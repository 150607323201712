import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import MachineOff from "../../assets/images/machineoff.png";
import UsbIn from "../../assets/images/switchnusb.png";
import SwitchOn from "../../assets/images/switchon.png";
import LoadConfig from "../../assets/images/loadconfig.gif";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table, TableBody, TableCell, TableHeadCell, TableHeader, TableRow } from "../../utils/dashboard/table";
import { fetchAllMachinesData, fetchAllMachineConfigs } from "src/store/actions/machine";
import {
  fetchGymData,
  setGymDataReady,
  setGymName,
  setMachineIdGym,
} from "src/store/actions/gyms";
import { useLocation } from "react-router-dom";
import { postRequest } from 'src/utils/serverApi';
import { Usb } from 'lucide-react';


const Configuration = (props) => {

  const [isLoadingGymData, setIsLoadingGymData] = useState(true);
  const location = useLocation();
  const [popoverData, setPopoverData] = useState(null);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isConfirmPopoverVisible, setIsConfirmPopoverVisible] = useState(false);
  const isPhone = window.innerWidth <= 768;

  const [updatedVersion, setUpdatedVersion] = useState(false);

  let [versionData, setVersionData] = useState([]);

  {/*useEffect to handle the fetching of machine data in owners list*/}

  //here we check if all the machines and gyms data has been fetched or not and fetch them if no
  useEffect(() => {
    if (!props.allMachinesDataFetched && props.privilege) {
      props.fetchAllMachinesData(props.gymMachineIds, props.privilege);
      props.fetchAllMachineConfigs()
    } else if (props.privilege){
      props.setMachineIdGym(props.allMachinesData[0].id);
      props.setGymName(props.allMachinesData[0].name)
    }
    if (!props.allConfigInfoFetched && props.privilege) {
      props.fetchAllMachineConfigs()
    }
  }, [props.allMachinesDataFetched, props.gymsData, props.allConfigInfoFetched]);


  {/*useEffect to handle the fetching of gym data in owners list*/}

  useEffect(() => {
    // if a machine id has been set but the gym data was not fetched yet, then we fetch it
    if (props.setMachineIdGymSuccess && !Object.keys(props.gymsData).includes(props.machineIdGym)) {
      props.fetchGymData(props.machineIdGym, props.gymName);
    }

  }, [props.setMachineIdGymSuccess, props.machineIdGym]);


  {/*useEffect to handle the setting of default gym in dropdown*/}

  useEffect(() => {
    // Check if the current path matches the finance dashboard one
    if (location.pathname.startsWith("/management/configuration")) {
      // Here we check if a machine id was already saved before and thus we display the corresponding data and not 
      // necessary the first gym of the list 
      
      if (props.allMachinesDataFetched) {
        if (!props.machineIdGym && props.allMachinesData.length > 0) {

          props.setMachineIdGym(props.allMachinesData[0].id);
          props.setGymName(props.allMachinesData[0].name)
        } else if (props.allMachinesData.length > 0) {
          const selectedGymName = props.allMachinesData.filter(
            (gym) => gym.id === props.machineIdGym
          );
          if (selectedGymName.length > 0) {
            props.setGymName(selectedGymName[0].name)
          }
        }
      } else {
        props.fetchAllMachinesData(props.gymMachineIds, props.privilege);
        props.fetchAllMachineConfigs()
      }
    }
  }, [location.pathname, props.machineIdGym, props.allMachinesData]);

  {/*useEffect to handle the mapping of configs to machines*/}

  //use effect that checks and loads all data needed to map correct machine configs to every machine that the gym owner owns
  useEffect(() => {
    if (!props.allMachinesDataFetched || !props.allConfigInfoFetched) return; 
  
    const relevantMachines = Object.values(props.allMachinesData).filter(
      (machine) => machine.machineConfig // make sure machine has a config
    );

    const newVersionData = relevantMachines.flatMap((machine) => {// create new array with each machines name and its next config
      const { next } = machine.machineConfig;
      return Object.values(props.allConfigInfo)
       .filter((config) => config.id === next) // find matching config
        .map((config) => ({
          ...config, 
          machineName: machine.nameGym, // add machines name
          currentVersion: machine.machineConfig.current
        }));
    });
  
  
    setVersionData(newVersionData)
  
  }, [props.allMachinesDataFetched, props.machineIdGym, props.allConfigInfoFetched, updatedVersion, props.allMachinesData]);
  

  const handlePopover = (event, version, type) => {
    event.stopPropagation();
    if (type === "mis popup") {
      setPopoverData(version); // Set the popoverData
      setIsConfirmPopoverVisible(true);
    } else {
      setPopoverData(version);
      setIsPopoverVisible((prev) => (popoverData === version ? !prev : true));
    }
  };

  const closePopover = () => {
    setIsPopoverVisible(false);
    setIsConfirmPopoverVisible(false);
  };


  const handleUpdateVersion = (config) => {
    const machineId = Object.values(props.allMachinesData).filter(// get current of machine from allmachinesdatastate
      (machine) => machine.nameGym === config.content.machineName)[0].id
    firebase
      .firestore()
      .collection("machines")
      .doc(machineId)
      .get()
      .then((docSnapshot) => {
        if (docSnapshot.exists) {
          //Update the field in Firestore
          return firebase.firestore().collection("machines").doc(machineId).update({
            "configurations.current": config.content.id,
            "configurations.next": null
          });
        } else {
          console.error("Document does not exist");
        }
      })
      .then(() => {
        props.fetchAllMachinesData(props.gymMachineIds, props.privilege);
        setUpdatedVersion(!updatedVersion)
        props.fetchAllMachinesData(props.gymMachineIds, props.privilege);
        closePopover()
      })
      .catch((error) => {
        console.error("Error updating document:", error);
      });
  };
  

    // this is the pop over to display the information of the machine selected
    const PopoverContent = () => (
      <div
        className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-30"
        onClick={closePopover}
      >
        <div
          className="bg-white rounded-lg shadow-lg p-6"
          style={{
            width: "min(70%, 700px)",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
          onClick={(e) => e.stopPropagation()} // Prevent click from closing the popover
        >
          <p className="mb-2">
            <span className="font-semibold text-left">Nom de version:</span> {popoverData.name}
          </p>
          <ul className="list-disc list-inside">
          <span className="font-semibold text-left">Composition:</span>
            {popoverData.composition.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
  
        </div>
      </div>
    );

  // this is the pop over to display the information of the machine selected
  const ConfirmPopoverContent = (content) => (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-30"
      onClick={closePopover}
    >
      {/* Popover Container */}
      <div
        className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center text-center"
        style={{
          width: "min(70%, 700px)",
          maxHeight: "80vh",
          overflowY: "auto",
        }}
        onClick={(e) => e.stopPropagation()} // Prevent click from closing the popover
      >
        {/* Popover Title */}
        <p className="font-semibold text-lg text-gray-800 mb-4">
          Vous êtes sûr que vous avez complété toutes les étapes ?
        </p>
  
        {/* Action Buttons */}
        <div className="flex items-center justify-center gap-x-4 w-full mt-4">
          <button 
              onClick={() => handleUpdateVersion(content)}
              className="bg-blue-600 text-white text-sm font-semibold px-6 py-2 rounded-full shadow hover:bg-blue-700 transition duration-200"
            >
              Mis à jour
          </button>
          <button 
            onClick={closePopover}
            className="bg-gray-300 text-gray-700 text-sm font-semibold px-4 py-2 rounded-full shadow hover:bg-gray-400 transition duration-200"
          >
            Annuler
          </button>
  
        </div>
      </div>
    </div>
  );

  return (
    props.allMachinesDataFetched && props.allConfigInfoFetched ? (
      <div className="min-h-screen flex flex-col w-full p-6 bg-customOrange-light" style={{ marginTop: "20px", paddingLeft: "4%", paddingRight: "4%" }}>
        {/* Title*/}
        <div className="bg-white border border-transparent rounded-2xl shadow px-10 py-3 text-center text-2xl font-bold text-black mx-auto w-auto flex-shrink-0" style={{ marginBottom: "1px", marginTop: "25px" }}>
          Mettre à jour
        </div>    
        {/* Table */}
        <div className="p-4 flex-shrink overflow-auto bg-white border border-transparent rounded-2xl shadow w-full" style={{ marginTop: "30px" }}>
        {props.allMachinesData ? (
          isPhone ? (
            <Table className="w-full rounded-lg shadow-sm table-fixed">
              <TableHeader>
                <TableRow className="bg-gray-100 border-b border-gray-300">
                  <TableHeadCell className="px-3 py-2 text-left whitespace-nowrap text-xs font-bold text-black w-[140px]">Nom salle</TableHeadCell>
                  <TableHeadCell className="px-3 py-2 text-center text-xs font-bold text-black w-[140px]">Télécharger</TableHeadCell>
                  <TableHeadCell className="px-3 py-2 w-[140px] whitespace-nowrap text-right text-xs font-bold text-black">Mettre à jour</TableHeadCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {versionData.map((item, index) => (
                  <TableRow key={index} className="border-b border-gray-200 hover:bg-gray-50 transition-all duration-200">
                    <TableCell className="px-3 py-2 text-left text-sm text-gray-700 w-[140px]">
                      {item.machineName}
                    </TableCell>
                    <TableCell className="px-3 py-2 text-center ml-7 text-sm text-gray-700 w-[130px]">
                      {item.urlLink ? (
                        <a
                          href={item.urlLink}
                          className="px-3 py-1 w-[110px] rounded-md bg-customOrange-dark text-white text-xs font-semibold focus:outline-none transition-all duration-200 hover:bg-orange-600"
                        >
                          Télécharger
                        </a>
                      ) : (
                        <span className="text-gray-500">No Link</span>
                      )}
                    </TableCell>
                    <TableCell className="px-3 py-2 text-right text-sm text-gray-700 w-[140px]">
                      <button
                        onClick={(e) => handlePopover(e, item, "mis popup")}
                        className="px-3 py-1 w-[90px] whitespace-nowrap rounded-md bg-blue-500 text-white text-xs font-semibold focus:outline-none transition-all duration-200 hover:bg-blue-600"
                      >
                        Mettre à jour
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Table className="w-full rounded-lg shadow-sm table-fixed">
              <TableHeader>
                <TableRow className="bg-gray-100 border-b border-gray-300">
                  <TableHeadCell className="px-6 py-3 text-center text-sm font-bold text-black w-1/5">Nom salle</TableHeadCell>
                  <TableHeadCell className="px-6 py-3 text-center text-sm font-bold text-black w-1/5">Noveaux Version</TableHeadCell>
                  <TableHeadCell className="px-6 py-3 text-center text-sm font-bold text-black w-1/5">Composition</TableHeadCell>
                  <TableHeadCell className="px-6 py-3 text-center text-sm font-bold text-black w-1/5">Télécharger</TableHeadCell>
                  <TableHeadCell className="px-6 py-3 text-center text-sm font-bold text-black w-1/5">Mettre à jour</TableHeadCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {versionData.map((item, index) => (
                  <TableRow key={index} className="border-b border-gray-200 hover:bg-gray-50 transition-all duration-200">
                    <TableCell className="px-6 py-3 text-center text-sm font-medium text-gray-700 w-1/5">
                      {item.machineName}
                    </TableCell>
                    <TableCell className="px-6 py-3 text-center text-sm font-medium text-gray-700 w-1/5">
                      {item.name}
                    </TableCell>
                    <TableCell className="px-6 py-3 text-center text-sm font-medium text-gray-700 w-1/5">
                      <button
                        onClick={(e) => handlePopover(e, item, "content popup")}
                        className="w-[140px] px-4 py-2 rounded-full bg-customOrange-dark text-white text-sm font-semibold focus:outline-none transition-all duration-200 hover:bg-orange-600"
                      >
                        Composition
                      </button>
                    </TableCell>

                    <TableCell className="px-6 py-3 text-center text-sm font-medium text-gray-700 w-1/5">
                      {item.urlLink ? (
                        <a
                          href={item.urlLink}
                          className="w-[140px] px-4 py-2 rounded-full bg-customOrange-dark text-white text-sm font-semibold focus:outline-none transition-all duration-200 hover:bg-orange-600 inline-block text-center"
                        >
                          Télécharger
                        </a>
                      ) : (
                        <span className="text-gray-500">No Link</span>
                      )}
                    </TableCell>
                    <TableCell className="px-6 py-3 text-center text-sm font-medium text-gray-700 w-1/5">
                      <button
                        onClick={(e) => handlePopover(e, item, "mis popup")}
                        className="px-4 py-2 rounded-full bg-blue-500 text-white text-sm font-semibold focus:outline-none transition-all duration-200 hover:bg-blue-600"
                      >
                        Mis à jour
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )
        ) : null}
        {isPopoverVisible && popoverData && <PopoverContent />}
        {isConfirmPopoverVisible && <ConfirmPopoverContent content={popoverData} />}
      </div>
      {/* Tutorial Content */}
      <div className="p-4 flex-shrink overflow-auto bg-white border border-transparent rounded-2xl shadow w-full" style={{ marginTop: "30px" }}>
        <h1 className="text-3xl font-bold text-center text-black-700 mb-8">
          How to Load Configuration on the Machine
        </h1>

        {/* Steps */}
        <div className="step mb-6">
          <h2 className="text-2xl font-semibold text-black-700">Step 1: Prepare Your USB Key</h2>
          <p className="text-gray-700">
            Put the file on a USB key. Make sure there is no other content on the USB at all.
          </p>
        </div>

        <div className="step mb-6">
          <h2 className="text-2xl font-semibold text-black-700">Step 2: Turn Off the Machine</h2>
          <img
            src={MachineOff}
            alt="Machine switch"
            className="w-1/3 h-auto object-cover rounded-lg my-2 mx-auto"
            style={{ maxWidth: "200px" }}
          />
          <p className="text-gray-700">
            Open the machine and turn off the switch located at the top right corner.
          </p>
        </div>

        <div className="step mb-6">
          <h2 className="text-2xl font-semibold text-black-700">Step 3: Insert the USB Key</h2>
          <img
            src={UsbIn}
            alt="Insert USB key into machine"
            className="w-1/3 h-auto object-cover rounded-lg my-2 mx-auto"
            style={{ maxWidth: "200px" }}
          />
          <p className="text-gray-700">
            Insert the USB key into the USB port at the top right corner, just above the switch.
          </p>
        </div>

        <div className="step mb-6">
          <h2 className="text-2xl font-semibold text-black-700">Step 4: Turn On the Machine</h2>
          <img
            src={SwitchOn}
            alt="Turn on the machine"
            className="w-1/3 h-auto object-cover rounded-lg my-2 mx-auto"
            style={{ maxWidth: "200px" }}
          />
          <p className="text-gray-700">Turn on the machine by clicking on the switch.</p>
        </div>

        <div className="step mb-6">
          <h2 className="text-2xl font-semibold text-black-700">Step 5: Load the Configuration</h2>
          <img
            src={LoadConfig}
            alt="Using the remote"
            className="w-1/2 h-auto object-cover rounded-lg my-4 mx-auto"
            style={{ maxWidth: "300px" }}
          />
          <p className="text-gray-700">Take the gray remote and navigate to "Read Configuration":</p>
          <ul className="list-disc list-inside text-gray-700">
            <li>Press <strong>C</strong>.</li>
            <li>Press <strong>A</strong> multiple times until you reach Program 82: <em>Lire config</em>.</li>
            <li>If you go too far, press <strong>B</strong> to go back.</li>
            <li>Press <strong>D</strong> to display the file name.</li>
            <li>Press <strong>D</strong> again to confirm.</li>
            <li>Press <strong>D</strong> one more time. A message saying "<em>Patienter</em>" will appear.</li>
          </ul>
        </div>

        <div className="step mb-6">
          <h2 className="text-2xl font-semibold text-black-700">Step 6: Restart the Machine</h2>
          <p className="text-gray-700">Once the machine has restarted:</p>
          <ul className="list-disc list-inside text-gray-700">
            <li>Turn off the machine.</li>
            <li>Remove the USB key.</li>
            <li>Turn the machine on again to complete the process.</li>
          </ul>
        </div>

        <div className="step mb-6">
          <h2 className="text-2xl font-semibold text-black-700">Step 7: Click Mis à jour</h2>
        </div>
      </div>

    </div>
    ) : null
  );
  
}

 const mapStateToProps = (state) => ({
    userId: state.userState.userId,
    allMachinesData: state.machineState.allMachinesData,
    allMachinesDataFetched: state.machineState.allMachinesDataFetched,
    allConfigInfo: state.machineState.allConfigInfo,
    allConfigInfoFetched: state.machineState.allConfigInfoFetched,
    gymsData: state.gymState.gymsData,
    machineIdGym: state.gymState.machineIdGym,
    allMachinesDataFetched: state.machineState.allMachinesDataFetched,
    setMachineIdGymSuccess: state.gymState.setMachineIdGymSuccess,
    gymName: state.gymState.gymName, 
    privilege: state.userState.privilege,
    userId: state.userState.userId,
    gymMachineIds: state.userState.gymMachineIds,
    
  });
  
  const mapDispatchProps = (dispatch) => ({
    fetchAllMachinesData: (machineIds, privilege) => dispatch(fetchAllMachinesData(machineIds, privilege)),
    fetchAllMachineConfigs: () => dispatch(fetchAllMachineConfigs()),
    setGymDataReady: () => dispatch(setGymDataReady()),
    setMachineIdGym: (machineId) => dispatch(setMachineIdGym(machineId)),
    setGymName: (gymName) => dispatch(setGymName(gymName)), 
    fetchGymData: (machineId, nameGym) => dispatch(fetchGymData(machineId, nameGym)), 
  });

  export default connect(mapStateToProps, mapDispatchProps)(Configuration)