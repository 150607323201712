import React from "react";
import { FaDollarSign, FaUsers, FaUser, FaStar } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import { MdLocalDrink } from "react-icons/md";
import {
  RiArrowDownLine,
  RiArrowRightLine,
  RiArrowUpLine,
} from '@remixicon/react';

const DataBlock = ({ title, value, type, size, status, statusData}) => {
  //if (status) {console.log(statusData)} else{console.log("normal block")}
  const isPhone = window.innerWidth <= 768;
  // Function to format numbers with a space for values over 999
  const formatValue = (value, type) => {
    // Ensure the value is a float for 'money' or 'subscription'
    let formattedValue = parseFloat(value);
  
    if (type === "money" || type === "subscription") {
      // Convert to float and then format as needed
      formattedValue = formattedValue.toFixed(2); // Ensure it's always 2 decimal points (e.g., 1,000.00)
    }
  
    // Convert to string for manipulation
    let valueStr = formattedValue.toString();

    if (formattedValue > 999) {
      // Split the string into chunks of 3 digits, starting from the end
      valueStr = valueStr.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
  
    // Append the euro symbol for 'money' or 'subscription' types
    if (type === "money" || type === "subscription") {
      valueStr += "€";
    }
  
    return valueStr;
  };
  

  // Determine the icon and background color based on the type
  let icon, bgColor;
  if (type === "money") {
    icon = <FaDollarSign color="black" size={20} />;
    bgColor = "bg-green-100";
  } else if (type === "subscription") {
    icon = <FaPeopleGroup color="black" size={20} />;
    bgColor = "bg-yellow-100";
  } else if (type === "drink") {
    icon = <MdLocalDrink color="black" size={20} />;
    bgColor = "bg-blue-100";
  } else if (type === "user") {
    icon = <FaUser color="black" size={20} />;
    bgColor = "bg-gray-100";
  } else if (type === "star") {
    icon = <FaStar color="black" size={20} />
    bgColor = "bg-yellow-100"
  }

  return (
    <div className="relative px-6 py-9 bg-white border-transparent rounded-xl shadow flex flex-col items-center justify-center">
      {/* Left Column: Icon in a circle */}
      <div className={`w-12 h-12 ${bgColor} flex items-center justify-center rounded-full`}>
        {icon}
      </div>

      {/* Value */}
      <div className={`text-black mt-4 ${isPhone ? "text-xl" : size === "smaller" ? "text-3xl" : "text-4xl"} font-semibold`}>
        {formatValue(value, type)}
      </div>

      {/* Title */}
      <div className="text-gray-500 text-sm mt-[3px]">{title}</div>

      {/* Progress box */}
      {status && statusData !== 0 ? (
        <span className={`absolute bottom-2 right-2 inline-flex items-center gap-x-1 rounded-md 
          px-2 py-1 text-tremor-label font-semibold 
          ${statusData > 0 
            ? 'bg-emerald-100 text-emerald-800 dark:bg-emerald-400/20 dark:text-emerald-500' 
            : 'bg-rose-100 text-rose-800 dark:bg-rose-400/20 dark:text-rose-500'}`}>
          {statusData > 0 
            ? <RiArrowUpLine className="-ml-0.5 size-4" aria-hidden={true} />
            : <RiArrowDownLine className="-ml-0.5 size-4" aria-hidden={true} /> }
          {Math.abs(statusData)}%
        </span>
      ) : null}

    </div>

  );
};

export default DataBlock;
