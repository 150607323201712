import React, { useState } from "react";
import { Table, TableBody, TableCell, TableHeader, TableRow } from "./table";

const ProductTable = ({ products }) => {
  const [sortConfig, setSortConfig] = useState({ key: "drinks", direction: "descending" });
  
  if (products) {
    //console.log(products)
    // // Identify the maximum and minimum values for drinks and revenue
    const maxDrinks = Math.max(...Object.values(products).map((product) => product.drinks));
    const minDrinks = Math.min(...Object.values(products).map((product) => product.drinks));
    const maxRevenue = Math.max(...Object.values(products).map((product) => product.revenue));
    const minRevenue = Math.min(...Object.values(products).map((product) => product.revenue));
  
    const handleSort = (key) => {
      let direction = "ascending";
      if (sortConfig.key === key && sortConfig.direction === "ascending") {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };
  
    const sortedProducts = [...Object.values(products)].sort((a, b) => {
      if (sortConfig.key) {
        const order = sortConfig.direction === "ascending" ? 1 : -1;
        if (a[sortConfig.key] < b[sortConfig.key]) return -1 * order;
        if (a[sortConfig.key] > b[sortConfig.key]) return 1 * order;
        return 0;
      }
      return 0;
    });
  
    const renderSortIndicator = (key) => {
      const isActive = sortConfig.key === key;
      const ascending = isActive && sortConfig.direction === "ascending";
      const descending = isActive && sortConfig.direction === "descending";
  
      return (
        <span className="ml-2 text-gray-500">
          <span className={`${descending ? "text-black" : ""}`}>↑</span>
          <span className={`${ascending ? "text-black" : ""}`}>↓</span>
        </span>
      );
    };
  
    const getTypeStyle = (type) => {
      let bgColor = "";
      let textColor = "";
    
      switch (type) {
        case "whey":
          bgColor = "#FF8C8C"; // Darker Red
          textColor = "#fff";
          break;
        case "isolate":
          bgColor = "#7BB9FF"; // Darker Blue
          textColor = "#fff";
          break;
        case "vitamin":
          bgColor = "#FF9A4D"; // Darker Orange
          textColor = "#fff";
          break;
        case "carnitine":
          bgColor = "#F7D15D"; // Darker Yellow
          textColor = "#fff";
          break;
        case "booster":
          bgColor = "#A97DB7"; // Darker Purple
          textColor = "#fff";
          break;
        case "pack":
          bgColor = "#56C1B4"; // Darker Cyan
          textColor = "#fff";
          break;
        case "membership":
          bgColor = "#F07EA1"; // Darker Pink
          textColor = "#fff";
          break;
        case "BCAA":
          bgColor = "#4CAF50"; // Darker Green
          textColor = "#fff";
          break;
        default:
          bgColor = "transparent";
          textColor = "inherit";
      }
    
      return {
        backgroundColor: bgColor,
        color: textColor,
        borderRadius: "12px",
        padding: "4px 12px",
        display: "inline-block",
        textAlign: "center",
      };
    };
    
    
    return (
      <div className="p-6 bg-white border border-white rounded-md shadow w-full overflow-x-auto">
        <h3 className="mb-8 text-lg font-semibold mt-4">Répartition des ventes</h3>
        <Table className="w-full overflow-x-auto">
          <TableHeader>
            <TableRow header={true}>
              <TableCell className="flex-1 px-4 py-4 text-sm text-black">Produit</TableCell>
              <TableCell
                className="flex-1 px-4 py-4 text-sm text-black cursor-pointer"
                onClick={() => handleSort("drinks")}
              >
                Ventes {renderSortIndicator("drinks")}
              </TableCell>
              <TableCell
                className="flex-1 px-4 py-4 text-sm text-black cursor-pointer"
                onClick={() => handleSort("revenue")}
              >
                Revenu (€) {renderSortIndicator("revenue")}
              </TableCell>
              {/* Add the new column for type */}
              <TableCell className="flex-1 px-4 py-4 text-sm text-black">Type</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sortedProducts.map((product) => (
              <TableRow key={product.name}>
                <TableCell>{product.name}</TableCell>
                <TableCell>
                  <span
                    style={{
                      display: "inline-block",
                      padding: "3.5px 14px",
                      backgroundColor:
                        product.drinks === maxDrinks
                          ? "#EAF6FF" // Light blue for max
                          : product.drinks === minDrinks
                          ? "#FFE8CC" // Light orange for min
                          : "transparent",
                      color:
                        product.drinks === maxDrinks
                          ? "#0056b3" // Dark blue for max
                          : product.drinks === minDrinks
                          ? "#cc5200" // Dark orange for min
                          : "inherit",
                      fontWeight:
                        product.drinks === maxDrinks || product.drinks === minDrinks
                          ? "bold"
                          : "normal",
                      borderRadius: "8px",
                    }}
                  >
                    {product.drinks}
                  </span>
                </TableCell>
                <TableCell>
                  <span
                    style={{
                      display: "inline-block",
                      padding: "3px 10px",
                      backgroundColor:
                        product.revenue === maxRevenue
                          ? "#EAF6FF" // Light blue for max
                          : product.revenue === minRevenue
                          ? "#FFE8CC" // Light orange for min
                          : "transparent",
                      color:
                        product.revenue === maxRevenue
                          ? "#0056b3" // Dark blue for max
                          : product.revenue === minRevenue
                          ? "#cc5200" // Dark orange for min
                          : "inherit",
                      fontWeight:
                        product.revenue === maxRevenue || product.revenue === minRevenue
                          ? "bold"
                          : "normal",
                      borderRadius: "5px",
                    }}
                  >
                    {product.revenue.toFixed(2)}€
                  </span>
                </TableCell>
                {/* Add the type with rounded background */}
                <TableCell>
                  <span style={getTypeStyle(product.type)}>{product.type}</span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

};

export default ProductTable;
