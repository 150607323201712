import { TrackNextIcon } from "@radix-ui/react-icons";
import { Mountain } from "lucide-react";
import { Bar, BarChart, CartesianGrid, XAxis, Tooltip, Legend, ResponsiveContainer, YAxis } from "recharts";
import { createImportSpecifier } from "typescript";

const COLORS = [
    "#90CAF9",
    "#A5D6A7", 
    "#FFD699",
    "#FF8A80", 
    "#B0BEC5", 
    "#FFEB3B", // Bright Yellow
    "#FF9800", // Bright Orange
  ];

export const StackedBarChart = ({data, type}) => {
    if (data) {
    //  console.log(data)
    let categories =[]; //create a list to hold all the different categories of each bar
    const months = data.length
    data.map((key) => { //iterate through months
      Object.entries(key).map((entry) => { //iterate through keys in each month
          if (entry[0] !== "month" && typeof entry[0] === "string" && !categories.includes(entry[0])){
              categories.push(entry[0])
          }
      })
    })

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
            <div className="p-4 bg-white border rounded-xl shadow space-y-2">
                {categories.map((category, index) => (
                <div key={category} className="flex items-center space-x-2">
                    <div style={{ backgroundColor: COLORS[index], width: 12, height: 12}}/>
                   {/* Dynamically display current categories and their values*/}
                    <p className="text-sm text-gray-700"> 
                        {payload[0]?.payload[category] 
                        ? `${category}: ${payload[0]?.payload[category]}` 
                        : `${category}: 0`}
                        
                    </p>
                </div>
                )
                )}
            </div>
            );
        }
        
        return null; // Return null if the condition is not met
        };  

  return (
    <div className="bg-white rounded-xl shadow p-6">
      <div className="mb-4">
        <h3 className="text-lg font-semibold">{type === "drinks" || type === "overviewDrinks" ? "Répartition des boissons" : type === "subscriptions" ? "Répartition des abonnements" : "Répartition des revenus salles" }</h3>
        <p className="text-sm text-gray-500">{data[0]?.month + " à " + data[data.length - 1]?.month}</p>
      </div>
      <div>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data} stackOffset="sign" barSize={60}>
            <CartesianGrid vertical={false} horizontal={false} />
            <XAxis
              dataKey="month"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => value.slice(0, 7)}
            />
            <YAxis axisLine={false} tickLine={false} />
            <Tooltip cursor={{ fill: "rgba(126, 126, 126, 0.1)" }} content={<CustomTooltip />} />
            <Legend
              verticalAlign="top"
              height={36}
              iconType="square"
              formatter={(value) => {
                switch (value) {
                  case "freeDrinks":
                    return "Gratuites";
                  case "membershipDrinks":
                    return "Abonnements";
                  case "packDrinks":
                    return "Packs";
                  case "paidDrinks":
                    return "Payantes";
                  default:
                    return value;
                }
              }}
            />
            {categories.map((category, index) => { 
            const barThickness = months > 3 ? 40 : 60; // Set bar thickness according to how many months are displayed
            const isLastBar = index === categories.length - 1; // Check if it's the last bar
            
            return ( 
                <Bar
                key={category}
                dataKey={category}
                stackId="a"
                fill={COLORS[index]} // Use the corresponding color for each bar
                radius={isLastBar ? [4, 4, 0, 0] : [0, 0, 0, 0]} // Round top corners only for the last bar
                barSize={barThickness}
                />
            );
            })}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
}

  