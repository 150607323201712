import { Cell, BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="p-4 bg-white border rounded-xl shadow">
        <p className="text-gray-700">
          <strong>Total:</strong> {payload[0]?.payload?.value}
        </p>
      </div>
    );
  }
  return null;
};

const HistogramBar = ({ data, title, revenue, isPhone }) => {
  if (!data || data.length === 0) return null;

  // Find the max value to scale the Y-axis properly
  const maxValue = Math.max(...data.map((entry) => entry.value)) || 1;

  return (
    <div className="p-6 bg-white border border-white rounded-xl shadow">
      <div className="flex items-center justify-between mb-4">
        <h3
          className="text-lg font-semibold text-left mt-[10px] mb-[20px]"
          style={{ marginLeft: "25px", flexWrap: "wrap", marginRight: "10px" }}
        >
          {title}
        </h3>
      </div>

      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data} barSize={45}>
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tick={{ fill: "#6b7280", fontSize: isPhone ? 8 : 12 }}
            angle={isPhone ? -15 : 0}
            textAnchor={isPhone ? "end" : "middle"}
          />

          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: "#6b7280", fontSize: 12 }}
            domain={[0, Math.ceil(maxValue * 1.1)]} // Scale max value dynamically
            tickFormatter={(value) =>
              value === 0 ? "" : `${value}${revenue ? "€" : ""}`
            }
          />

          <Tooltip
            cursor={{ fill: "rgba(126, 126, 126, 0.1)" }}
            content={<CustomTooltip />}
          />

          <Bar dataKey="value" radius={[15, 15, 15, 15]}>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  entry.name === "Gratuites" || entry.name === "Revenue Unite"
                    ? "#90CAF9"
                    : entry.name === "Payantes" || entry.name === "Revenue Abonnement"
                    ? "#A5D6A7"
                    : entry.name === "Abonnements" || entry.name === "Revenue App Abonnement"
                    ? "#FFD699"
                    : entry.name === "Packs"
                    ? "#FF8A80"
                    : "#B0BEC5" // Default color if not matched
                }
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HistogramBar;
