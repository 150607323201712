import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export function clearAllGymsData() {
    return ((dispatch) => {
        dispatch({type:"CLEAR_ALL_GYMS_DATA"});
    })
}

export function fetchAllGymsData() {
    return async (dispatch) => {
      dispatch({ type: "START_FETCHING_ALL_GYM_DATA" });
  
      const machinesRef = firebase.firestore().collection("machines");
  
      try {
        const machinesSnapshot = await machinesRef.get();
        const allGymsData = {};
  
        const machinePromises = machinesSnapshot.docs.map(async (doc) => {
          const machineId = doc.id;
          const machineData = doc.data();
  
          const financialsSnapshot = await machinesRef
            .doc(machineId)
            .collection("financials")
            .get();
  
          // Only proceed if this machine has at least one financial document
          if (!financialsSnapshot.empty) {
            const financialsData = {};
            financialsSnapshot.forEach((finDoc) => {
              financialsData[finDoc.id] = finDoc.data();
            });
  
            allGymsData[machineId] = {
              chargeMembership: machineData.chargeMembership || null,
              rentPrice: machineData.rentPrice || null,
              financials: financialsData,
              name: machineData.name || machineId,
            };
          }
        });
  
        await Promise.all(machinePromises);
        dispatch({
          type: "FETCH_GYM_DATA_ALL_SUCCESS",
          gymsData: allGymsData,
        });
      } catch (error) {
        console.error("Error fetching all gym data:", error);
        dispatch({ type: "FETCH_DASHBOARD_DATA_FAILURE", error });
      }
    };
  }
  



export function fetchGymData(machineId, nameGym) {
    return async (dispatch) => {
        dispatch({ type: "START_FETCHING_GYM_DATA" });

        const machinesRef = firebase.firestore().collection("machines");

        try {
            // Fetch previous months' financial data
            const financialsRef = machinesRef.doc(machineId)

            const [machineSnapshot, financialsSnapshot] = await Promise.all([
                financialsRef.get(), 
                financialsRef.collection("financials").get()
            ]);
            
            // Initialize the result object
            const allMonthsData = {};
            
            // Save the "chargeMembership" field from the document
            if (machineSnapshot.exists) {
                const machineData = machineSnapshot.data();
                allMonthsData.chargeMembership = machineData.chargeMembership; // Add chargeMembership field
                allMonthsData.rentPrice = machineData.rentPrice;
            }
            
            // Save financials collection data
            allMonthsData.financials = {}; // Nested dictionary for financials
            
            financialsSnapshot.forEach((doc) => {
                allMonthsData.financials[doc.id] = doc.data();
            });
              
            dispatch({
                type: "FETCH_GYM_DATA_SUCCESS",
                data: allMonthsData,
                machineId: machineId,
                nameGym: nameGym,
            });
        } catch (error) {
            console.error("Error fetching gym data:", error);
            dispatch({ type: "FETCH_DASHBOARD_DATA_FAILURE", error });
        }
    };
}

export const setMachineIdGym = (machineId) => {
    return (dispatch) => {
        dispatch({
            type: 'SET_MACHINE_ID_GYM_SUCCESS',
            machineId: machineId
        })
    }
}

export const resetMachineIdGym = () => {
    return (dispatch) => {
        dispatch({
            type: 'RESET_MACHINE_ID_GYM_SUCCESS',
        })
    }
}

export const setGymName = (gymName) => {
    return (dispatch) => {
        dispatch({
            type: 'SET_GYM_NAME_SUCCESS',
            gymName: gymName
        })
    }
}

export const resetMGymName = () => {
    return (dispatch) => {
        dispatch({
            type: 'RESET_GYM_NAME_SUCCESS',
        })
    }
}


export const setSelectedGymsAnalysis = (selectedGymsAnalysis) => {
    return (dispatch) => {
        dispatch({
            type: 'SET_SELECTED_GYMS_ANALYSIS',
            selectedGymsAnalysis: selectedGymsAnalysis
        })
    }
}

export const setGymDataReady = () => {
    return (dispatch) => {
        dispatch({
            type: 'SET_GYM_DATA_READY',
        })
    }
}

