import React, { useEffect, useState } from 'react';
import { ArrowUp, ArrowDown, Minus } from 'lucide-react';
import Leader from "../../assets/images/leader.png"
import { getPreviousMonth } from './getPreviousMonth';


const MovementIcon = ({ movement }) => {
    if (movement > 0){
        return (
            <div className='flex flex-row items-center justify-center'>
                <ArrowUp className="text-green-500 w-4 h-4" />
                <span className='text-green-500'>{movement}</span>
            </div>
        )
    } else if (movement < 0) {
        return (
            <div className='flex flex-row items-center justify-center'>
                <ArrowDown className="text-red-500 w-4 h-4" />;
                <span className='text-red-500'>{movement}</span>
            </div>
        )
    } else {
      return <Minus className="text-gray-400 w-4 h-4" />;
    }
};

const formatToMonthId = (date) => {
    const d = new Date(date); // ensure it's a Date object
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    return `${year}-${month}`; // "YYYY-MM"
  };
  
  
  

const getDateRangeData = (data, currentDate) => {
    const unsortedLeagueData = {};
    const currentDateFormatted = formatToMonthId(currentDate)
    const startDate = "2025-03";

    Object.values(data).forEach((gym) => {

        const startMonth = formatToMonthId(startDate);
        const endMonth = formatToMonthId(currentDateFormatted);

        console.log(startMonth)
        console.log(endMonth)

        const getSafeTotal = (gym, month) =>
            gym?.financials?.[month]?.memberships?.totalMemberships || 0;

        unsortedLeagueData[gym.name] = {
            memberships: (getSafeTotal(gym, endMonth) - getSafeTotal(gym, startMonth)) < 0 ? 0 : (getSafeTotal(gym, endMonth) - getSafeTotal(gym, startMonth)),
            rank: 0,
        };
    });

    return unsortedLeagueData;
};




const rankGyms = (unsorted) => {
    const sortedEntries = Object.entries(unsorted) // Convert into an array of arrays containing the key and value
      .sort(([, a], [, b]) => b.memberships - a.memberships); // Descending order
  
    const sorted = {};
  
    sortedEntries.forEach(([gym, data], index) => { // Loop through array and insert into dict 
      sorted[gym] = {
        memberships: data.memberships,
        rank: index + 1,
      };
    });
  
    return sorted;
  };


const Leaderboard = ({ data, currentGym }) => {

    const [sortedLeagueData, setSortedLeagueData] = useState({});
    const currentDate = new Date();
    const seasonStartDate = "01/04/2025";
    const seasonEndDate = "30/04/2025"


    useEffect(() => {
        const unsorted = getDateRangeData(data, currentDate);
        const sorted = rankGyms(unsorted);
        setSortedLeagueData(JSON.parse(JSON.stringify(sorted)));

      }, [data]);
      

    return (
        <div className="bg-white rounded-lg shadow p-4">
            <div className="flex items-center justify-center mb-4">
                {/* Left image */}
                <img src={Leader} alt="leader" className="w-7 h-8 mr-4" />
                
                {/* Title */}
                <div className="flex flex-col items-center">
                <div className="text-lg font-semibold">LEADERBOARD</div>
                <div className="text-sm text-gray-500 mt-1">
                    Saison actuelle
                </div>
                <div className="text-xs text-gray-400 mt-0.5 italic">
                {`${seasonStartDate} – ${seasonEndDate}`}
                </div>
                </div>


                {/* Right image */}
                <img src={Leader} alt="leader" className="w-7 h-8 ml-4" />
            </div>
            {/* Wrapper to allow horizontal scrolling on smaller screens */}
            <div className="overflow-x-auto">
                
                {/* Optional: Add max height for the table container if you want vertical scrolling */}
                <div className="min-w-full overflow-y-auto">
                    <table className="min-w-full text-left border-[0px] bg-white rounded-lg border-separate border-spacing-y-1">
                        <thead className="bg-white sticky top-0 z-10 border-[0px]">
                            <tr className="bg-white border-[0px]">
                                <th className="px-2 py-3 text-gray-700 text-center">Position</th>
                                <th className="px-2 py-3 text-gray-700 text-center">Salle</th>
                                <th className="px-2 py-3 text-gray-700 text-center">
                                "Nouveau Abo"
                                </th>
                                {/* <th className="px-2 py-3 text-gray-700 text-center rounded-tr-xl rounded-br-xl">Évolution</th> */}
                            </tr>
                        </thead>
                        <tbody>
                        {Object.keys(sortedLeagueData).map((gym, index) => {
                            const isCurrentGym = gym === currentGym;
                            return (
                                <tr
                                    key={gym}
                                    className={`
                                        ${isCurrentGym ? 'bg-green-100 rounded-lg py-6' : 'bg-gray-50 py-4'}
                                        ${index === 0 ? 'rounded-t-lg' : ''}
                                        ${index === Object.keys(sortedLeagueData).length - 1 ? 'rounded-b-lg' : ''}
                                    `}
                                >
                                    <td className={`px-4 py-4 text-center ${isCurrentGym ? 'font-bold' : '' } rounded-bl-lg rounded-tl-lg`}>{sortedLeagueData[gym].rank}</td>
                                    <td className={`px-4 py-4 text-center ${isCurrentGym ? 'font-bold' : ''}`}>{currentGym === gym ? currentGym : "X"}</td>
                                    <td className={`px-4 py-4 text-center ${isCurrentGym ? 'font-bold' : ''}`}>{sortedLeagueData[gym].memberships}</td>
                                    <td className={`px-4 py-4 text-center rounded-tr-lg rounded-br-lg`}>
                                        {/* <div className={`flex flex-row items-center justify-center text-center ${isCurrentGym && 'rounded-tr-xl rounded-br-xl'}`}>
                                            <MovementIcon movement={null} />  
                                        </div> */}
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Leaderboard;