import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="p-4 bg-white border rounded-xl shadow space-y-2">
        <p className="text-sm text-gray-700">{`Date: ${payload[0].payload.date}`}</p>
        <p className="text-sm text-blue-700">{`Sportive: ${payload[0].value}`}</p>
        <p className="text-sm text-orange-700">{`Protéinée: ${payload[1].value}`}</p>
      </div>
    );
  }

  return null;
};

const StackedAreaChart = ({ data, isPhone }) => {
  if (data) {

  // Determine the interval for skipping data points
  const interval = data.length > 150 ? 5 : 1;

  // Filter the data to only include every 'interval' data point
  const filteredData = data.filter((_, index) => index % interval === 0);
  return (
    <div className="p-6 bg-white border border-white rounded-xl shadow">
      <div className="flex items-center justify-between mb-4">
        <h3
          className="text-lg font-semibold ml-[25px] mt-[10px] mb-[35px]"
          style={{ flexWrap: "wrap", marginRight: "10px" }}
        >
          Répartition des boissons sportives et protéinées
        </h3>
      </div>

      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={filteredData}>
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            interval={isPhone ?  null : (data.length > 150 ? Math.floor(data.length / 50) : Math.floor(data.length / 5))}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tickFormatter={(value, index) => (index === 0 ? "" : value)} // Hide the first tick
          />
          <Tooltip content={<CustomTooltip />} />
          <Area
            type="monotone"
            dataKey="sport"
            // stackId="1"
            stroke="#001B25"
            fill="#90CAF9"
          />
          <Area
            type="monotone"
            dataKey="protein"
            // stackId="1"
            stroke="#F08300"
            fill="#FFD699"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
}

export default StackedAreaChart;
