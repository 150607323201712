import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../styles/dashboard/dashboardPage.css";
import { Table, TableBody, TableCell, TableHeadCell, TableHeader, TableRow } from "../../utils/dashboard/table";
import { reload } from "../../store/actions/reload";
import { fetchAllMachinesData, resetMachineDataUpdated, fetchAllMachineConfigs } from "../../store/actions/machine";
import { RotatingLines } from "react-loader-spinner";
import MenuBar from "src/utils/dashboard/menuBar";
import { FaDumbbell, FaRunning, FaBriefcase, FaBuilding } from 'react-icons/fa';
import Filter from "../../assets/images/filter_icon.jpg";
import { getSizeStyle, getTypeStyle } from "src/utils/management/featureTableStyle";
import { fetchDrinksInfo } from "../../store/actions/drinks";
import { fetchGymData } from "src/store/actions/gyms";

import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { FiLayers } from "react-icons/fi";
import { useNavigate, useLocation } from "react-router-dom";

const sizeOptions = ["<500", "<1000", "<1500", "<2000", "<2500", ">2500"];
const typeOptions = ["muscu", "crossfit", "office", "general"];


const MachinesPage = (props) => {
  const navigate = useNavigate();
  const { reload } = props;
  const userId = props.userId;
  const [popoverData, setPopoverData] = useState(null);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isPopoverFilterVisible, setIsPopoverFilterVisible] = useState(false)
  const [sortConfig, setSortConfig] = useState({ key: "nameGym", direction: "ascending" }); // Sorting state
  const isPhone = window.innerWidth <= 768;
  const [sizeFilter, setSizeFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState([]);
  const [filteredMachines, setFilteredMachines] = useState(props.allMachinesData || []);
  const [appliedFilters, setAppliedFilters] = useState(false); // Flag to apply filters

  const [popOverMachine, setPopOverMachine] = useState(null);

  useEffect(() => {
    console.log(props.gymsData)
    console.log(props.allMachinesData)
    console.log(filteredMachines)
  },[filteredMachines])

  // we need to fetch all the machine data and apply filters if they are some
  useEffect(() => {
    if (!props.allMachinesDataFetched && props.privilege) {
      props.fetchAllMachinesData(null, props.privilege);
      props.fetchDrinksInfo()
    } 

    setFilteredMachines(props.allMachinesData)

  }, [props.allMachinesDataFetched, props.gymMachineIds, props.allMachinesData]);

  //match the flavor name to its id and family id inside the popover data
  useEffect(() => {
    if (popoverData) {
      let machineWithFlavours = popOverMachine;
      Object.keys(popOverMachine.items).map((item) => {
        if (popOverMachine.items[item]) {
          const flavorId = popOverMachine.items[item].flavorId
          const familyId = popOverMachine.items[item].familyId
          machineWithFlavours.items[item]["flavorName"] = props.drinksInfo[familyId]["flavours"][flavorId].nameEn
        }
      })
      setPopoverData(machineWithFlavours)
      props.resetMachineDataUpdated()
    }
  },[props.allMachinesData, props.allMachinesDataUpdated])


  {/*useEffect to handle the mapping of configs to machines*/}

  //use effect that checks and loads all data needed to map correct machine configs to every machine that the gym owner owns
  useEffect(() => {
    if (!props.allMachinesDataFetched || !props.allConfigInfoFetched) return; 
    const relevantMachines = Object.values(props.gymsData).filter(
      (machine) => machine.machineConfig // make sure machine has a config
    );
  
    const newVersionData = relevantMachines.flatMap((machine) => {// create new array with each machines name and its next config
      const { current } = machine.machineConfig;
      return Object.values(props.allConfigInfo)
        .filter((config) => config.id === current) // find matching config
        .map((config) => ({
          ...machine, 
          version: config.name, // add machines name
        }));
    });
  
    setFilteredMachines(newVersionData)
  
  }, [props.allMachinesDataFetched, props.allConfigInfoFetched, props.gymsData]);
  

  // Handle sorting logic
  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // this is done to save the filters selected
  const handleFilterChange = (filterType, selectedOption) => {
    if (filterType === "size") {
      // Only update to the last selected size
      setSizeFilter(selectedOption); // Directly set the selected size filter
    } else if (filterType === "type") {
      setTypeFilter([selectedOption]); // If only one type filter can be selected, use an array
    }
  };

  const clearFilters = () => {
    setSizeFilter([]);
    setTypeFilter([]);
    setAppliedFilters(false); // Reset the applied filters flag
    setIsPopoverFilterVisible(false); // Close the popover
    setFilteredMachines(props.allMachinesData); // Reset the data to the full set
  };

  // this is done to apply the filters on the data
  const applyFilters = () => {
    // Check if no filters are applied
    if (sizeFilter.length === 0 && typeFilter.length === 0) {
      // No filters applied, just sort by machineId
      const sortedMachines = [...props.allMachinesData].sort((a, b) => {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
      });
  
      setFilteredMachines(sortedMachines); // Set sorted machines with no filters applied
      setAppliedFilters(false); // Mark that no filters are applied
    } else {
      // Apply filters if there are selected Filters      
      let filteredSizes = sizeFilter; // Start with the selected size
      
      // Add smaller sizes if the selected size contains "<"
      if (sizeFilter.length > 0 && sizeFilter[0].includes("<")) {
        const sizeValue = parseInt(sizeFilter[0].replace("<", ""));
        sizeOptions.forEach((sizeOption) => {
          if (sizeOption.includes("<")) {
            const size = parseInt(sizeOption.replace("<", ""));
            if (size < sizeValue) {
              filteredSizes.push(sizeOption);
            }
          }
        });
      }

      const newFilteredMachines = props.allMachinesData
        .filter((machineData) => {
          const matchesSize = filteredSizes.length === 0 || filteredSizes.includes(machineData.size);
          const matchesType = typeFilter.length === 0 || typeFilter.includes(machineData.type);

          return matchesSize && matchesType;
        })
        .sort((a, b) => {
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;
          return 0;
        }); // Sort filtered data by machineId

      setFilteredMachines(newFilteredMachines);
      setAppliedFilters(true); // Mark that filters are applied
    }
  
    setIsPopoverFilterVisible(false); // Close the popover
  };


  // this is to change the order of the gyms displays
  const renderSortIndicator = (key) => {
    const isActive = sortConfig.key === key;
    const ascending = isActive && sortConfig.direction === "ascending";
    const descending = isActive && sortConfig.direction === "descending";

    return (
      <span className="ml-2 text-gray-500">
        <span className={`${descending ? "text-black" : ""}`}>↑</span>
        <span className={`${ascending ? "text-black" : ""}`}>↓</span>
      </span>
    );
  };

   // this is the pop over to select the different filters to apply
   const PopoverContentFilter = () => (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-30"
      onClick={() => setIsPopoverFilterVisible(false)}
    >
      <div
        className="bg-white rounded-lg shadow-lg p-6 relative"
        style={{
          width: "min(90%, 550px)",
          maxHeight: "50vh",
          display: "flex",
          flexDirection: "column",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Popover Content */}
        <div className="flex items-start">
          <button onClick={() => setIsPopoverFilterVisible(false)} className="text mr-4">
            ✕
          </button>
          <h3 className="text-center flex-grow font-semibold -ml-[14px]">Filtres</h3>
        </div>
        <hr className="mt-[20px] border-gray-300" />
  
        <div className="overflow-y-auto">
        {/* Selected Filters Section */}
        {sizeFilter.length > 0 || typeFilter.length > 0 ? (
          <div>
            <h4 className="font-semibold mt-4 mb-[15px]">Filtres sélectionnés</h4>
            <div className="flex flex-wrap gap-2 mt-2">
            {sizeFilter.length > 0 && (
              <div
                className="flex items-center px-5 py-3 border border-orange-500 rounded-full bg-orange-100"
              >
                <span className="font-semibold mr-2" style={{ fontSize: "14px" }}>{sizeFilter}</span>
                <button
                  className="text-gray-500 hover:text-red-500"
                  style={{ fontSize: "14px" }}
                  onClick={() => setSizeFilter("")} // Clear the size filter
                >
                  ✕
                </button>
              </div>
            )}
            {typeFilter.map((filter, idx) => (
              <div
                key={idx}
                className="flex items-center px-5 py-3 border border-orange-500 rounded-full bg-orange-100"
              >
                <span className="font-semibold mr-2" style={{ fontSize: "14px" }}>{filter}</span>
                <button
                  className="text-gray-500 hover:text-red-500"
                  style={{ fontSize: "14px" }}
                  onClick={() => {
                    setTypeFilter(typeFilter.filter((f) => f !== filter));  // Remove selected type filter
                  }}
                >
                  ✕
                </button>
              </div>
            ))}
            </div>
            <hr className="mt-[20px] border-gray-300" />
          </div>
        ) : null}
  
        {/* Size Filters */}
        <h4 className="font-semibold mt-4 text-left mb-[15px]">Taille de salle</h4>
        <div className="flex flex-row flex-wrap gap-2 mt-2">
          {sizeOptions.map((option) => (
            <button
              key={option}
              onClick={() => handleFilterChange("size", [option])}
              className={`text-left px-5 py-3 ${sizeFilter.includes(option) ? "bg-orange-100 border-orange-500" : ""} border rounded-full`}
              style={{ fontSize: "14px" }}
            >
              {option}
            </button>
          ))}
        </div>
        <hr className="mt-[25px] border-gray-300" />
  
        {/* Type Filters */}
        <h4 className="font-semibold mt-[20px] text-left mb-[15px]">Type de salle</h4>
        <div className="flex flex-row flex-wrap gap-2 mt-2 mb-[80px]">
          {typeOptions.map((option) => {
            let icon = null;
  
            switch (option) {
              case "muscu":
                icon = <FaDumbbell className="text-lg mr-2" />;
                break;
              case "crossfit":
                icon = <FaRunning className="text-lg mr-2" />;
                break;
              case "office":
                icon = <FaBriefcase className="text-lg mr-2" />;
                break;
              case "general":
                icon = <FaBuilding className="text-lg mr-2" />;
                break;
              default:
                break;
            }
  
            return (
              <button
                key={option}
                onClick={() => handleFilterChange("type", option)}
                className={`text-left px-5 py-3 ${typeFilter.includes(option) ? "bg-orange-100 border-orange-500" : ""} border flex rounded-full`}
                style={{ fontSize: "14px" }}
              >
                {icon}
                {option}
              </button>
            );
          })}
        </div>
        
        </div>
        {/* Footer with Clear and Apply Buttons */}
        <div 
        className="absolute bottom-0 left-0 w-full bg-white border-t border-gray-300 shadow-md px-6 py-4 flex justify-between items-center"
        style={{
          boxShadow: "0 -3px 6px rgba(0, 0, 0, 0.2)", // Adds a subtle shadow on the top border of the footer
          borderBottomLeftRadius: "0.5rem", 
          borderBottomRightRadius: "0.5rem"
        }}
        >

          <button
            onClick={clearFilters}
            className="text-black hover:text-red-500"
            style={{fontWeight: 500}}
          >
            Tout effacer
          </button>
          <button
            onClick={applyFilters}
            className="text-white bg-orange-500 px-5 py-2.5"
            style={{fontWeight: 600, borderRadius: "0.5rem"}}
          >
            Afficher les salles
          </button>
        </div>
      </div>
    </div>
  );

  // this is the pop over to display the information of the machine selected
  const PopoverContent = () => (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-30"
      onClick={closePopover}
    >
      <div
        className="bg-white rounded-lg shadow-lg p-6"
        style={{
          width: "min(70%, 700px)",
          maxHeight: "80vh",
          overflowY: "auto",
        }}
        onClick={(e) => e.stopPropagation()} // Prevent click from closing the popover
      >
        <p className="mb-2">
          <span className="font-semibold text-left">Nom de salle:</span> {popoverData.nameGym}
        </p>
        {props.privilege === "fiilin" ? (<p className="mb-2">
          <span className="font-semibold text-left">Machine ID:</span> {popoverData.id}
        </p>) : (null)}
        <p className="mb-4">
          <span className="font-semibold">Gérant:</span> {popoverData.nameOwner}
        </p>
        <p className="mb-4">
          <span className="font-semibold">Adresse:</span> {popoverData.addressGym}
        </p>
        <p className="mb-2">
          <span className="font-semibold">Boissons</span>
        </p>
        <div className="mt-2 border border-gray-300 rounded-md">
          <Table className="w-full text-sm border border-gray-300 rounded-md overflow-hidden">
            <TableHeader className="bg-white border-b border-gray-300">
              <TableRow className="hover:bg-transparent">
                <TableHeadCell className="flex-grow ml-4 px-2 py-2 text-center">Boisson Nom</TableHeadCell>
                <TableHeadCell className="w-[230px] mr-5 px-2 py-2 text-center">Sold out</TableHeadCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {Object.values(popoverData.items).map((item, index) => (
                <TableRow key={index} className="hover:bg-gray-100 h-[60px] flex items-center justify-center">
                  <TableCell className="flex-grow px-2 text-center">{item.flavorName}</TableCell>
                  <TableCell className="w-[230px] h-[60px] px-2 flex items-center justify-center text-center">
                    <MenuBar menuItems={soldOutMenuItems} soldOutOption={true} soldOutMachineInfo={popoverData} soldOutDrinkInfo={item}/>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        {popoverData?.complaints?.length > 0 && (
        <>
          <p className="mb-2">
            <span className="font-semibold">Plaintes</span>
          </p>
          <div className="mt-2 border border-gray-300 rounded-md">
            <Table className="w-full text-sm border border-gray-300 rounded-md overflow-hidden">
              <TableHeader className="bg-white border-b border-gray-300">
                <TableRow className="hover:bg-transparent">
                  <TableHeadCell className="px-4 py-2 text-center">Date</TableHeadCell>
                  <TableHeadCell className="px-4 py-2 text-center">Détails</TableHeadCell>
                  <TableHeadCell className="px-4 py-2 text-center">Dernière boisson</TableHeadCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {popoverData.complaints.map((complaint, index) => (
                  <TableRow key={index} className="hover:bg-gray-100">
                    <TableCell className="px-4 py-2 text-center">{complaint.date}</TableCell>
                    <TableCell className="px-4 py-2 text-center">{complaint.complaintContent}</TableCell>
                    <TableCell className="px-4 py-2 text-center">{complaint.lastDrinkMachine}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </>
        )}

      </div>
    </div>
  );

  // this opens the pop over for the machine data
  const handlePopover = (event, machine) => {
    console.log(machine)
    event.stopPropagation();
    setPopOverMachine(machine);
    let machineWithFlavours = machine;
    Object.keys(machine.items).map((item) => {
      if (machine.items[item]) {
        const flavorId = machine.items[item].flavorId
        const familyId = machine.items[item].familyId
        machineWithFlavours.items[item]["flavorName"] = props.drinksInfo[familyId]["flavours"][flavorId].nameEn
      }
    })
    setPopoverData(machineWithFlavours);
    setIsPopoverVisible((prev) => (popoverData?.id === machine.id ? !prev : true));

  };


  const closePopover = () => {
    setIsPopoverVisible(false);
  };
  
  const menuItems = [
    { name: "Distributeurs", route: "/management/machines" },
    { name: "Plaintes", route: "/management/plaintes" },
  ];

  const soldOutMenuItems = [
    { name: "En stock", type:"stock"},
    { name: "Épuisé", type:"soldout"},
  ];

  if (props.allMachinesDataFetched) {
    return (
      <div className="min-h-screen flex flex-col w-full p-6 bg-customOrange-light" style={{ marginTop: "20px", paddingLeft: "4%", paddingRight: "4%" }}>
        {/* MenuBar */}
        <div className="flex justify-start pt-8">
          <MenuBar menuItems={menuItems} />
        </div>
          {/* Filters Section */}
          <div className="p-4 flex-shrink overflow-auto bg-white border border-transparent rounded-xl shadow w-full" style={{ marginBottom: "20px" }}>
          {/* Filters Button */}
          {props.privilege === "fiilin" ? (<button
          onClick={() => setIsPopoverFilterVisible(true)}
          className="flex items-center justify-between px-4 py-2 border border-black rounded-xl text-left whitespace-nowrap hover:bg-transparent relative"
          style={{
            borderColor: appliedFilters ? "orange" : "hsl(214.3 31.8% 91.4%)",
            borderWidth: appliedFilters > 0 ? 2 : 1
          }}
          >
            <img
              alt=""
              src={Filter}  // Make sure to replace this with the correct path to your image
              // alt="Filter Icon"
              className="mr-2"  // Adjust the margin as needed
              style={{ width: "20px", height: "auto" }}  // Adjust the size of the icon
            />
            Filtres
            { appliedFilters && 
            <span
              className={`absolute top-[-8px] right-[-8px] flex items-center justify-center w-5 h-5 text-xs font-semibold text-white ${sizeFilter.length || typeFilter.length ? "bg-orange-500" : "bg-transparent"}`}
              style={{
                borderRadius: "50%",  // Make it a circle
                padding: "2px", // Padding for better alignment
              }}
            >
              {(
                (sizeFilter.length > 0 ? 1 : 0) + // Count size filter as 1 if selected
                typeFilter.length // Count number of type filters selected
              ) > 0 ? `${(sizeFilter.length > 0 ? 1 : 0) + typeFilter.length}` : ""}
            </span>
            }
          </button>) : (null)}
          <Table className="w-full table-fixed border-collapse">
            <TableHeader>
              <TableRow className="hover:bg-transparent">
                <TableHeadCell className="w-[50px] text-center text-sm text-black flex-grow">Active</TableHeadCell>
                {props.privilege === "fiilin" && (
                  <TableHeadCell className="w-[150px] px-4 py-4 text-center text-sm text-black flex-grow">Machine ID</TableHeadCell>
                )}
                <TableHeadCell 
                  className="w-[200px] cursor-pointer px-4 py-4 text-center text-sm text-black flex-grow"
                  onClick={() => handleSort("nameGym")}
                >
                  Nom salle {renderSortIndicator("nameGym")}
                </TableHeadCell>
                  <TableHeadCell className="w-[150px] text-center text-sm text-black flex-grow">Version</TableHeadCell>
                {!isPhone && (
                  <TableHeadCell className="w-[200px] px-4 py-4 text-center text-sm text-black flex-grow">Adresse</TableHeadCell>
                )}
                {!isPhone && (
                  <TableHeadCell className="w-[120px] text-center text-sm text-black flex-grow">Taille</TableHeadCell>
                )}
                {!isPhone && ( <TableHeadCell className="w-[120px] text-center text-sm text-black flex-grow">Type</TableHeadCell>
                )}
              </TableRow>
            </TableHeader>

            <TableBody>
              {filteredMachines && (filteredMachines.map((filteredMachines) => (
                <TableRow className="table-row-hover" key={filteredMachines.id} onClick={(e) => handlePopover(e, filteredMachines)}>
                  
                  {/* Active Status */}
                  {isPhone ? (<TableCell className="w-[50px] text-center pl-2 text-sm text-gray-600 flex-grow">
                    {filteredMachines.isActive ? (
                      <div className="w-5 h-5 flex items-center justify-center rounded-full bg-green-500 text-white">
                        ✔
                      </div>
                    ) : (
                      <div className="w-5 h-5 flex items-center justify-center rounded-full bg-red-500 text-white">
                        ✖
                      </div>
                    )}
                  </TableCell>) : (
                    <TableCell className="w-[50px] text-center pl-14 text-sm text-gray-600 flex-grow">
                    {filteredMachines.isActive ? (
                      <div className="w-5 h-5 flex items-center justify-center rounded-full bg-green-500 text-white">
                        ✔
                      </div>
                    ) : (
                      <div className="w-5 h-5 flex items-center justify-center rounded-full bg-red-500 text-white">
                        ✖
                      </div>
                    )}
                  </TableCell>
                  ) }

                  {/* Show Machine ID for 'fiilin' privilege */}
                  {props.privilege === "fiilin" && (
                    <TableCell className="w-[150px] text-center text-sm flex-grow">{filteredMachines.id}</TableCell>
                  )}

                  {/* Gym Name */}
                  <TableCell className="w-[200px] text-center text-sm flex-grow">{filteredMachines.nameGym}</TableCell>

                  {/* Version Column (Aligned with Button) */}
                    <TableCell className="w-[150px] flex items-center justify-center text-center flex-grow">
                      {props.privilege === "gym" && filteredMachines["machineConfig"].next != null ? (
                        <button 
                          onClick={() => navigate("/management/configuration")}
                          className="bg-blue-600 text-white text-sm font-semibold px-4 h-[40px] min-w-[120px] rounded-full shadow flex items-center justify-center hover:bg-blue-700 transition duration-200"
                        >
                          Mettre à jour
                        </button>
                      ) : (
                        <span className="text-gray-600">{filteredMachines.version}</span>
                      )}
                    </TableCell>

                  {/* Address */}
                  {!isPhone && (
                    <TableCell className="w-[200px] text-center text-sm flex-grow">{filteredMachines.addressGym}</TableCell>
                  )}

                  {/* Size */}
                  {!isPhone && (
                    <TableCell className="w-[120px] text-center text-sm flex-grow">
                      <span style={getSizeStyle(filteredMachines.size)}>{filteredMachines.size}</span>
                    </TableCell>
                  )}

                  {/* Type */}
                  {!isPhone && (<TableCell className="w-[120px] text-center text-sm flex-grow">
                    <span style={getTypeStyle(filteredMachines.type)}>{filteredMachines.type}</span>
                  </TableCell> 
                  )}

                </TableRow>
              )))}
            </TableBody>
          </Table>

        </div>
        {isPopoverVisible && popoverData && <PopoverContent />}
        {isPopoverFilterVisible && <PopoverContentFilter />}
      </div>
    );
  } else {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen space-y-6">
        <RotatingLines strokeColor="#f08300" height={30} width={30} visible={true} animationDuration="1.25" />
      </div>
    );
  }
};


const mapStateToProps = (state) => ({
  userId: state.userState.userId,
  gymsData: state.gymState.gymsData,
  allMachinesData: state.machineState.allMachinesData,
  allMachinesDataFetched: state.machineState.allMachinesDataFetched,
  gymMachineIds: state.userState.gymMachineIds,
  privilege: state.userState.privilege,
  drinksInfo: state.drinksState.drinks,
  allMachinesDataUpdated: state.machineState.allMachinesDataUpdated,
  allConfigInfo: state.machineState.allConfigInfo,
  allConfigInfoFetched: state.machineState.allConfigInfoFetched,
});

const mapDispatchProps = (dispatch) => {
  return {
    reload: (userId, gymMachineIds) => {dispatch(reload(userId, gymMachineIds))},
    fetchAllMachinesData: (gymMachineIds, privilege) => {dispatch(fetchAllMachinesData(gymMachineIds, privilege))},
    fetchDrinksInfo: () => {dispatch(fetchDrinksInfo())},
    resetMachineDataUpdated: () => {dispatch(resetMachineDataUpdated())},
    fetchGymData: (machineId, nameGym) => dispatch(fetchGymData(machineId, nameGym)),
    fetchAllMachineConfigs: () => dispatch(fetchAllMachineConfigs()),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(MachinesPage);
