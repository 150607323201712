import React, { useState } from "react";
import menAvatar from "../../assets/images/men_avatar.png"; // Import male avatar
import womenAvatar from "../../assets/images/women_avatar.png"; // Import female avatar

const LastCustomersBox = ({ customers, month }) => {
  // State for handling the modal visibility and selected customer
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  // Function to open the modal
  const handleRowClick = (customer) => {
    setSelectedCustomer(customer);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCustomer(null);
  };

  return (
    <div className="p-4 bg-white border border-white rounded-xl shadow-md h-full overflow-y-auto">
      <h3 className="text-lg font-semibold ml-[25px] mt-[10px] mb-[35px]">
        Meilleurs clients en {month}
      </h3>
      <ul className="space-y-2">
        {customers.map((customer, index) => (
          <li
            key={index}
            className="flex items-center justify-between hover:bg-gray-200 border-transparent rounded-xl px-4 py-2 hover:cursor-pointer"
            onClick={() => handleRowClick(customer)} // Handle click on row
          >
            {/* Avatar */}
            <img
              src={customer.gender === "male" ? menAvatar : womenAvatar}
              alt={`${customer.name}'s avatar`}
              className="w-10 h-10 rounded-full object-cover flex-shrink-0"
            />
            {/* Text Block */}
            <div className="flex flex-grow flex-col justify-center items-center px-4">
              <p className="text-sm font-medium text-gray-700 text-center">
                {customer.firstName} {customer.lastName}
              </p>
              <p className="text-xs text-gray-500 text-center">{customer.email}</p>
            </div>
            {/* Amount Spent */}
            <div className="text-sm font-semibold text-gray-700 flex-shrink-0">
              {customer.totalSpent.toFixed(2)}€
            </div>
          </li>
        ))}
      </ul>

      {/* Modal Popup */}
      {isModalOpen && selectedCustomer && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-md w-1/3">
            <h2 className="text-xl font-semibold mb-4">Customer Details</h2>
            <p><strong>Name:</strong> {selectedCustomer.firstName} {selectedCustomer.lastName}</p>
            <p><strong>Email:</strong> {selectedCustomer.email}</p>
            <p><strong>Total Spent:</strong> {selectedCustomer.totalSpent.toFixed(2)}€</p>
            <button
              onClick={handleCloseModal}
              className="mt-4 bg-red-500 text-white py-2 px-4 rounded-full hover:bg-red-600"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LastCustomersBox;
