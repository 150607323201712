import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RotatingLines } from "react-loader-spinner";
import CustomMultiSelect from "./customMultiSelection";
import {
  fetchGymData,
} from "src/store/actions/gyms";
import { fetchAllMachinesData } from "src/store/actions/machine";
import MultiLineChart from "src/utils/dashboard/multiLineChart";
import MenuBar from "src/utils/dashboard/menuBar";


// this component is for the analysis of different gyms. 
// We select different gyms and then it makes the comparison between them
const Analysis = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [combinedData, setCombinedData] = useState([]);
  const currentDate = new Date();
  const currentStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  const currentMonthId = `${currentStartDate.getFullYear()}-${String(currentStartDate.getMonth() + 1).padStart(2, '0')}`

  {/*Menu Bar routing dictionary */}
  const menuItems = props.privilege == "fiilin" ? 
  [
    { name: "Dashboard", route: "/finance/dashboard" },
    { name: "Analysis", route: "/finance/analysis" },
    {name: "Overview", route: "/finance/overview"}
  ] : [    
    { name: "Dashboard", route: "/finance/dashboard" },
    { name: "Analysis", route: "/finance/analysis" },
  ]

  // it fetches the data of the gyms if the data has not been fetched before
  const fetchGymDataIfNotPresent = (machineId, gymName) => {
    const gymData = props.gymsData[machineId];
    if (gymData && !gymData[currentMonthId]?.finance) {
      console.log("fetchGymData: ", machineId)
      props.fetchGymData(machineId, gymName);
    }
  };

  useEffect(() => {
    if (!props.allMachinesDataFetched) {
      props.fetchAllMachinesData();
    }
  }, [props.allMachinesDataFetched, props.gymsData]);

  // Fetch data for selected gyms
  useEffect(() => {
    if (props.selectedGymsAnalysis.length > 0) {
        setIsLoading(true);
        props.selectedGymsAnalysis.forEach((gym) => fetchGymDataIfNotPresent(gym.id, gym.name));
    }
  }, [props.selectedGymsAnalysis]);

  // We need to adapt the data of the gyms to sort it by date and to combine the data of all the gyms selected 
  const transformGymData = (gymsData) => {
    const gyms = Object.values(gymsData);
  
    // Extract unique dates and sort them by date
    const uniqueDates = [
      ...new Set(
        gyms
          .map((gym) => gym["financials"][currentMonthId]?.finance?.dailyDrinks?.map((entry) => entry.date))
          .flat()
      ),
    ].sort((a, b) => new Date(a) - new Date(b)); // Sort dates in ascending order
  
    const transformedData = uniqueDates.map((date) => {
      const drinksEntry = { date };
      const revenueEntry = { date };
  
      gyms.forEach((gym) => {
        const dailyDrinks = gym["financials"][currentMonthId]?.finance?.dailyDrinks?.find((entry) => entry.date === date);
        const dailyRevenue = gym["financials"][currentMonthId]?.finance?.dailyRevenue?.find((entry) => entry.date === date);
  
        drinksEntry[gym.name] = dailyDrinks ? dailyDrinks.drinks : 0;
        revenueEntry[gym.name] = dailyRevenue ? dailyRevenue.revenue : 0;
      });
  
      return { drinks: drinksEntry, revenue: revenueEntry };
    });
  
    return transformedData;
  };
  
  // Combine data for AreaGraph
  useEffect(() => {
    if (props.selectedGymsAnalysis.length > 0) {
      // Check if all selected gyms have their data loaded
      const allDataFetched = props.selectedGymsAnalysis.every(
        (gym) =>
          props.gymsData[gym.id] &&
          props.gymsData[gym.id]["financials"][currentMonthId]?.finance &&
          props.gymsData[gym.id]["financials"][currentMonthId]?.memberships
      );
    

      if (allDataFetched) {
        // Filter props.gymsData to include only selected gyms
        const filteredGymsData = props.selectedGymsAnalysis.reduce((acc, gym) => {
          if (props.gymsData[gym.id]) {
            acc[gym.id] = props.gymsData[gym.id];
          }
          return acc;
        }, {});
    
        // Transform the filtered data
        const data = transformGymData(filteredGymsData);
        setCombinedData(data);
        setIsLoading(false);
      }
    } else {
      // If no gyms are selected, reset the combined data
      setCombinedData([]);
    }
  }, [props.gymsData, props.selectedGymsAnalysis]);
    
  

return (
  <div className="min-h-screen flex flex-col w-full p-6 bg-customOrange-light" style={{ marginTop: "20px", paddingLeft: "4%", paddingRight: "4%" }}>
  {/* MenuBar */}
  <div className="flex justify-start pt-8">
    <MenuBar menuItems={menuItems} />
  </div>
  {/* Gym Selector */}
  <CustomMultiSelect machines={props.allMachinesData} />
    {/* Loading and Graph Content */}
    {isLoading ?
        (
          <div className="flex justify-center items-center h-64">
            <RotatingLines strokeColor="#f08300" height={30} width={30} visible={true} animationDuration="1.25" />
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-4 w-full pt-3">
            {combinedData.length > 0 && (
              <MultiLineChart
                data={combinedData.map((entry) => entry.drinks)}
                title="Nombre de boissons"
                gyms={props.selectedGymsAnalysis.map((gym) => ({
                  id: gym.name,
                  nameGym: gym.name,
                }))}
                dataKey="drinks"
                revenue={true}
                nameTooltip="Revenu"
                colorLine="#001B25"
                colorArea="#90CAF9"
              />
            )}
            {combinedData.length > 0 && (
              <MultiLineChart
                data={combinedData.map((entry) => entry.revenue)}
                title="Revenu boissons"
                gyms={props.selectedGymsAnalysis.map((gym) => ({
                  id: gym.name,
                  nameGym: gym.name,
                }))}
                dataKey="revenue"
                revenue={true}
                nameTooltip="Revenu"
                colorLine="#001B25"
                colorArea="#90CAF9"
              />
            )}
          </div>
        )
    }
  </div>
);

};

const mapStateToProps = (state) => ({
    gymsData: state.gymState.gymsData,
    gymDataFetched: state.gymState.gymDataFetched,
    allMachinesData: state.machineState.allMachinesData,
    allMachinesDataFetched: state.machineState.allMachinesDataFetched,
    selectedGymsAnalysis: state.gymState.selectedGymsAnalysis,
    privilege: state.userState.privilege
});

const mapDispatchProps = (dispatch) => ({
    fetchGymData: (machineId, gymsData) => dispatch(fetchGymData(machineId, gymsData)),
    fetchAllMachinesData: () => dispatch(fetchAllMachinesData())
});

export default connect(mapStateToProps, mapDispatchProps)(Analysis);
