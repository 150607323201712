import { tab } from "@testing-library/user-event/dist/tab";

  //general transform function to parse data for various bars and graph components
  const transformDataGraph = (data, graphType, dateChanged, selectedMonths, dateRange, selectedMonthId) => {

    const handleSingleMonth = (data, graphType) => { //this handles the case if only 1 month is selected
      switch(graphType){

        case 'histogramBar': //uses HistogramBar component, Répartition des boissons
          return [
                  { name: "Gratuites", value: data[selectedMonthId]["finance"]["freeDrinks"] },
                  { name: "Payantes", value: data[selectedMonthId]["finance"]["paidDrinks"] },
                  { name: "Abonnements", value: data[selectedMonthId]["finance"]["membershipDrinks"] },
                  { name: "Packs", value: data[selectedMonthId]["finance"]["packDrinks"] },
                ];

        case 'overviewRevenueHistogram': //uses HistogramBar component, for overview page repartition of revenue
          return data; 

        case 'overviewDrinksHistogram': //uses HistogramBar component, for overview page repartition of revenue
          return data;  

        case 'areaGraph'://uses  AreaGraph component, Evolution du revenu boisson, Evolution du nombre boisson
          return data[selectedMonthId]["finance"]["dailyRevenue"];

        case 'stackedGraph'://uses StackedAreaChart component, Répartition des boissons sportives et protéinées
          return data[selectedMonthId]["finance"]["sportAndProtein"];

        case 'donutGraph'://uses DonutChart component, Répartition des abonnements
          return data[selectedMonthId]["memberships"];

        case 'overviewDrinksGraph'://uses StackedBarChart component, Répartition des boissons for overview page
          let rawDataDrinks = Object.values(data).reduce((acc, gym) => { //use reduce to iterate over gyms and accumulate months
            Object.keys(gym["financials"])
              .filter(month => month == selectedMonthId) //filter out months out of range for each month
              .forEach(month => { //iterate through each month and check if its already in accumulator
                const monthData = gym["financials"][month];
          
                // If month exists, accumulate values
                if (acc[month]) {
                  acc[month].Gratuites += monthData.finance.freeDrinks;
                  acc[month].Abonnements += monthData.finance.membershipDrinks;
                  acc[month].Packs += monthData.finance.packDrinks;
                  acc[month].Payantes += monthData.finance.paidDrinks;
                } else {
                  // Otherwise, initialize it
                  acc[month] = {
                    month: month,
                    Gratuites: monthData.finance.freeDrinks,
                    Abonnements: monthData.finance.membershipDrinks,
                    Packs: monthData.finance.packDrinks,
                    Payantes: monthData.finance.paidDrinks,
                  };
                }
              });
          
            return acc;
          }, {}); // Start with an empty object
          return Object.values(rawDataDrinks);
        
        case 'overviewRevenueGraph'://uses StackedBarChart component, Répartition des boissons for overview page
          let rawDataRevenue = Object.values(data).reduce((acc, gym) => { //use reduce to iterate over gyms and accumulate months
            Object.keys(gym["financials"])
              .filter(month => month == selectedMonthId) //filter out months out of range for each month
              .forEach(month => { //iterate through each month and check if its already in accumulator
                const monthData = gym["financials"][month];
          
                // If month exists, accumulate values
                if (acc[month]) {
                  acc[month].UnitRevenue += Math.round(monthData.finance.revenue);
                  acc[month].MembershipRevenue += Math.round(monthData.memberships.moneyMemberships);
                  acc[month].AppMembershipRevenue += monthData.memberships.moneyAppMemberships ? Math.round(monthData.memberships.moneyAppMemberships) : 0;
                } else {
                  // Otherwise, initialize it
                  acc[month] = {
                    month: month,
                    UnitRevenue: Math.round(monthData.finance.revenue),
                    MembershipRevenue: Math.round(monthData.memberships.moneyMemberships),
                    AppMembershipRevenue: monthData.memberships.moneyAppMemberships ? Math.round(monthData.memberships.moneyAppMemberships) : 0,
                  };
                }
              });
          
            return acc;
          }, {}); // Start with an empty object
        return Object.values(rawDataRevenue);

        case 'productTable':
          //console.log(data)
          let tableData = Object.values(data).reduce((acc, gym) => { //use reduce to iterate over gyms and accumulate months
            if (Object.keys(gym["financials"])) {
              Object.keys(gym["financials"])
              .filter(month => month == selectedMonthId) //filter out months out of range for each month
              .forEach(month => { //iterate through each month and check if its already in accumulator
                const drinkData = gym["financials"][month]["finance"].productData;
                drinkData.map(type => {
                  if (acc[type.name]) {
                    acc[type.name].revenue += type.revenue;
                    acc[type.name].drinks += type.drinks;
                  } else {
                    acc[type.name] = {
                      revenue: type.revenue,
                      drinks: type.drinks,
                      type: type.type,
                      name: type.name
                    }
                  }
                })
              });
            }
            return acc;
          }, {}); // Start with an empty object
        return tableData;

        default:
          return data[selectedMonthId];
      }
    }

    const handleMultipleMonth = (data, graphType, formattedStartDate, formattedEndDate) => { //handles the case of multiple months data

      let inRangeMonthData = Object.keys(data)  // get all the months in range in an array
        .filter((key) => {
          const month = key;  // current month in YYYY-MM format
          return month >= formattedStartDate && month <= formattedEndDate;  //only include months within the range
        })

      switch(graphType){

        case 'productTable':
          let tableData = Object.values(data).reduce((acc, gym) => { //use reduce to iterate over gyms and accumulate months
            Object.keys(gym["financials"])
              .filter(month => month >= formattedStartDate && month <= formattedEndDate) //filter out months out of range for each month
              .forEach(month => { //iterate through each month and check if its already in accumulator
                const drinkData = gym["financials"][month]["finance"].productData;
                drinkData.map(type => {
                  if (acc[type.name]) {
                    acc[type.name].revenue += type.revenue;
                    acc[type.name].drinks += type.drinks;
                  } else {
                    acc[type.name] = {
                      revenue: type.revenue,
                      drinks: type.drinks,
                      type: type.type,
                      name: type.name
                    }
                  }
                })
              });
          
            return acc;
          }, {}); // Start with an empty object
        return tableData;

        case 'overviewDrinksGraph'://uses StackedBarChart component, Répartition des boissons for overview page
          let rawDataDrinks = Object.values(data).reduce((acc, gym) => { //use reduce to iterate over gyms and accumulate months
            Object.keys(gym["financials"])
              .filter(month => month >= formattedStartDate && month <= formattedEndDate) //filter out months out of range for each month
              .forEach(month => { //iterate through each month and check if its already in accumulator
                const monthData = gym["financials"][month];
          
                // If month exists, accumulate values
                if (acc[month]) {
                  acc[month].Gratuites += monthData.finance.freeDrinks;
                  acc[month].Abonnements += monthData.finance.membershipDrinks;
                  acc[month].Packs += monthData.finance.packDrinks;
                  acc[month].Payantes += monthData.finance.paidDrinks;
                } else {
                  // Otherwise, initialize it
                  acc[month] = {
                    month: month,
                    Gratuites: monthData.finance.freeDrinks,
                    Abonnements: monthData.finance.membershipDrinks,
                    Packs: monthData.finance.packDrinks,
                    Payantes: monthData.finance.paidDrinks,
                  };
                }
              });
          
            return acc;
          }, {}); // Start with an empty object
          return Object.values(rawDataDrinks);
        
        case 'overviewRevenueGraph'://uses StackedBarChart component, Répartition des boissons for overview page
          let rawDataRevenue = Object.values(data).reduce((acc, gym) => { //use reduce to iterate over gyms and accumulate months
            Object.keys(gym["financials"])
              .filter(month => month >= formattedStartDate && month <= formattedEndDate) //filter out months out of range for each month
              .forEach(month => { //iterate through each month and check if its already in accumulator
                const monthData = gym["financials"][month];
          
                // If month exists, accumulate values
                if (acc[month]) {
                  acc[month].UnitRevenue += Math.round(monthData.finance.revenue);
                  acc[month].MembershipRevenue += Math.round(monthData.memberships.moneyMemberships);
                  acc[month].AppMembershipRevenue += monthData.memberships.moneyAppMemberships ? Math.round(monthData.memberships.moneyAppMemberships) : 0;
                } else {
                  // Otherwise, initialize it
                  acc[month] = {
                    month: month,
                    UnitRevenue: Math.round(monthData.finance.revenue),
                    MembershipRevenue: Math.round(monthData.memberships.moneyMemberships),
                    AppMembershipRevenue: monthData.memberships.moneyAppMemberships ? Math.round(monthData.memberships.moneyAppMemberships) : 0,
                  };
                }
              });
          
            return acc;
          }, {}); // Start with an empty object
        return Object.values(rawDataRevenue);

        case 'barGraph': //uses StackedBarChart component, Répartition des boissons
          return inRangeMonthData.map((key) => {
            const monthData = data[key];  // get the data for the current month

            return {
              month: key,
              Gratuites: monthData.finance.freeDrinks,
              Abonnements: monthData.finance.membershipDrinks,
              Packs: monthData.finance.packDrinks,
              Payantes: monthData.finance.paidDrinks,
            };
          });

        case 'barGraphSubscriptions': //uses StackedBarChart component, Répartition des abonnements
          return inRangeMonthData.map((key) => {
            const monthData = data[key]["memberships"]["memberships"];  // get the data for the current month
            let categoryDict = {}
            categoryDict["month"] = key
            for (let category in monthData){
              categoryDict[category] = monthData[category].total
            }
            return categoryDict;
          });

        case 'areaGraph': //uses  AreaGraph component, Evolution du revenu boisson, Evolution du nombre boisson
          return inRangeMonthData.map((key) => {
            const monthData = data[key]["finance"]["dailyRevenue"];  // Get the data for the current month | object = [ {2024-11:{}}, {2025-12:{}}, ... ] | monthData = {2024-11:{}}
      
            return monthData.map((entry) => ({ // flatten monthData into an array of daily revenue and drinks for each day | object = [ [{}, {}, ...], [{}, {}, ...], ...] 
              date: entry.date,         // Add the month for each day's data
              revenue: entry.revenue,  // Extract daily revenue
              drinks: entry.drinks,    // Extract daily drinks
            }))
          })
          .flat();

        case 'stackedGraph': //uses StackedAreaChart component, Répartition des boissons sportives et protéinées
          return inRangeMonthData.map((key) => {
            const monthData = data[key]["finance"]["sportAndProtein"];  // Get the data for the current month | object = [ {2024-11:{}}, {2025-12:{}}, ... ] | monthData = {2024-11:{}}
            return monthData.map((entry) => ({ // flatten monthData into an array of daily revenue and drinks for each day | object = [ [{}, {}, ...], [{}, {}, ...], ...] 
              date: entry.date,         // Add the month for each day's data
              protein: entry.protein,  // Extract daily revenue 
              sport: entry.sport,    // Extract daily drinks
            }));
          })
          .flat();

        case 'donutGraph': //uses DonutChart component, Répartition des abonnements
          return inRangeMonthData.map((key) => {
            const monthData = data[key]["memberships"]["memberships"];  // get the data for the current month
            let categoryDict = {}
            for (let category in monthData){
              categoryDict[category] = monthData[category]
            }
            return categoryDict;
          });

        default:
          return data[selectedMonthId];
      }
    }

    if (dateChanged && selectedMonths > 1) { //check if its a date range or not and call appropriate function
      const formattedStartDate = `${dateRange[0].getFullYear()}-${String(dateRange[0].getMonth() + 1).padStart(2, '0')}`;
      const formattedEndDate = `${dateRange[1].getFullYear()}-${String(dateRange[1].getMonth() + 1).padStart(2, '0')}`;
      return handleMultipleMonth(data, graphType, formattedStartDate, formattedEndDate)
    } else if (dateChanged) {
      return handleSingleMonth(data, graphType)
    }
  }

  export default transformDataGraph;