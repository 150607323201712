
import {fetchAllMachinesData} from "./machine"
import { fetchAllOwnersData } from "./owners"


export function reload(clientId, machineIds) {
    // this method calls all the methods that fetch data at onces
    return ((dispatch) => {
        dispatch(fetchAllMachinesData(machineIds))
        dispatch(fetchAllOwnersData())
    })
}
  