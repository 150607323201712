import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { setSelectedGymsAnalysis } from "src/store/actions/gyms";
import { FaCheck } from 'react-icons/fa';  // Import checkmark icon
import { IoMdFitness } from "react-icons/io";



const CustomMultiSelect = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelectGym = (gym) => {
    let updatedSelection;
    // Check if the gym with the same ID is already selected
    if (props.selectedGymsAnalysis.some((selectedGym) => selectedGym.id === gym.id)) {
      // Remove the gym if it's already selected
      updatedSelection = props.selectedGymsAnalysis.filter(
        (selectedGym) => selectedGym.id !== gym.id
      );
    } else {
      // Add the gym if it's not selected
      updatedSelection = [...props.selectedGymsAnalysis, gym];
    }
    props.setSelectedGymsAnalysis(updatedSelection); // Update the parent component
  };
  
  const removeGym = (gym) => {
    // Remove the gym by ID
    const updatedSelection = props.selectedGymsAnalysis.filter(
      (selectedGym) => selectedGym.id !== gym.id
    );
    props.setSelectedGymsAnalysis(updatedSelection); // Update the parent component
  };
  

  // Close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full mb-[10px]" ref={dropdownRef}>
      <div className="flex items-center gap-2">
        {/* Dropdown Button */}
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="py-2 bg-white border rounded-full shadow-sm text-left whitespace-nowrap flex items-center justify-center text-sm"
          style={{paddingRight: "20px"}}
          type="button"
        >
          <div
            className="flex items-center justify-center w-[30px] h-[30px] rounded-full bg-[#001B25] text-white ml-[10px] mr-[10px]"  // Circle for the icon
            style={{ fontSize: "14px" }}  // Adjust the icon size inside the circle
          >
            {/* Replace this with your gym icon, here I'm using a placeholder text */}
            <IoMdFitness className="text-lg" /> {/* Icon with margin and size */}
          </div>
          {props.selectedGymsAnalysis.length > 0 ? "Ajouter des salles" : "Sélectionner des salles"}

          {/* Icon inside a circle next to the button text */}
        </button>

        {/* Selected gyms as tags */}
        <div className="flex flex-wrap gap-2">
          {props.selectedGymsAnalysis.map((gym) => (
            <div
              key={gym.id}
              className="flex items-center px-5 py-3 border rounded-full bg-orange-100 shadow-sm border-orange-500"
            >
              <span className="text-sm font-sm mr-2">{gym.nameGym}</span>
              <button
                className="text-sm text-gray-500 hover:text-red-500"
                onClick={() => removeGym(gym)}
              >
                ✕
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Dropdown List */}
      {isOpen && (
        <ul className="absolute left-0 z-10 mt-1 bg-white border rounded-xl shadow-lg max-h-80 overflow-y-auto">
          {props.machines.map((machine) => (
            <li
              key={machine.id}
              className="flex items-center px-8 py-4 cursor-pointer hover:bg-gray-100"
            >
              {/* Checkbox */}
              <div
                className={`mr-3 w-5 h-5 flex items-center justify-center rounded-md border ${
                  props.selectedGymsAnalysis.some((gym) => gym.id === machine.id)
                    ? "bg-orange-500 border-orange-500"
                    : "bg-white border-gray-300"
                }`}
                onClick={() => handleSelectGym(machine)}
              >
                {props.selectedGymsAnalysis.some((gym) => gym.id === machine.id) && (
                  <FaCheck className="text-white w-3 h-3" /> 
                )}
              </div>
              {/* Gym Name */}
              <span
                className={`${
                  props.selectedGymsAnalysis.some((gym) => gym.id === machine.id)
                    ? "font-semibold"
                    : "font-normal"
                }`}
              >
                {machine.nameGym}
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
    selectedGymsAnalysis: state.gymState.selectedGymsAnalysis, 
});

const mapDispatchProps = (dispatch) => ({
    setSelectedGymsAnalysis: (selectedGyms) => dispatch(setSelectedGymsAnalysis(selectedGyms)),
});

export default connect(mapStateToProps, mapDispatchProps)(CustomMultiSelect);
