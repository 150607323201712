import React, { useEffect } from "react";
import MenuBar from "../../utils/dashboard/menuBar";
import { connect } from "react-redux";
import { fetchGymData } from "src/store/actions/gyms";
import { fetchAllMachinesData } from "src/store/actions/machine";
import CustomSelect from "../../utils/dashboard/customSelect";
import { useNavigate } from "react-router-dom";

const Settings = (props) => {
    const navigate = useNavigate();

    const menuItems = [
        { name: "Dashboard", route: "/finance/dashboard" },
        { name: "Reports", route: "/finance/reports" },
        { name: "Settings", route: "/finance/settings" },
    ];

    const handleSelect = (machineId, nameGym) => {
        props.fetchGymData(machineId, nameGym);
    };

    useEffect(() => {
        if (!props.allMachinesData && !props.allMachinesDataFetched) {
          navigate("/finance/dashboard")
        }
      }, [])

    return (
        <div className="min-h-screen flex flex-col w-full p-6 bg-white" style={{ marginTop: '20px' }}>
        {/* Header */}
            <div className="w-full border border-gray-200 rounded-lg shadow-md p-8">
                <CustomSelect machines={props.allMachinesData} onSelect={handleSelect} />
                <div className="relative">
                    <hr className="w-[calc(100%+64px)] -ml-8 border-t border-gray-300 my-4" />
                </div>
                <h1 className="mt-1.5 text-3xl font-bold mb-5 text-left">Rapports</h1>
                <MenuBar menuItems={menuItems} />
            </div>
        </div>
  );
};

const mapStateToProps = (state) => ({
    allMachinesData: state.machineState.allMachinesData, 
    allMachinesDataFetched: state.machineState.allMachinesDataFetched,
  });
  
const mapDispatchProps = (dispatch) => {
    return {
      fetchGymData: (machineId, nameGym) => {dispatch(fetchGymData(machineId, nameGym))},
      fetchAllMachinesData: () => { dispatch(fetchAllMachinesData()) }, 
    };
};
  
  
export default connect(mapStateToProps, mapDispatchProps)(Settings);
