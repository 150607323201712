import firebase from 'firebase/compat/app';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { setInfoUser, setUserId } from '../../store/actions/user';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from "../../assets/images/Fiilin_logo.png";
import { useNavigate } from 'react-router-dom';
import { fetchAllMachinesData } from '../../store/actions/machine';
import { displayPartsToString } from 'typescript';


const SignInPage = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSignIn = () => {
    if (email.length === 0 || password.length === 0) {
      toast.error('Veuillez remplir tous les champs');
      return;
    } 
    firebase.firestore().collection("intranetDashboardUsers")
      .where("email", '==', email)
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length > 0) {
          firebase.auth().signInWithEmailAndPassword(email, password)
          .then((userCredential) => {
            const user = userCredential.user;
            props.setUserId(user.uid);
            props.setInfoUser(snapshot.docs[0].data());
            props.fetchAllMachinesData(props.gymMachineIds, props.privilege)
          })
          .then(() => {
            navigate("/");
          })
            .catch((err) => {
              setPassword('');
              if (err.code === "auth/invalid-email") {
                toast.error("Adresse email incorrecte");
              } else if (err.code === "auth/user-not-found") {
                toast.error("Aucun utilisateur trouvé");
              } else {
                toast.error("Veuillez réessayer");
              }
            });
        } else {
          toast.error("Aucun utilisateur trouvé");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8">
        {/* Logo */}
        <div className="flex justify-center mb-8">
          <img src={Logo} alt="Logo" className="w-28 h-auto" />
        </div>

        {/* Form */}
        <div>
          {/* Email Input */}
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 font-semibold mb-2 text-left">
              Email
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md bg-white focus:ring focus:ring-blue-300 focus:outline-none"
              placeholder="Votre email"
            />
          </div>

          {/* Password Input */}
          <div className="mb-4">
            <label htmlFor="password" className="block text-gray-700 font-semibold mb-2 text-left">
              Mot de passe
            </label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md bg-white focus:ring focus:ring-blue-300 focus:outline-none"
              placeholder="Votre mot de passe"
            />
          </div>

          {/* Sign-In Button */}
          <button
            onClick={onSignIn}
            className="w-full button-color-input font-semibold text-white py-2 px-4 rounded-md transition mt-2"
          >
            Se connecter
          </button>

          {/* Forgot Password */}
          <div
            className="text-black text-sm mt-4 text-center cursor-pointer hover:underline"
            onClick={() => navigate('ResetPassword')}
          >
            Mot de passe oublié ?
          </div>
        </div>
        <ToastContainer autoClose={3000} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: state.userState.userId,
  allMachinesData: state.machineState.allMachinesData,
  allMachinesDataFetched: state.machineState.allMachinesDataFetched,
  gymsData: state.gymState.gymsData,
  gymMachineIds: state.userState.gymMachineIds,
  privilege: state.userState.privilege,
  tasks: state.taskState.tasks, 
});

const mapDispatchProps = (dispatch) => ({
  setUserId: (userId) => { dispatch(setUserId(userId)); },
  fetchAllMachinesData: (gymMachineIds, privilege) => {dispatch(fetchAllMachinesData(gymMachineIds, privilege))},
  setInfoUser: (userId) => {dispatch(setInfoUser(userId))}
});

export default connect(mapStateToProps, mapDispatchProps)(SignInPage);
