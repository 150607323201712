import { TrendingUpDown } from "lucide-react"

const initState = {
    machineProducts: null,
    machineProductsFetched: false, 
    allMachinesData: null, 
    allMachinesDataFetched: false,
    allConfigInfo: null,
    allConfigInfoFetched: false,
    allMachinesDataUpdated: false
}

const machineReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_ALL_CONFIGS':
            return {
                ...state,
                allConfigInfo: action.configInfo,
                allConfigInfoFetched: true
            }
        case 'FETCH_MACHINE_INFO':
            return {
                ...state, 
                machineProducts: action.machineProducts,
                machineProductsFetched: true
            }
        case 'ERROR_FETCHING_MACHINE_INFO':
            console.log("error fetching machine data: " + action.error)
            return state;
        case 'FETCH_ALL_MACHINES_INFO':
            return {
                ...state, 
                allMachinesData: action.allMachinesData,
                allMachinesDataFetched: true
            }
        case 'ERROR_ALL_FETCHING_MACHINES_INFO':
            console.log("error fetching machine data: " + action.error)
            return state;
        case 'CHANGE_IS_MACHINE_ACTIVE': 
            return {
                ...state, 
                allMachinesDataFetched: false, 
            }
        case 'SET_SOLD_OUT':
            return {
                ...state,
                allMachinesData: action.payload.data,
                allMachinesDataUpdated: true
            };
        case 'RESET_MACHINE_DATA_UPDATED':
            return {
                ...state,
                allMachinesDataUpdated: false
            }
        case 'CLEAR_MACHINES_DATA':
            return initState;
        default:
            return state
    }
}

export default machineReducer