import { combineReducers } from "redux";
import machineReducer from "./machineReducer";
import userReducer from "./userReducer";
import gymReducer from "./gymReducer";
import ownerReducer from "./ownerReducer";
import tasksReducer from "./tasksReducer";
import drinksReducer from "./drinksReducer";


const rootReducer = combineReducers({
    userState: userReducer,
    machineState: machineReducer, 
    gymState: gymReducer, 
    taskState: tasksReducer, 
    ownerState: ownerReducer,
    drinksState: drinksReducer
})

export default rootReducer;