import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DataBlock from "../../utils/dashboard/dataBlock";
import HistogramBar from "src/utils/dashboard/histogramBar";
import DonutChart from "src/utils/dashboard/donutChart";
import { RotatingLines } from "react-loader-spinner";
import { PlaceholderBlock, PlaceholderGraph } from "src/utils/placeholder";
import DatePicker from "react-datepicker";
import { fr } from "date-fns/locale"; // Import French locale
import "react-datepicker/dist/react-datepicker.css";
import { subMonths, startOfYear, endOfYear, differenceInMonths, min} from "date-fns";
import "../../styles/dashboard/customDatePicker.css"; // Import custom CSS file for DatePicker customization
import {
  fetchAllGymsData,
  fetchGymData,
  resetMachineIdGym,
  resetMGymName,
  setGymDataReady,
  setGymName,
  setMachineIdGym,
} from "src/store/actions/gyms";
import { fetchAllMachinesData } from "src/store/actions/machine";
import { setInfoUser } from "src/store/actions/user";
import StackedAreaChart from "src/utils/dashboard/stackedAreaChart";
import AreaGraph from "src/utils/dashboard/areaGraph";
import LastCustomersBox from "src/utils/dashboard/lastCustomerBox";
import ProductTable from "src/utils/dashboard/productTable";
import transformDataGraph from "src/utils/dashboard/transformDataGraph";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { StackedBarChart } from "src/utils/dashboard/stackedBarChart";
import MenuBar from "src/utils/dashboard/menuBar";
import CustomSelect from "../../utils/dashboard/customSelect";
import Leaderboard from "src/utils/dashboard/leaderboard";
import { getPreviousMonth } from "src/utils/dashboard/getPreviousMonth";


const Dashboard = (props) => {
  //miscellaneous constants
  const isPhone = window.innerWidth <= 768;
  const [isLoadingGymData, setIsLoadingGymData] = useState(true);

  //constants for calendar range selection
  const currentDate = new Date();
  const currentStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  const [dateRange, setDateRange] = useState([
    currentStartDate, // First day of the current month
    null, // End date will be null for single-month selection
  ]);
  const [startDate, endDate] = dateRange;
  const [selectedMonthId, setSelectedMonthId] = useState(`${currentStartDate.getFullYear()}-${String(currentStartDate.getMonth() + 1).padStart(2, '0')}`)
  const location = useLocation();
  const currentMonthId = `${currentStartDate.getFullYear()}-${String(currentStartDate.getMonth() + 1).padStart(2, '0')}`
  const [selectedDateOption, setSelectedDateOption] = useState(null)
  const [selectedMonths, setSelectedMonths] = useState();
  const [dateChanged, setDateChanged] = useState(false);

  //calendar available range constants
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");

  //calendar quick button constants
  const [lastMonthValid, setLastMonthValid] = useState(false);
  const [last3MonthValid, setLast3MonthValid] = useState(false);
  const [lastYearValid, setLastYearValid] = useState(false);

  //constants for dashboards main numbers
  const [totalRevenue, setTotalRevenue] = useState(""); //Revenu total
  const [totalRevenueUnit, setTotalRevenueUnit] = useState(""); //Nombre boissons
  const [totalDrinks, setTotalDrinks] = useState(""); //Revenus unite
  const [totalRevenueMembership, setTotalRevenueMembership] = useState("") //Revenu abonnement

  //constants for dashboards second line of numbers
  const [appMemberships, setAppMemberships] = useState(0); //Abonnements aux appli
  const [averageRating, setAverageRating] = useState("");  //Etoiles
  const [averageDrinksPerUser, setAverageDrinksPerUser] = useState(""); //Moyenne boissons par client
  const [uniqueUsers, setUniqueUsers] = useState(""); //Nombres de clients actifs

  //constants for progress arrows for single month
  const [lastMonthProgress, setLastMonthProgress] = useState({
    changeInRevenue: 0,
    changeInSubscriptionRevenue: 0,
    changeInNumberDrinks: 0,
    changeInNumberClientsActive: 0
  })
  const [showStatus, setShowStatus] = useState(false);


  {/*useEffect Hooks that control how data is loaded according to gym selection */}

  // we fetch the data of all gyms but not the financial of the membership data, just the primary data of gyms
  // if the data is already fetched, then, we set a machine id as a default one along with the name, which is the 
  // the first gym by alphabetic order
  useEffect(() => {
    if (!props.allMachinesDataFetched) {
      props.fetchAllMachinesData(props.gymMachineIds, props.privilege);
    } else {
      props.setMachineIdGym(props.allMachinesData[0].id);
      props.setGymName(props.allMachinesData[0].name)
    }
  }, [props.allMachinesDataFetched]);


  useEffect(() => {
    // Check if the current path matches the finance dashboard one
    if (location.pathname.startsWith("/finance")) {
      // Here we check if a machine id was already saved before and thus we display the corresponding data and not 
      // necessary the first gym of the list 
      if (props.allMachinesData) {
        if (!props.machineIdGym && props.allMachinesData.length > 0) {
          props.setMachineIdGym(props.allMachinesData[0].id);
          props.setGymName(props.allMachinesData[0].name)
        } else if (props.allMachinesData.length > 0) {
          const selectedGymName = props.allMachinesData.filter(
            (gym) => gym.id === props.machineIdGym
          );
          if (selectedGymName.length > 0) {
            props.setGymName(selectedGymName[0].name)
          }
        }
      } else {
        props.fetchAllMachinesData();
      }
    }
  }, [location.pathname, props.machineIdGym, props.allMachinesData]);


  useEffect(() => {
    // if a machine id has been set but the gym data was not fetched yet, then we fetch it
    if (props.setMachineIdGymSuccess && !Object.keys(props.gymsData).includes(props.machineIdGym) && props.privilege) {
      props.fetchGymData(props.machineIdGym, props.gymName);
      if (!props.gymDataAllFetched) {
        props.fetchAllGymsData();
      }
    }

  }, [props.setMachineIdGymSuccess, props.machineIdGym]);


  {/*useEffect Hooks that control how data is displayed according to calendar selection */}

  useEffect(() => {
    // once gymdata is loaded, get the first and last month of data and set the available calendar range
    if (props.machineIdGym && props.gymsData[props.machineIdGym]){
      const firstDate = Object.keys(props.gymsData[props.machineIdGym]["financials"])[0];
      const lastMonthIndex = Object.keys(props.gymsData[props.machineIdGym]["financials"]).length-1;
      const lastDate = Object.keys(props.gymsData[props.machineIdGym]["financials"])[lastMonthIndex];
      setMinDate(firstDate);
      setMaxDate(lastDate);

      //decide whether or not to show last month, last 3 months or last year buttons
      const isValidRange = firstDate && lastDate;
      // Calculate the difference in months between the start and end date
      const rangeInMonths = isValidRange ? differenceInMonths(lastDate, firstDate) : 0;
      setLastMonthValid(rangeInMonths >= 1); // For "Last Month"
      setLast3MonthValid(rangeInMonths >= 3); // For "Last Month"
      setLastYearValid(rangeInMonths >= 12); // For "Last Month"
    } else {
      props.fetchGymData(props.machineIdGym, props.gymName);
      if (!props.gymDataAllFetched) {
        props.fetchAllGymsData();
      }
    }
  }, [props.setMachineIdGymSuccess, props.gymDataFetched, props.machineIdGym, props.selectedGymName]);


  useEffect(() => { //hook to calculate correct number of selected months and update datechanged flag
    const diffInMonths = dateRange[1] ? differenceInMonths(dateRange[1], dateRange[0]) + 1 : 1;
    setSelectedMonths(diffInMonths); //update selectedMonths after dateRange is updated
    setDateChanged(true)
  }, [dateRange]);  // runs only when dateRange changes

  
  //if more than one month selected, aggregate data for selected time period
  useEffect (() => {
    //we calculate how many months are there in total in the selected date range
    if (props.machineIdGym && props.gymsData.hasOwnProperty(props.machineIdGym) ){
      if (selectedMonths > 1 && dateChanged) {//check whether number of selectedmonths is more than 1 and has been updated
        //if more than 1 we sum up every month in range to get total revenue
        let revenue = 0;
        let drinks = 0;
        let membershipRevenue = 0;
        let unitRevenue = 0;

        let subscriptions = 0;
        let totalStars = 0;
        let totalDrinks = 0;
        let totalUniqueUsers = 0;
        //split months dictionary in array of the keys
        const monthsId = Object.keys(props.gymsData[props.machineIdGym]["financials"])
        //get the start month in date form
        const startMonth = `${dateRange[0].getFullYear()}-${String(dateRange[0].getMonth() + 1).padStart(2, '0')}`
        //find the index of the selected start month in the array of keys
        const startMonthIndex = monthsId.indexOf(startMonth)
        //loop from start month all the way to last month in arra
        for (let i=startMonthIndex;i<startMonthIndex + selectedMonths;i+=1) {
          revenue += props.gymsData[props.machineIdGym]["financials"][monthsId[i]]["finance"].revenue + props.gymsData[props.machineIdGym]["financials"][monthsId[i]]["memberships"].moneyMemberships;
          // we also need to add the revenue of the app memberships 
          revenue += props.gymsData[props.machineIdGym]["financials"][monthsId[i]]["memberships"].moneyAppMemberships ? props.gymsData[props.machineIdGym]["financials"][monthsId[i]]["memberships"].moneyAppMemberships : 0

          drinks += props.gymsData[props.machineIdGym]["financials"][monthsId[i]]["finance"].totalDrinks
          membershipRevenue += props.gymsData[props.machineIdGym]["financials"][monthsId[i]]["memberships"].moneyMemberships
          unitRevenue += props.gymsData[props.machineIdGym]["financials"][monthsId[i]]["finance"].revenue

          subscriptions += props.gymsData[props.machineIdGym]["financials"][monthsId[i]]["memberships"].moneyAppMemberships ? props.gymsData[props.machineIdGym]["financials"][monthsId[i]]["memberships"].moneyAppMemberships : 0
          totalStars += props.gymsData[props.machineIdGym]["financials"][monthsId[i]]["finance"].averageRating
          totalDrinks += props.gymsData[props.machineIdGym]["financials"][monthsId[i]]["finance"].averageDrinksPerUser
          totalUniqueUsers += props.gymsData[props.machineIdGym]["financials"][monthsId[i]]["finance"].uniqueUsers
        }
        setTotalRevenue(revenue.toFixed(2));
        setTotalDrinks(drinks);
        setTotalRevenueMembership(membershipRevenue.toFixed(2));
        setTotalRevenueUnit(unitRevenue.toFixed(2));

        setAppMemberships(subscriptions);
        setAverageRating((totalStars / selectedMonths).toFixed(2));
        setAverageDrinksPerUser(Math.round(drinks / totalUniqueUsers));
        setUniqueUsers(Math.round(totalUniqueUsers / selectedMonths));

        setShowStatus(false);
      } else {
        //if only 1 month simply display that months values
        let revenue = props.gymsData[props.machineIdGym]["financials"][selectedMonthId]["finance"].revenue + props.gymsData[props.machineIdGym]["financials"][selectedMonthId]["memberships"].moneyMemberships

        // we add the revenue of the app memberships
        revenue += props.gymsData[props.machineIdGym]["financials"][selectedMonthId]["memberships"].moneyAppMemberships ? props.gymsData[props.machineIdGym]["financials"][selectedMonthId]["memberships"].moneyAppMemberships : 0
        setTotalRevenue(revenue)
        setTotalDrinks(props.gymsData[props.machineIdGym]["financials"][selectedMonthId]["finance"].totalDrinks);
        setTotalRevenueMembership(props.gymsData[props.machineIdGym]["financials"][selectedMonthId]["memberships"].moneyMemberships.toFixed(2));
        setTotalRevenueUnit(props.gymsData[props.machineIdGym]["financials"][selectedMonthId]["finance"].revenue.toFixed(2));

        setAppMemberships(props.gymsData[props.machineIdGym]["financials"][selectedMonthId]["memberships"].moneyAppMemberships ? props.gymsData[props.machineIdGym]["financials"][selectedMonthId]["memberships"].moneyAppMemberships : 0)
        setAverageRating(props.gymsData[props.machineIdGym]["financials"][selectedMonthId]["finance"].averageRating.toFixed(2));
        setAverageDrinksPerUser(Math.round(props.gymsData[props.machineIdGym]["financials"][selectedMonthId]["finance"].averageDrinksPerUser));
        setUniqueUsers(props.gymsData[props.machineIdGym]["financials"][selectedMonthId]["finance"].uniqueUsers);

        // we calculate the relative values with the previous month if not a range has been selected and if there is actually a previous month

        const prevMonthFormatted = getPreviousMonth(selectedMonthId)
        const lastDate = Object.keys(props.gymsData[props.machineIdGym]["financials"])[0];
        if (!(prevMonthFormatted < lastDate)) {
          let lastRevenue = props.gymsData[props.machineIdGym]["financials"][prevMonthFormatted]["finance"].revenue + props.gymsData[props.machineIdGym]["financials"][prevMonthFormatted]["memberships"].moneyMemberships
          lastRevenue += props.gymsData[props.machineIdGym]["financials"][prevMonthFormatted]["memberships"].moneyAppMemberships ? props.gymsData[props.machineIdGym]["financials"][prevMonthFormatted]["memberships"].moneyAppMemberships : 0
          
          setLastMonthProgress( prevData => ({
            ...prevData,
            changeInRevenue: ( ( (revenue - lastRevenue) / lastRevenue ) * 100 ).toFixed(2),
            changeInSubscriptionRevenue: (((props.gymsData[props.machineIdGym]["financials"][selectedMonthId]["memberships"].moneyMemberships - props.gymsData[props.machineIdGym]["financials"][prevMonthFormatted]["memberships"].moneyMemberships) / props.gymsData[props.machineIdGym]["financials"][prevMonthFormatted]["memberships"].moneyMemberships) * 100).toFixed(2),
            changeInNumberClientsActive: (((props.gymsData[props.machineIdGym]["financials"][selectedMonthId]["finance"].uniqueUsers - props.gymsData[props.machineIdGym]["financials"][prevMonthFormatted]["finance"].uniqueUsers) / props.gymsData[props.machineIdGym]["financials"][prevMonthFormatted]["finance"].uniqueUsers) * 100).toFixed(2),
            changeInNumberDrinks: (((props.gymsData[props.machineIdGym]["financials"][selectedMonthId]["finance"].totalDrinks - props.gymsData[props.machineIdGym]["financials"][prevMonthFormatted]["finance"].totalDrinks) / props.gymsData[props.machineIdGym]["financials"][prevMonthFormatted]["finance"].totalDrinks) * 100).toFixed(2)
          }))
          setShowStatus(true)
        } else {
          setShowStatus(false)
        }

      }
    } 
  }, [props.machineIdGym, selectedMonthId, minDate, dateChanged]);


  {/*Function to handle the changing of selected gym*/}
  // this function is to handle the case where we click on a specific gym
  const handleSelectGym = (machineId, nameGym) => {
    // we show a spinner is the data has not been fetched yet
    setIsLoadingGymData(true);
    handleQuickSelect("clear")
    setDateRange([currentStartDate, null])

    // we reset the value to the current month
    setSelectedMonthId(`${currentStartDate.getFullYear()}-${String(currentStartDate.getMonth() + 1).padStart(2, '0')}`)
    setSelectedDateOption(null)


    // to display the data of the gym we need to finance information and the memberships information
    if (Object.keys(props.gymsData).includes(machineId)) {
      props.setGymDataReady();
    }
    else {
      // we fetch the info
      props.fetchGymData(machineId, nameGym);
      if (!props.gymDataAllFetched) {
        props.fetchAllGymsData();
      }
    }

    // we then update the machine id and the gymName because if we do it earlier then the useEffect line 109
    // is going to trigger and we want it only when the check for the data fetching has been done 
    props.setMachineIdGym(machineId);
    props.setGymName(nameGym)
  };

  
  const handleChangeDateCalendar = (update) => { 
    // First, reset the dateChanged flag
    setDateChanged(false);
    
    //clear all selections from calendar
    handleQuickSelect("clear");
  
    // Then update dateRange
    setDateRange(update);
  
    // Set selectedMonthId based on the updated dateRange
    const selectedMonth = `${update[0].getFullYear()}-${String(update[0].getMonth() + 1).padStart(2, '0')}`;
    setSelectedMonthId(selectedMonth);

  };

  {/*Secondary functions that performs utility actions like selecting months based on a button or transforming data to be send to components */}

  // this function is to highlight the month selected on the calendar date picker
  const highlightMonth = (date) => {
    if (!startDate || !endDate) return "";

    const isStartOrEnd =
      (startDate?.getFullYear() === date.getFullYear() && startDate?.getMonth() === date.getMonth()) ||
      (endDate?.getFullYear() === date.getFullYear() && endDate?.getMonth() === date.getMonth());

    const isBetween =
      startDate &&
      endDate &&
      date > startDate &&
      date < endDate;

    if (isStartOrEnd) return "selected-month";
    if (isBetween) return "between-month";

    return "";
  };


  const handleQuickSelect = (option) => {
    const today = new Date();
    setDateChanged(false)

    switch (option) {
      case "lastMonth":
        setDateRange([today, today]);
        setSelectedMonthId(`${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}`)
        setSelectedDateOption("lastMonth")
        setSelectedMonthId(`${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}`)
        break;
      case "last3Months":
        setDateRange([subMonths(today, 2), today]);
        setSelectedDateOption("last3Months")
        break;
      case "currentYear":
        setDateRange([startOfYear(today), endOfYear(today)]);
        setSelectedDateOption("currentYear")
        break;
      case "selectionYear":
        setDateRange([null, null]);
        setSelectedDateOption("selectionYear")
        break;
      case "allMonths":
        const minDateFormatted = new Date(...minDate.split("-").map((v, i) => i === 1 ? v - 1 : v), 1);
        const maxDateFormatted = new Date(...maxDate.split("-").map((v, i) => i === 1 ? v - 1 : v), 1);
        setDateRange([minDateFormatted, maxDateFormatted])
        setSelectedDateOption("selectionAll")
        break;
      case "selectionMonth":
        setDateRange([null, null]);
        setSelectedDateOption("selectionMonth")
        break;
      case "clear":
        setSelectedDateOption(null)
        setDateRange([null, null]);
        break;
      default:
        setSelectedDateOption(null)
        setDateRange([null, null]);
        break;
    }
  };



  {/*Various HTML components */}
  const customCalendarContainer = ({ children }) => (
    <div
      className="border border-transparent rounded-xl"
      style={{
        display: "flex",
        flexDirection: isPhone ? "column" : "row", // Stack vertically for phone
        alignItems: "stretch",
        marginRight: isPhone
          ? "0" // No margin on phone view
          : `calc(4vw + (8 / 100) * (92vw))`, // Dynamic margin for desktop
      }}
    >
      {/* Sidebar */}
      <div
        className={`p-4 bg-white border-r flex flex-col justify-between border border-transparent ${
          isPhone ? "mb-4" : "" // Add margin-bottom for phone view
        }`}
        style={{
          borderTopLeftRadius: isPhone ? "0" : "10px", // Remove border radius for phone
          borderBottomLeftRadius: isPhone ? "0" : "10px", // Remove border radius for phone
        }}
      >
        <div>
          {lastMonthValid && (
            <button
              className={`block w-full mb-2 py-4 px-2 border border-transparent rounded-xl ${
                selectedDateOption === "lastMonth"
                  ? "bg-customOrange-dark text-white"
                  : "bg-white text-black hover:bg-customOrange-light"
              }`}
              onClick={() => handleQuickSelect("lastMonth")}
            >
              Dernier mois
            </button>
          )}
          {last3MonthValid && (
            <button
              className={`block w-full mb-2 py-4 px-2 border border-transparent rounded-xl ${
                selectedDateOption === "last3Months"
                  ? "bg-customOrange-dark text-white"
                  : "bg-white text-black hover:bg-customOrange-light"
              }`}
              onClick={() => handleQuickSelect("last3Months")}
            >
              3 derniers mois
            </button>
          )}
          {lastYearValid && (
            <button
              className={`block w-full mb-2 py-4 px-2 border border-transparent rounded-xl ${
                selectedDateOption === "currentYear"
                  ? "bg-customOrange-dark text-white"
                  : "bg-white text-black hover:bg-customOrange-light"
              }`}
              onClick={() => handleQuickSelect("currentYear")}
            >
              Année en cours
            </button>
          )}
          {/* Select full date range option */}
          {minDate && maxDate &&(<button
            className={`block w-full mb-2 py-4 px-2 border border-transparent rounded-xl ${
              selectedDateOption === "currentYear"
                ? "bg-customOrange-dark text-white"
                : "bg-white text-black hover:bg-customOrange-light"
            }`}
            onClick={() => handleQuickSelect("allMonths")}
          >
            Total
          </button>)}
          <button
            className="block w-full mb-2 py-4 px-2 bg-white border border-transparent rounded-xl hover:bg-customOrange-light"
            onClick={() => handleQuickSelect()}
          >
            Réinitialiser
          </button>
        </div>
      </div>
  
      {/* Calendar */}
      <div
        className="bg-white"
        style={{
          flexGrow: 1,
          padding: "10px",
          borderTopRightRadius: isPhone ? "10px" : "0", // Adjust border radius for phone
          borderBottomRightRadius: isPhone ? "10px" : "0", // Adjust border radius for phone
        }}
      >
        {children}
      </div>
    </div>
  )
  
  {/*Menu Bar routing dictionary */}
  const menuItems = props.privilege == "fiilin" ? 
  [
    { name: "Dashboard", route: "/finance/dashboard" },
    { name: "Analysis", route: "/finance/analysis" },
    {name: "Overview", route: "/finance/overview"}
  ] : [    
    { name: "Dashboard", route: "/finance/dashboard" },
    { name: "Analysis", route: "/finance/analysis" },
  ]

  return (
    <div
      className={`min-h-screen flex flex-col w-full p-6 bg-customOrange-light ${
        isPhone ? "w-full p-1" : "" // Adjust padding and width for phone view
      }`}
      style={{
        marginTop: "20px",
        paddingLeft: isPhone ? "2%" : "4%", // Smaller padding on phone view
        paddingRight: isPhone ? "2%" : "4%", // Smaller padding on phone view
        height: "auto", // Make sure the div fills the phone screen height
        justifyContent: "flex-start", // Align content to the top for both mobile and desktop
        alignItems: "flex-start", // Align items to the top of the flex container
      }}
    >

    {/* Gym Selector and Date Range Picker */}
    {props.allMachinesDataFetched && (
      <div className="w-full flex-start">
        {/* MenuBar */}
        {!props.gymMachineIds || props.gymMachineIds[1]  ? (<div className= {isPhone ? "flex flex-start item-start" : "flex justify-start pt-8"}>
          <MenuBar menuItems={menuItems} />
        </div>) : <div style={{ height: 35, visibility: 'hidden' }}></div>}
        <div className={isPhone ? "w-full flex flex-col mb-1 ": "flex items-center justify-between mb-[10px]"}>
        {/* Gym Selector */}
        <div className="flex items-center space-x-4 w-full mb-1">
          <CustomSelect machines={props.allMachinesData} onSelect={handleSelectGym} />
        </div>

        {/* Date Picker */}
        <div className={isPhone ? "flex flex-row items-center justify-between bg-white border border-transparent rounded-xl px-4 w-full mb-1" : "flex flex-row ml-4 mb-1 items-center justify-between bg-white border border-transparent rounded-xl px-4"}>
          <FaRegCalendarAlt />
          <DatePicker
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => handleChangeDateCalendar(update)}
            dateFormat="MMM yyyy"
            showMonthYearPicker
            placeholderText={startDate}
            className="custom-datepicker-input"
            calendarClassName="custom-datepicker-calendar"
            dayClassName={(date) => highlightMonth(date)}
            locale={fr}
            calendarContainer={customCalendarContainer}
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>
      </div>

        {/* Content */}
        {props.machineIdGym && props.gymsData.hasOwnProperty(props.machineIdGym) ? (
          <div className="mt-3 flex flex-col flex-grow space-y-4 justify-start">
            {/* First set of data blocks */}
            <div className={`grid gap-4 w-full ${isPhone ? "grid-cols-1" : "grid-cols-4"}`}>
              <DataBlock title="Revenu total" value={totalRevenue} type="money" status={showStatus} statusData={lastMonthProgress.changeInRevenue} />
              <DataBlock title="Revenu abonnement" value={totalRevenueMembership} type="money" status={showStatus} statusData={lastMonthProgress.changeInSubscriptionRevenue}/>
              <DataBlock title="Revenus unité" value={totalRevenueUnit} type="money"/>
              <DataBlock title="Revenu app abonnement" value={appMemberships} type="money" />
            </div>

            {/* Second set of data blocks */}
            <div className={`grid gap-4 w-full ${isPhone ? "grid-cols-1" : "grid-cols-4"}`}>
              <DataBlock title="Nombre boissons" value={totalDrinks} type="drink" status={showStatus} statusData={lastMonthProgress.changeInNumberDrinks}/>
              <DataBlock 
                title={selectedMonths < 2 ? "Nombres de clients actifs" : "Moyenne nombres de clients actifs"}
                value={uniqueUsers}
                type="user"
                status = {showStatus}
                statusData={lastMonthProgress.changeInNumberClientsActive}
              />
              <DataBlock title="Moyenne boissons par client" value={averageDrinksPerUser} type="drink" />
              <DataBlock title="Note Utilisateur" value={averageRating} type="star" />
            </div>

            {/* Conditional Rendering for Graphs */}
            {selectedMonths === 1 ? (
              <div className={`flex ${isPhone ? "flex-col" : "grid grid-cols-5"} gap-4 w-full flex-grow`}>
                <div className={`col-span-${isPhone ? "1" : "3"} h-full overflow-x-auto`}>
                  <AreaGraph
                    data={transformDataGraph(props.gymsData[props.machineIdGym]["financials"], "areaGraph", dateChanged, selectedMonths, dateRange, selectedMonthId)}
                    title="Evolution du nombre de boissons"
                    dataKey="drinks"
                    nameTooltip="Nb boissons"
                    colorLine="#F08300"
                    colorArea="#FFD699"
                    isPhone={isPhone}
                  />
                </div>
                <div className={`col-span-${isPhone ? "1" : "2"} h-full`}>
                  <HistogramBar data={transformDataGraph(props.gymsData[props.machineIdGym]["financials"], "histogramBar", dateChanged, selectedMonths, dateRange, selectedMonthId)} title="Répartition des boissons" isPhone={isPhone}/>
                </div>
              </div>
            ) : selectedMonths > 1 && selectedMonths < 7 ? (
              <div className={`flex ${isPhone ? "flex-col" : "grid grid-cols-5"} gap-4 w-full flex-grow`}>
                <div className={`col-span-${isPhone ? "1" : "3"} h-full`}>
                  <AreaGraph
                    data={transformDataGraph(props.gymsData[props.machineIdGym]["financials"], "areaGraph", dateChanged, selectedMonths, dateRange, selectedMonthId)}
                    title="Evolution du nombre de boissons"
                    dataKey="drinks"
                    nameTooltip="Nb boissons"
                    colorLine="#F08300"
                    colorArea="#FFD699"
                    isPhone={isPhone}
                  />
                </div>
                <div className={`col-span-${isPhone ? "1" : "2"} h-full`}>
                  <StackedBarChart data={transformDataGraph(props.gymsData[props.machineIdGym]["financials"], "barGraph", dateChanged, selectedMonths, dateRange, selectedMonthId)} type="drinks" />
                </div>
              </div>
            ) : selectedMonths >= 7 ? (
              <div className={`flex ${isPhone ? "flex-col" : "w-full"} gap-4 w-full flex-grow`}>
                <div className={`w-full h-full mb-4 ${isPhone ? "mb-2" : ""}`}>
                  <AreaGraph
                    data={transformDataGraph(props.gymsData[props.machineIdGym]["financials"], "areaGraph", dateChanged, selectedMonths, dateRange, selectedMonthId)}
                    title="Evolution du nombre de boissons"
                    dataKey="drinks"
                    nameTooltip="Nb boissons"
                    colorLine="#F08300"
                    colorArea="#FFD699"
                    isPhone={isPhone}
                  />
                </div>
                <div className={`w-full h-full ${isPhone ? "mt-2" : ""}`}>
                  <StackedBarChart data={transformDataGraph(props.gymsData[props.machineIdGym]["financials"], "barGraph", dateChanged, selectedMonths, dateRange, selectedMonthId)} type="drinks" />
                </div>
              </div>
            ) : (
              <div>No data available</div>
            )}
            {/* Conditional rendering of graphs for phone or desktop */}
            {isPhone ? (
              <div className="flex flex-col gap-4 w-full">
                {selectedMonths === 1 ? (
                  <>
                    {/* DonutChart and AreaGraph stacked on top of each other for 1 month */}
                    {props.gymsData[props.machineIdGym]["financials"][selectedMonthId]["memberships"].totalMemberships > 0 && (
                      <DonutChart
                        data={transformDataGraph(props.gymsData[props.machineIdGym]["financials"], "donutGraph", dateChanged, selectedMonths, dateRange, selectedMonthId)}
                        title="Répartition des abonnements"
                        needTransform={true}
                      />
                    )}
                    <div className="h-[423px] overflow-y-auto">
                      <Leaderboard
                        data={props.gymsDataAll}
                        currentGym={props.gymName}
                      />
                    </div>
                  </>
                ) : (
                  // For more than 1 month, replace DonutChart with StackedBarChart in the same layout
                  <div className="h-full">
                    <StackedBarChart
                      data={transformDataGraph(props.gymsData[props.machineIdGym]["financials"], "barGraphSubscriptions", dateChanged, selectedMonths, dateRange, selectedMonthId)}
                      title="Répartition des abonnements"
                      type="subscriptions"
                    />
                  </div>
                )}
                {/* For both cases (1 or more months), always show the AreaGraph below */}
              </div>
            ) : (
              <div>

                {selectedMonths == 1 && (
                  <div className="grid grid-cols-5 gap-4 w-full flex-grow">
                    <div className="col-span-2 h-full">
                    <DonutChart
                        data={transformDataGraph(props.gymsData[props.machineIdGym]["financials"], "donutGraph", dateChanged, selectedMonths, dateRange, selectedMonthId)}
                        title="Répartition des abonnements"
                        needTransform={true}
                      />
                    </div>
                    <div className="col-span-3 h-[423px] overflow-y-auto">
                    <Leaderboard
                      data={props.gymsDataAll}
                      currentGym={props.gymName}
                    />
                    </div>
                  </div>
                )}
                {/* Case y: StackedBarChart and AreaGraph in the same row for desktop (more than 1 month) */}
                {selectedMonths < 7 && selectedMonths > 1 && (
                  <div className="grid grid-cols-5 gap-4 w-full flex-grow">
                    <div className="col-span-2 h-[423px] overflow-y-auto">
                      <Leaderboard
                        data={props.gymsDataAll}
                        currentGym={props.gymName}
                      />
                    </div>
                    <div className="col-span-3 h-[423px] overflow-y-auto">
                      <StackedBarChart
                        data={transformDataGraph(props.gymsData[props.machineIdGym]["financials"], "barGraphSubscriptions", dateChanged, selectedMonths, dateRange, selectedMonthId)}
                        title="Répartition des abonnements"
                        type="subscriptions"
                      />
                    </div>
                  </div>
                )}

                {/* Case z: StackedBarChart in one row, AreaGraph in a separate row for desktop (7+ months) */}
                {selectedMonths >= 7 && (
                  <div className="flex flex-col w-full flex-grow">
                    <div className="w-full h-full mb-4">
                      <StackedBarChart
                        data={transformDataGraph(props.gymsData[props.machineIdGym]["financials"], "barGraphSubscriptions", dateChanged, selectedMonths, dateRange, selectedMonthId)}
                        title="Répartition des abonnements"
                        type="subscriptions"
                      />
                    </div>
                    <div className="w-full h-full">
                    <div className="col-span-3 h-[423px] overflow-y-auto">
                      <Leaderboard
                        data={props.gymsDataAll}
                        currentGym={props.gymName}
                      />
                    </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* Stacked Graphs and Best Customers */}
            <div className={`w-full flex-grow min-h-full ${isPhone ? "flex flex-col space-y-4" : "grid grid-cols-5 gap-4"}`}>
              {/* Stacked Area Chart */}
              <div className={`col-span-3 ${isPhone ? "w-full" : ""} flex flex-col h-full`}>
                <StackedAreaChart
                  data={transformDataGraph(props.gymsData[props.machineIdGym]["financials"], "stackedGraph", dateChanged, selectedMonths, dateRange, selectedMonthId)}
                  isPhone={isPhone}
                />
              </div>

              {/* Best Customers Box */}
              <div className={`col-span-2 ${isPhone ? "w-full" : "max-h-[438px]"}`}>
                <LastCustomersBox
                  customers={props.gymsData[props.machineIdGym]["financials"][selectedMonthId]["finance"].bestCustomers}
                  month={selectedMonthId}
                />
              </div>
            </div>

            {/* Product Table */}
            <div className={`col-span-3 ${isPhone ? "w-full" : ""} flex flex-col h-full`}>
              <ProductTable
                products={props.gymsData[props.machineIdGym]["financials"][selectedMonthId]["finance"].productData}
              />
            </div>

          </div>
        ) : (
          <div className="flex flex-col flex-grow space-y-6">
            <RotatingLines strokeColor="#f08300" height={30} width={30} visible={true} animationDuration="1.25" />
            <div className="grid grid-cols-4 gap-4 w-full">
              <PlaceholderBlock title="Chiffre d'affaire" />
              <PlaceholderBlock title="Nombre boissons" />
              <PlaceholderBlock title="Revenu abonnement" />
              <PlaceholderBlock title="Utilisateurs" />
            </div>
            <div className="grid grid-cols-2 gap-3 w-full flex-grow">
              <PlaceholderGraph />
              <PlaceholderGraph />
            </div>
          </div>
        )}
      </div>
    )}
    </div>


  );
  
  

  };
  
  

  
  const mapStateToProps = (state) => ({
    userId: state.userState.userId,
    gymsData: state.gymState.gymsData,
    gymsDataAll: state.gymState.gymsDataAll,
    gymDataFetched: state.gymState.gymDataFetched,
    gymDataAllFetched: state.gymState.gymDataFetched,
    allMachinesData: state.machineState.allMachinesData,
    allMachinesDataFetched: state.machineState.allMachinesDataFetched,
    setMachineIdGymSuccess: state.gymState.setMachineIdGymSuccess,
    machineIdGym: state.gymState.machineIdGym,
    gymName: state.gymState.gymName, 
    fetchedGymsFinanceNumber: state.gymState.fetchedGymsFinanceNumber, 
    fetchedGymsMembershipNumber: state.gymState.fetchedGymsMembershipNumber,
    gymMachineIds: state.userState.gymMachineIds,
    privilege: state.userState.privilege
  });
  
  const mapDispatchProps = (dispatch) => ({
    fetchGymData: (machineId, nameGym) => dispatch(fetchGymData(machineId, nameGym)),
    fetchAllGymsData: () => dispatch(fetchAllGymsData()),
    fetchAllMachinesData: (machineIds, privilege) => dispatch(fetchAllMachinesData(machineIds, privilege)),
    setMachineIdGym: (machineId) => dispatch(setMachineIdGym(machineId)),
    setGymDataReady: () => dispatch(setGymDataReady()),
    resetMachineIdGym: () => dispatch(resetMachineIdGym()),
    setGymName: (gymName) => dispatch(setGymName(gymName)), 
    resetMGymName: () => dispatch(resetMGymName()),
    setInfoUser: (userId) => dispatch(setInfoUser(userId))
  });

  export default connect(mapStateToProps, mapDispatchProps)(Dashboard)