import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { fetchGymInfo, fetchProductInfo } from './utils';
import gymReducer from '../reducers/gymReducer';
import { all } from 'axios';


export function fetchAllMachinesData(gymMachineIds, privilege) {
    return async (dispatch) => {
        const currentDate = new Date();
        const pastWeekFromNowTimeStamp = currentDate.getTime() - (7 * 24 * 3600 * 1000); // one week in seconds
        //if array empty then get all machine data
        if (privilege === "fiilin") {
            firebase.firestore()
            .collection("machines")
            .where("showInApp", "==", true)
            .get()
            .then(async (machineQuerySnapshot) => {
                let promises = machineQuerySnapshot.docs.map(async (machineDoc) => {
                    const machineData = machineDoc.data();
                    const machineInfo = {
                        id: machineDoc.id,
                        name: machineData.name,
                        hasSentEmail: machineData.hasSentEmail,
                        isActive: machineData.isActive,
                        type: machineData.type,
                        size: machineData.size,
                        machineConfig: machineData.configurations,
                        items : {}
                    };

                    //Fetch items
                    const itemsSnapshot = await firebase.firestore()
                        .collection("machines")
                        .doc(machineDoc.id)
                        .collection("items")
                        .get();

                    itemsSnapshot.forEach((itemDoc) => {
                        machineInfo.items[itemDoc.id] = itemDoc.data();
                    });

                    // Fetch complaints
                    const complaintsSnapshot = await firebase.firestore()
                        .collection("userFeedbacks")
                        .where("machineId", "==", machineInfo.id)
                        .where("timeStamp", ">=", pastWeekFromNowTimeStamp)
                        .where("rating", "<=", 3)
                        .orderBy("timeStamp")
                        .get();

                    const complaintsList = complaintsSnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                        nameMachine: machineData.name,
                    }));

                    const complaints = { complaints: complaintsList }
                    // Fetch gym and owner information
                    const userData = await fetchGymInfo(machineInfo, complaints, machineData.gymId, machineData.ownerId);
                    return userData;
                });

                // Wait for all machine details to be fetched
                Promise.all(promises)
                    .then((updatedAllMachinesDataArray) => {
                        const sortedMachines = [...updatedAllMachinesDataArray].sort((a, b) =>
                            a.nameGym.localeCompare(b.nameGym)
                        );
                        dispatch({
                            type: "FETCH_ALL_MACHINES_INFO",
                            allMachinesData: sortedMachines,
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: "ERROR_FETCHING_ALL_MACHINES_INFO", error: error });
                    });
            })
            .catch((error) => {
                dispatch({ type: "ERROR_FETCHING_ALL_MACHINES_INFO", error: error });
            });
        } else if (gymMachineIds && gymMachineIds.length !== 0){          
            // Create a list of promises to fetch data for each machine by its ID
            const promises = gymMachineIds.map( async (machineId) => {
              // Get the machine document by its ID
              const machineDoc = await firebase.firestore().collection("machines").doc(machineId).get();
              
              if (!machineDoc.exists) {
                console.log(`No document found for machine ID: ${machineId}`);
                return null;  // Skip if the document does not exist
              }
          
              const machineData = machineDoc.data();
              const machineInfo = {
                id: machineDoc.id,
                name: machineData.name,
                hasSentEmail: machineData.hasSentEmail,
                isActive: machineData.isActive,
                type: machineData.type,
                size: machineData.size,
                machineConfig: machineData.configurations,
                items: {}
              };

            
                const itemsSnapshot = await firebase.firestore()
                .collection("machines")
                .doc(machineId)
                .collection("items")
                .get();

                itemsSnapshot.forEach((itemDoc) => {
                    machineInfo.items[itemDoc.id] = itemDoc.data();
                });

              // Fetch complaints for the machine
              const complaintsSnapshot = await firebase.firestore()
                .collection("userFeedbacks")
                .where("machineId", "==", machineInfo.id)
                .where("timeStamp", ">=", pastWeekFromNowTimeStamp)
                .where("rating", "<=", 3)
                .orderBy("timeStamp")
                .get();
          
              const complaintsList = complaintsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                nameMachine: machineData.name,  // Add machine name to each complaint
              }));
          
              const complaints = { complaints: complaintsList };
          
              // Fetch gym and owner information
              const userData = await fetchGymInfo(machineInfo, complaints, machineData.gymId, machineData.ownerId);
          
              // Return the structured data for this machine
              return userData
            });
          
            // Wait for all promises to resolve
            try {
              const allMachineData = await Promise.all(promises); // Wait for all data fetching to complete
              const sortedMachines = [...allMachineData].sort((a, b) =>
                a.nameGym.localeCompare(b.nameGym)
                );
                dispatch({
                    type: "FETCH_ALL_MACHINES_INFO",
                    allMachinesData: sortedMachines,
                });
            } catch (error) {
              console.error('Error fetching machine data:', error);
            }
          }
          

        
    };
}

export function fetchAllMachineConfigs() {
    return async (dispatch) => { // this function fetches the configs collection from the database, we will need to map the current machines config id to the names of teh documents in this collection later on
        try {
            const configSnapshot = await firebase.firestore()
                .collection("machineConfigurations")
                .get();

            if (configSnapshot.empty) {
                console.log("No machine configurations found.");
                return Promise.resolve([]); // Resolve with empty array if no documents exist
            }

            // Extract data from each document
            const configList = configSnapshot.docs.map(doc => ({
                id: doc.id, // Include document ID
                ...doc.data() // Spread the rest of the fields
            }));

            // Dispatch the action with type "ADD_ALL_CONFIGS"
            return dispatch({
                type: "FETCH_ALL_CONFIGS",
                configInfo: configList
            });
        } catch (error) {
            console.error("Error fetching machine configs:", error);
            return Promise.reject(error); // Reject with error if fetching fails
        }
    };
}



export function fetchOwnerMachinesInfo(ownerId) {
    // fetch the info of all machines that belongs to a user
    return (dispatch) => {
        const productRef = firebase.firestore().collection("machineProducts");

        // Use a Firestore query to filter machines by "ownerId"
        firebase.firestore()
            .collection("machines")
            .where("ownerId", "==", ownerId) // Filter machines by "ownerId"
            .get()
            .then((machineQuerySnapshot) => {
                const promises = [];

                machineQuerySnapshot.forEach((machineDoc) => {
                    const machineData = machineDoc.data()
                    const machineId = machineDoc.id;

                    // Now, you have the machineId, and you can call your existing method to fetch machine details.
                    const machineDetailsPromise = fetchMachineMenu(machineId, productRef, machineData);

                    promises.push(machineDetailsPromise);
                });

                // We need to wait until all machine details have been fetched.
                Promise.all(promises)
                .then((updatedMachineArray) => {
                    dispatch({ type: 'FETCH_MACHINE_INFO', machineProducts: updatedMachineArray });
                })
                .catch((error) => {
                    dispatch({ type: 'ERROR_FETCHING_MACHINE_INFO', error: error });
                });
            })
            .catch((error) => {
                dispatch({ type: 'ERROR_FETCHING_MACHINE_INFO', error: error });
            });
    };
}   

// Separate method to fetch machine details using machineId
function fetchMachineMenu(machineId, productRef, machineData) {
    return new Promise((resolve, reject) => {
        firebase.firestore()
            .collection("machines")
            .doc(machineId)
            .collection("items")
            .orderBy("order")
            .get()
            .then((snapshot) => {
                const promises = [];

                snapshot.forEach((doc) => {
                    const data = doc.data();

                    let itemInfo = {id: data.flavorId, order: data.order, familyId: data["familyId"]}

                    // We need to retrieve the information about each product
                    const familyProductRef = productRef.doc(data["familyId"]);
                    const flavorDocId = itemInfo["id"];
                    const productsPromises = fetchProductInfo(itemInfo, familyProductRef, flavorDocId);

                    promises.push(productsPromises);
                });

                // We need to wait until the description data has been fetched
                Promise.all(promises)
                .then((updatedMachineArray) => {
                    const machineDetails = {
                        data: machineData, 
                        items: updatedMachineArray
                    }
                    resolve(machineDetails);
                })
                .catch((error) => {
                    reject(error);
                });
            })
            .catch((error) => {
                reject(error);
            });
    });
}


export function setIsLoadingMenu(callback) {
    return (dispatch) => {
        dispatch({ type: 'IS_LOADING_MENU' });
        if (typeof callback === 'function') {
            callback();
        }
    };
}

export function changeIsMachineActive(machineId, isActive) {
    return (dispatch) => {
        firebase.firestore().collection("machines").doc(machineId).update({
            isActive: isActive
        }).then(() => {
            dispatch({ type: 'CHANGE_IS_MACHINE_ACTIVE'});
        }).catch((error) => {
            console.log(error)
            dispatch({ type: 'ERROR_CHANGING_IS_MACHINE_ACTIVE', error: error });
        });
    };
}

export function clearMachinesData(){
    return ((dispatch) => {
        dispatch({ type: 'CLEAR_MACHINES_DATA'})
    })
}

export function resetMachineDataUpdated(){
    return ((dispatch) => {
        dispatch({ type: 'RESET_MACHINE_DATA_UPDATED'})
    })
}

export function handleSoldOut(data, item, allMachinesData, type) {
    return (dispatch) => {
        let newMachineData = allMachinesData || {};
        if (data.id === "HpeidNR1vX2hnRSWvrzJ") { //testing safeguard to only allow tremplin drinks to be sold out
            let status = false;

            firebase.firestore()
                .collection("machines")
                .doc(data.id)
                .get()
                .then((docSnapshot) => {
                    if (docSnapshot.exists) {

                        return firebase.firestore()
                            .collection("machines")
                            .doc(data.id)
                            .collection("items")
                            .where("familyId", "==", item.familyId)
                            .where("flavorId", "==", item.flavorId)
                            .get();
                    } else {
                        console.error("Document does not exist");
                        throw new Error("Document does not exist"); // Stop execution
                    }
                })
                .then((querySnapshot) => {
                    if (type === "soldout") {
                        status = true;
                    }
                    const updatePromises = querySnapshot.docs.map((doc) =>
                        doc.ref.update({ soldOut: status })
                    );

                    return Promise.all(updatePromises);
                })
                .then(() => {
                    if (newMachineData) {
                    Object.values(newMachineData).map((machine) => {
                        if (data.id === machine.id) {
                            Object.values(machine.items).map((flavors) => {
                                if (flavors.flavorId === item.flavorId) {
                                    flavors.soldOut = status;
                                }
                            })
                        }
                    })
                }

                })
                .then(() => {
                    dispatch({
                        type: "SET_SOLD_OUT",
                        payload: {
                            data: newMachineData
                        }
                    });
                })
                .catch((error) => {
                    console.error("Error updating document:", error);
                });
        }
    };
}
