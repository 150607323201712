import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";


import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { handleSoldOut } from "src/store/actions/machine";
import { fetchAllMachinesData } from "src/store/actions/machine";

const MenuBar = ({menuItems, soldOutOption, soldOutMachineInfo, soldOutDrinkInfo, handleSoldOut, allMachinesData }) => {
  const location = useLocation();
  const navigate = useNavigate();


  return (
    <div className="flex items-center mb-4 mt-4 px-1 py-1 w-fit bg-customOrange rounded-xl">
      {menuItems.map((item) => (
        <button
          key={item.name}
          onClick={soldOutOption 
            ? () => handleSoldOut(soldOutMachineInfo, soldOutDrinkInfo, allMachinesData, item.type)
            : () => navigate(item.route)
          }
          className={`
            px-4 py-2 text-sm font-medium transition-all duration-200 rounded-xl 
            ${
              soldOutOption 
                ? ( (item.name === "Épuisé" && soldOutDrinkInfo.soldOut) || (item.name === "En stock" && !soldOutDrinkInfo.soldOut)
                    ?   "text-white font-semibold bg-customOrange-dark"      
                    :  "text-gray-400 hover:text-black"       
                  ) 
                : (location.pathname === item.route 
                    ? "text-white font-semibold bg-customOrange-dark"   
                    : "text-gray-400 hover:text-black"                
                  )
            }
          `}
        >
          {item.name}
        </button>

      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  drinksInfo: state.drinksState.drinks,
  allMachinesData: state.machineState.allMachinesData,
});

const mapDispatchProps = (dispatch) => ({
  handleSoldOut: (data, item, allMachinesData, type) => dispatch(handleSoldOut(data, item, allMachinesData, type)),  // Explicitly pass dispatch
  fetchAllMachinesData: (gymMachineIds, privilege) => dispatch(fetchAllMachinesData(gymMachineIds, privilege)),
});



export default connect(mapStateToProps, mapDispatchProps)(MenuBar);

