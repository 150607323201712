
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export function setUserId(userId) {
    return ((dispatch) => {
        dispatch({type: 'SET_USER_ID', userId: userId})
    })
}

export function logoutUser(){
    return ((dispatch) => {
        dispatch({type: 'CLEAR_USER_DATA'})
    })
}

// This function returns another function that receives `dispatch`.
export function fetchInfoUser(userId) {
    return (dispatch) => {
      // Perform the async Firestore call:
      firebase
        .firestore()
        .collection("intranetDashboardUsers")
        .doc(userId)
        .get()
        .then((docSnapshot) => {
          if (docSnapshot.exists) {
            const userData = docSnapshot.data();
            // Now we dispatch inside the .then():
            dispatch({
              type: "FETCH_USER_DATA",
              currentUser: {
                firstName: userData.firstName,
                lastName: userData.lastName,
                email: userData.email,
                privilege: userData.privilege,
                gymMachineIds: userData.machineIds,
              },
            });
          } else {
            console.log("No document found with ID:", userId);
          }
        })
        .catch((error) => {
          console.error("Error retrieving document:", error);
        });
    };
  }


  export function setInfoUser(data){
    return (dispatch) => {
        dispatch({
            type: "SET_USER_DATA",
            currentUser: {
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              privilege: data.privilege,
              gymMachineIds: data.machineIds,
            }
        })
    }
  }
  