const initState = {
    userId: null,
    userDataFetch: false, 
    firstName: '', 
    lastName: '',
    email: '',
    privilege:null,
    gymMachineIds:[]
}

const userReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SET_USER_ID':
            return {
                ...state,
                userId: action.userId, 
            };

        case 'SET_USER_DATA': 
            return {
                ...state, 
                firstName: action.currentUser.firstName, 
                lastName: action.currentUser.lastName,
                email: action.currentUser.email, 
                gymMachineIds: action.currentUser.gymMachineIds,
                privilege: action.currentUser.privilege,
                userDataFetch: true, 
            }

        case 'FETCH_USER_DATA': 
            return {
                ...state, 
                firstName: action.currentUser.firstName, 
                lastName: action.currentUser.lastName,
                email: action.currentUser.email, 
                gymMachineIds: action.currentUser.gymMachineIds,
                privilege: action.currentUser.privilege,
                userDataFetch: true, 
            }
        case 'CLEAR_USER_DATA':
            return initState
        default:
            return state
    }
}

export default userReducer