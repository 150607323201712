import { LucideEthernetPort } from "lucide-react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  ReferenceLine,
} from "recharts";

const CustomTooltip = ({ active, payload, label, revenue, nameTooltip }) => {
  if (active && payload && payload.length) {
    return (
      <div className="p-4 bg-white border rounded-xl shadow">
        <p style={{ marginBottom: "8px", fontWeight: "bold", color: "#333" }}>
          Date: {label}
        </p>
        <p style={{ margin: 0, color: "#90CAF9" }}>
          {nameTooltip || "Valeurs"}: {payload[0].value}
          {revenue ? "€" : ""}
        </p>
      </div>
    );
  }

  return null;
};

const AreaGraph = ({ data, title, dataKey, revenue, nameTooltip, colorLine, colorArea, isPhone }) => {
  // Calculate the average value
  try {
    if (data) {
      const average = data.reduce((sum, item) => sum + (item[dataKey] || 0), 0) / data.length || 0;
      const sumValues = data.reduce((sum, item) => sum + (item[dataKey] || 0), 0) || 0;
  
      let combinedData = data.map((item) => ({
        ...item,
        averageValue: average, // Add averageValue for the Line
      }));
  
      // Determine the interval for skipping data points
      const interval = data.length > 150 ? 5 : 1;
      combinedData = data.filter((_, index) => index % interval === 0);
  
      const renderLegend = (colorArea) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "14px",
            marginTop: "10px",
            color: "#333",
            gap: "16px", 
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>Total: <span className="font-semibold">{sumValues.toFixed(2)}{revenue ? "€" : ""}</span></span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                display: "inline-block",
                width: "12px",
                height: "12px",
                backgroundColor: "red",
                marginRight: "8px",
                borderRadius: "2px",
              }}
            ></span>
            <span>Moyenne: {average.toFixed(2)}{revenue ? "€" : ""}</span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                display: "inline-block",
                width: "12px",
                height: "12px",
                backgroundColor: colorArea,
                marginRight: "8px",
                borderRadius: "2px",
              }}
            ></span>
            <span>{nameTooltip || "Valeurs"}</span>
          </div>
        </div>
      );
  
      return (
        <div className="p-6 bg-white border border-white rounded-xl shadow">
          <div className="flex items-center justify-between mb-4">
            <h3
              className="text-lg font-semibold text-left ml-8 mt-[15px] mb-[15px]"
              style={{ marginLeft: "25px", flexWrap: "wrap", marginRight: "10px" }}
            >
              {title}
            </h3>
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart data={combinedData}>
              <XAxis
                dataKey="date"
                axisLine={false}
                tickLine={false}
                interval={isPhone ? null : (data.length > 150 ? Math.floor(data.length / 50) : Math.floor(data.length / 5))}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickFormatter={(value, index) => index === 0 ? "" : (revenue ? `${value}€` : value)}
                domain={[(min) => Math.min(min, average), "auto"]}
              />
              <Tooltip content={<CustomTooltip revenue={revenue} nameTooltip={nameTooltip} />} />
              <Legend content={() => renderLegend(colorArea)} />
              <ReferenceLine
                y={average}
                stroke="red"
                strokeDasharray="3 3"
                label={{
                  position: "insideTopRight",
                  fill: "red",
                  fontSize: 12,
                }}
              />
              <Area
                type="monotone"
                dataKey={dataKey}
                stroke={colorLine}
                fill={colorArea}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      );
    }
  } catch (error) {
    console.error("Error rendering chart:", error);
    return <div className="p-6 bg-white border border-white rounded-xl shadow text-red-500">Error loading chart data</div>;
  }
  
}
export default AreaGraph;
