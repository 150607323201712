import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import DonutChart from "src/utils/dashboard/donutChart";
import Filter from "../../assets/images/filter_icon.jpg";
import { IoMdFitness } from "react-icons/io";
import { fetchAllMachinesData } from "src/store/actions/machine";
import Modal from "react-modal";
import { FiUser } from "react-icons/fi";
import { addMaintenanceTask, fetchAllMaintenanceTasks, updateMaintenanceTask } from "src/store/actions/tasks";
import { RotatingLines } from "react-loader-spinner";
import { postRequest } from "src/utils/serverApi";


// Make sure to set the app element for accessibility
Modal.setAppElement('#root');


const users = [
  { name: "Antoine Garbay", email: "antoine.garbay@fiilin.fr"}, 
  { name: "Mickael Leban", email: "mickael.leban@fiilin.fr"}, 
  { name: "Jonathan Leban", email: "jonathan.leban@fiilin.fr" }
]

const colorsTask = {"Machine": "#FFD699", "App": "#90CAF9", "Produit": "#F08300", "default": "#00FFFF"}
const colors = ["#AEC6CF", "#FFD1DC", "#FFF5BA", "#77DD77", "#E3D9F3"] 

const colorsStatus = {"-": "#FFFFFF", "Pas traité": "#FFF9C4", "En cours": "#A3D8F4", "Terminé": "#A8E6CF"}
const colorsTextStatus = {"-": "gray", "Pas traité": "#F57F17", "En cours": "#3B6A88", "Terminé": "#2E7D32"}
const statusList = ["-", "Pas traité", "En cours", "Terminé"]

const colorsType = {"-": "transparent", "Machine": "#00FFFF", "App": "#F08300", "Produit": "#D1A8D9"}
const colorsTextType = {"-": "gray", "Machine": "#001B25", "App": "#FFFFFF", "Produit": "#4E2A61"}
const typesList = ["-", "Machine", "App", "Produit"]

const isPhone = window.innerWidth <= 768;

// this page is for the maintenance of all the machines
const Maintenance = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [taskIdPopOver, setTaskIdPopOver] = useState(null)
  const [doneTaskPopOver, setDoneTaskPopOver] = useState(false)
  const [titleTaskPopOver, setTitleTaskPopOver] = useState('');
  const [hasChanged, setHasChanged] = useState(false)
  const [taskCounts, setTaskCounts] = useState([
    {
      "name": "Terminé", 
      "value": 0
    }, 
    {
      "name": "En cours", 
      "value": 0
    }, 
    {
      "name": "Pas traité", 
      "value": 0
    }
  ]);
  
  // gym dropdown
  const [gymNamePopOver, setGymNamePopOver] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  
  // responsible dropdown
  const [responsiblePopOver, setResponsiblePopOver] = useState(null);
  const [isOpenResponsible, setIsOpenResponsible] = useState(false);
  const [colorBackgroundName, setColorBackgroundName] = useState(null)

  // status dropdown
  const [statusPopOver, setStatusPopOver] = useState(null);
  const [isOpenStatus, setIsOpenStatus] = useState(false);

  // status dropdown
  const [typePopOver, setTypePopOver] = useState(null);
  const [isOpenType, setIsOpenType] = useState(false);

  // description
  const [descriptionTaskPopOver, setDescriptionTaskPopOver] = useState(null)
  
  // ref to dropdown
  const dropdownRef = useRef(null); // Ref for the dropdown
  const dropdownResponsibleRef = useRef(null); // Ref for the responsible dropdown
  const dropdownStatusRef = useRef(null); // Ref for the status dropdown
  const dropdownTypeRef = useRef(null)

  // new task modal
  const [isNewTask, setIsNewTask] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const handleOpenModal = (task, newTask) => {
    setTaskIdPopOver(task.id)
    setTitleTaskPopOver(task.title || null)
    setGymNamePopOver(task.gym || null)
    setDescriptionTaskPopOver(task.description || null)
    setDoneTaskPopOver(task.done  || false)
    setResponsiblePopOver(task.responsible || null)
    setColorBackgroundName(task.colorBackgroundName || "#AEC6CF")
    setStatusPopOver(task.status || "-")
    setTypePopOver(task.type || "-")
    setIsNewTask(newTask)
    setShowErrorMessage(false)
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setHasChanged(false)
  };

  // ******** Title Task *******
  const handleChangeTitleTask = (value) => {
    setTitleTaskPopOver(value)

    if (!isNewTask) {
      setHasChanged(true)
    }
    
  }
  

  // *******$ Gym dropdown *******
  const handleSelectGym = (gym) => {
    if (gym !== gymNamePopOver && !isNewTask) {
      setHasChanged(true)
    }
    setGymNamePopOver(gym);
    setIsOpen(false)
  };

  const handleDeleteGym = () => {
    if (!isNewTask) {
      setHasChanged(true)
    }
    setGymNamePopOver(null);
    setIsOpen(false)
  }

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  // ******* Responsible dropdown *******
  const handleSelectResponsible = (user, index) => {
    if (user !== responsiblePopOver && !isNewTask) {
      setHasChanged(true)
    }
    setResponsiblePopOver(user);
    setColorBackgroundName(colors[index])
    setIsOpenResponsible(false)
  };

  const handleDeleteResponsible = () => {
    if (!isNewTask) {
      setHasChanged(true)
    }
    setResponsiblePopOver(null);
    setIsOpenResponsible(false)
  }

  const toggleDropdownResponsible = () => {
    setIsOpenResponsible((prev) => !prev);
  };

  // ******* Status dropdown *********
  const handleSelectStatus = (status) => {
    if (status !== statusPopOver && !isNewTask) {
      setHasChanged(true)
    }
    setStatusPopOver(status);
    setIsOpenStatus(false)
  };


  const toggleDropdownStatus = () => {
    setIsOpenStatus((prev) => !prev);
  };

  // ******* Status dropdown *********

  const handleSelectType = (type) => {
    if (type !== typePopOver && !isNewTask) {
      setHasChanged(true)
    }
    setTypePopOver(type);
    setIsOpenType(false)
  };


  const toggleDropdownType = () => {
    setIsOpenType((prev) => !prev);
  };

    // ********* Description task *******
    const handleChangeDescriptionTask = (value) => {
      setDescriptionTaskPopOver(value)
      if (!isNewTask) {
        setHasChanged(true)
      }
    }

  // close dropdown when clicking outside

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
      if (dropdownResponsibleRef.current && !dropdownResponsibleRef.current.contains(event.target)) {
        setIsOpenResponsible(false);
      }
      if (dropdownStatusRef.current && !dropdownStatusRef.current.contains(event.target)) {
        setIsOpenStatus(false);
      }
      if (dropdownTypeRef.current && !dropdownTypeRef.current.contains(event.target)) {
        setIsOpenType(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
      if (!props.allMachinesDataFetched) {
        props.fetchAllMachinesData();
      } 
    }, [props.allMachinesDataFetched]);

  useEffect(() => {
    if (!props.maintenanceTasksFetched) {
      props.fetchAllMaintenanceTasks()
    } else {
      const counts = {
        "Terminé": 0,
        "En cours": 0,
        "Pas traité": 0,
      };

      props.tasks.forEach((task) => {
        if (counts[task.status] !== undefined) {
          counts[task.status]++;
        }
      });

      // Update taskCounts state
      setTaskCounts((prev) =>
        prev.map((item) => ({
          ...item,
          value: counts[item.name] || 0,
        }))
      );
    }
  }, [props.maintenanceTasksFetched, props.tasks])

  const handleModifyTask = async(taskId, title, description, status, type, gym, nameResponsible, emailResponsible, colorBackgroundName, tasks) => {
    props.updateMaintenanceTask(taskId, title, description, status, type, gym, nameResponsible, emailResponsible, colorBackgroundName, tasks)

    await postRequest("/email/modifyTaskUser", { 
      email: emailResponsible, 
      name: nameResponsible, 
      colorBackgroundName: colorBackgroundName, 
      title: title, 
      type: type, 
      description: description
    }, 7000);

    setHasChanged(false)
  }

  const handleAddTask = async(title, description, status, type, gym, responsiblePopOver, colorBackgroundName, tasks) => {
    if (title && title !== "" && status !== "-" && type !== "-" && gym && responsiblePopOver) {
      props.addMaintenanceTask(title, description, status, type, gym, responsiblePopOver["name"], responsiblePopOver["email"], colorBackgroundName, tasks)

      setIsModalOpen(false)

      const nameResponsible = responsiblePopOver["name"]
      const emailResponsible = responsiblePopOver["email"]
      
      await postRequest("/email/addTaskUser", { 
        email: emailResponsible, 
        name: nameResponsible, 
        colorBackgroundName: colorBackgroundName, 
        title: title, 
        type: type, 
        description: description
      }, 7000);
    } else {
      // we need to tell the user to fill out all the fields
      setShowErrorMessage(true)
    }
  }


  return (
<div className="min-h-screen flex flex-col w-full p-6">
  {props.allMachinesData ? (
    isPhone ? (
      <div className="min-h-screen flex flex-col w-full p-6 bg-customOrange-light" style={{ marginTop: "20px", paddingLeft: "4%", paddingRight: "4%" }}>
        <div className="flex flex-col w-full pt-8">
          {/* Maintenance section */}
          <div className="flex flex-col justify-start items-start mb-4 space-y-2">
            <h3 className="mt-1.5 text-xl font-bold text-left">Maintenance</h3>
            <div className="flex space-x-2 w-full">
              <button className="bg-customOrange border border-customOrange-dark text-black px-4 py-2 rounded-full" style={{ fontSize: "12px" }} onClick={() => handleOpenModal({}, true)}>
                + Nouvelle tâche
              </button>
              <button className="bg-white text-black px-4 py-2 border rounded-xl flex items-center" style={{ fontSize: "12px" }}>
                <img src={Filter} className="mr-1" style={{ width: "18px", height: "auto" }} />
                Filtrer
              </button>
            </div>
          </div>

          {/* Donut Chart for phone view */}
          {props.tasks.length > 0 && (
            <div className="w-full mb-6">
              <DonutChart data={taskCounts} title="Répartition des tâches" phoneView={true} needTransform={false} />
            </div>
          )}

          {/* Task List section */}
          <div className="space-y-6">
            {props.tasks.map((task, index) => (
              <div key={index} className="flex flex-row items-center border rounded-xl h-[120px] border-transparent cursor-pointer" onClick={() => handleOpenModal(task, false)}>
                <div className={`w-[8px] h-[120px] border -ml-[1px]`} style={{ borderTopLeftRadius: "0.75rem", borderBottomLeftRadius: "0.75rem", borderColor: "transparent", backgroundColor: task.type ? colorsTask[task.type] : colorsTask["default"] }}></div>
                <div className="flex-1 flex-col items-start bg-white h-[120px]" style={{ borderTopRightRadius: "0.75rem", borderBottomRightRadius: "0.75rem" }}>
                  <div className="flex flex-col justify-between flex-1 ml-[1rem] mt-[10px]">
                    <h4 className="text-left text-sm font-semibold">{task.title}</h4>
                    <p className="text-xs text-gray-500 text-left">{task.description}</p>
                  </div>
                  <div className="flex flex-row items-center mt-[10px] ml-[1rem] mr-[1rem] space-x-2">
                    <button className="text-left text-customOrange-dark text-left border-b-[1px] border-customOrange-dark pb-0.2" style={{ fontSize: "10px", marginTop: "2px" }}>
                      Voir tâche
                    </button>
                    {task.gym && (
                      <div className="flex bg-gray-100 border border-gray-500 text-gray-600 rounded-full items-center justify-center px-1 mt-[2px]" style={{ fontSize: "10px" }}>
                        {task.gym}
                      </div>
                    )}
                    {task.responsible && (
                      <div className="flex bg-gray-100 border border-transparent text-black rounded-full items-center justify-center px-1 mt-[2px] w-[25px] h-[25px]" style={{ fontSize: "10px", backgroundColor: task.colorBackgroundName }}>
                        {task.responsible["name"].split(" ")[0][0]}{task.responsible["name"].split(" ")[1][0]}
                      </div>
                    )}
                    <div className="flex-1"></div>
                    <span
                      className="px-2 py-1 rounded-full mr-[10px] items-center justify-center flex"
                      style={{
                        backgroundColor: colorsStatus[task.status],
                        color: colorsTextStatus[task.status],
                        fontWeight: "500",
                        fontSize: "8px",
                        height: "20px", 
                        lineHeight: "20px", 
                        display: "flex", 
                        alignItems: "center", 
                        justifyContent: "center"
                      }}
                    >
                      {task.status}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    ) : (
      <div className="min-h-screen flex flex-col w-full p-6 bg-customOrange-light" style={{ marginTop: "20px", paddingLeft: "4%", paddingRight: "4%" }}>
        <div className="flex-grow overflow-auto w-full p-8">
          <div className="flex space-x-12 p-6">
            <div className="w-3/5">
              <div className="flex justify-between items-right mb-4 space-x-4">
                <h3 className="mt-1.5 text-2xl font-bold text-left flex-1">Maintenance</h3>
                <button className="bg-customOrange border border-customOrange-dark text-black px-6 py-3 rounded-full" style={{ fontSize: "14px" }} onClick={() => handleOpenModal({}, true)}>+ Nouvelle tâche</button>
                <button className="bg-white text-black px-6 py-3 border rounded-xl flex items-center" style={{ fontSize: "14px" }}>
                  <img src={Filter} className="mr-2" style={{ width: "20px", height: "auto" }} />
                  Filtrer
                </button>
              </div>
              <div className="space-y-6">
                {props.tasks.map((task, index) => (
                  <div key={index} className="flex flex-row items-center border rounded-xl h-[135px] border-transparent cursor-pointer" onClick={() => handleOpenModal(task, false)}>
                    <div className={`w-[10px] h-[130px] border -ml-[1px]`} style={{borderTopLeftRadius: "0.75rem", borderBottomLeftRadius: "0.75rem", borderColor: "transparent", backgroundColor: task.type ? colorsTask[task.type] : colorsTask["default"]}}></div>
                    <div className="flex-1 flex-col items-center bg-white h-[130px]" style={{borderTopRightRadius: "0.75rem", borderBottomRightRadius: "0.75rem"}}>
                      <div className="flex-1 ml-[2.5rem] mt-[20px]">
                        <h4 className="text-left" style={{fontWeight: "600"}}>{task.title}</h4>
                        <p className="text-sm text-gray-500 text-left">{task.description}</p>
                      </div>
                      <div className="flex flex-row mt-[25px] ml-[2.5rem] mr-[1.5rem]">
                        <button className="text-left text-customOrange-dark text-left border-b-[1.5px] border-customOrange-dark pb-0.2" style={{ fontSize: "14px", marginTop: "3px" }}>
                          Voir tâche
                        </button>
                        {task.gym && (
                          <div className="flex bg-gray-100 border border-gray-500 text-gray-600 rounded-full items-center justify-center px-2 ml-[20px] mt-[3px]" style={{fontSize: "14px"}}>
                            {task.gym}
                          </div>
                        )}
                        {task.responsible && (
                          <div className="flex bg-gray-100 border border-transparent text-black rounded-full items-center justify-center px-2 ml-[20px] mt-[3px] w-[30px] h-[30px]" style={{fontSize: "13px", backgroundColor: task.colorBackgroundName}}>
                            {task.responsible["name"].split(" ")[0][0]}{task.responsible["name"].split(" ")[1][0]}
                          </div>
                        )}
                        <div className="flex-1"></div>
                        <span
                          className="px-4 py-1 rounded-full mr-[15px] items-center justify-center"
                          style={{
                            backgroundColor: colorsStatus[task.status],
                            color: colorsTextStatus[task.status],
                            fontWeight: "500",
                            fontSize: "14px",
                            height: "30px"
                          }}
                        >
                          {task.status}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-2/5">
              {props.tasks.length > 0 && <DonutChart data={taskCounts} title="Répartition des taches" phoneView={window.innerWidth < 1200} needTransform={false}/>}
            </div>
          </div>
        </div>
      </div>
    )
  ) : (
    <RotatingLines strokeColor="#f08300" height={30} width={30} visible={true} animationDuration="1.25" />
  )}

  {/* Modal for task editing */}
  <Modal
    isOpen={isModalOpen}
    onRequestClose={handleCloseModal}
    contentLabel="Task Edit Modal"
    className={`modal-class ${isPhone ? "w-full max-w-sm" : "w-1/2 max-w-lg"}`} // Adjusts width based on phone or desktop
    overlayClassName="overlay-class"
    closeTimeoutMS={200}
  >
    {/* Modal content */}
    <div className={`p-4 ${isPhone ? "overflow-auto max-h-[80vh]" : ""}`}>
      {/* Header */}
      <div className="flex justify-between items-center w-full border-b border-gray-300 pb-4">
        <div className="flex justify-between items-center w-full px-4">
          {doneTaskPopOver && !isNewTask ? (
            <button
              className="bg-green-100 border-green-600 text-green-600 border rounded-md text-sm px-4 py-1.5 rounded-xl"
              onClick={() => alert("Task marked as completed!")}
            >
              Terminée
            </button>
          ) : !isNewTask ? (
            <button
              className="focus:bg-customOrange-light focus:border-customOrange-dark focus:text-customOrange-dark hover:bg-customOrange-light hover:border-customOrange-dark hover:text-customOrange-dark border rounded-md text-black text-sm px-4 py-1.5 rounded-xl"
              onClick={() => alert("Task marked as completed!")}
            >
              ✔ Marquer comme terminée
            </button>
          ) : null}
          <button className="text-gray-500 text-xl" onClick={handleCloseModal}>
            ×
          </button>
        </div>
      </div>

      {/* Task Input */}
      <div style={{ paddingTop: isNewTask ? "0px" : "20px" }}>
        <input
          type="text"
          value={titleTaskPopOver}
          onChange={(e) => handleChangeTitleTask(e.target.value)}
          placeholder="Renseigne ta tâche"
          className={`border border-transparent rounded-md px-4 py-2 w-full ${titleTaskPopOver || titleTaskPopOver === "" ? "text-gray-500" : "text-black"} hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-orange-500 text-lg font-semibold`}
        />
      </div>

      {/* Gym Dropdown */}
      <div className="relative flex items-center gap-2 mt-4" ref={dropdownRef}>
        <p className="px-4 text-sm">Club</p>
        <div className="flex items-center gap-2 px-4 py-2 cursor-pointer">
          <button onClick={toggleDropdown} className="flex items-center cursor-pointer">
            {!gymNamePopOver && (
              <div className="flex justify-center items-center border-dashed border-gray-500 rounded-full p-1" style={{ borderWidth: "1.5px" }}>
                <IoMdFitness className="text-xl text-gray-500" />
              </div>
            )}
            <span className="ml-2 text-sm" style={{ color: gymNamePopOver ? "black" : "gray" }}>
              {gymNamePopOver || "Aucune salle"}
            </span>
          </button>
          {gymNamePopOver && (
            <span onClick={() => handleDeleteGym()} className="text-gray-500 cursor-pointer ml-2 text-xs">
              ✕
            </span>
          )}
        </div>

        {isOpen && (
          <div className="absolute left-0 mt-2 bg-white border border-gray-300 rounded-md shadow-lg z-10 w-full max-h-60 overflow-y-auto">
            <ul>
              {props.allMachinesData.map((gymData, index) => (
                <li
                  key={index}
                  onClick={() => handleSelectGym(gymData["name"])}
                  className="flex items-center py-2 cursor-pointer hover:bg-gray-100 pl-4 pr-8"
                >
                  <span className="text-gray-700 text-sm">{gymData["name"]}</span>
                  {gymNamePopOver === gymData["name"] && <span className="text-gray-700 ml-2">✔</span>}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Responsible Dropdown */}
      <div className="relative flex items-center gap-2 mt-4" ref={dropdownResponsibleRef}>
        <p className="px-4 text-sm">Responsable</p>
        <div className="flex items-center gap-2 px-4 py-2 cursor-pointer">
          <button onClick={toggleDropdownResponsible} className="flex items-center cursor-pointer">
            {responsiblePopOver ? (
              <div className="rounded-full flex items-center justify-center w-[30px] h-[30px]" style={{ backgroundColor: colorBackgroundName }}>
                <span className="text-black text-xs">
                  {responsiblePopOver["name"].split(" ")[0][0]}{responsiblePopOver["name"].split(" ")[1][0]}
                </span>
              </div>
            ) : (
              <div className="flex justify-center items-center border-dashed border-gray-500 rounded-full p-1" style={{ borderWidth: "1.5px" }}>
                <FiUser className="text-xl text-gray-500" />
              </div>
            )}
            <span className="ml-2 text-sm" style={{ color: responsiblePopOver ? "black" : "gray" }}>
              {responsiblePopOver?.name || "Aucun Responsable"}
            </span>
          </button>
          {responsiblePopOver && (
            <span onClick={() => handleDeleteResponsible()} className="text-gray-500 cursor-pointer ml-2 text-xs">
              ✕
            </span>
          )}
        </div>

        {isOpenResponsible && (
          <div className="absolute left-0 mt-2 bg-white border border-gray-300 rounded-md shadow-lg z-10 w-full max-h-60 overflow-y-auto">
            <ul>
              {users.map((user, index) => (
                <li
                  key={index}
                  onClick={() => handleSelectResponsible({ name: user.name, email: user.email }, index)}
                  className="flex items-center py-2 cursor-pointer hover:bg-gray-100 pl-4 pr-8"
                >
                  <span
                    className="rounded-full flex items-center justify-center w-[30px] h-[30px] mr-2"
                    style={{ backgroundColor: colors[index], fontSize: "13px" }}
                  >
                    {user.name.split(" ")[0][0]}{user.name.split(" ")[1][0]}
                  </span>
                  <span className="text-gray-700 text-sm">{user.name}</span>
                  <span className="text-gray-400 text-xs ml-2">{user.email}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Action Buttons */}
      <div className="flex justify-between items-center mt-6">
        {hasChanged && (
          <button
            className="bg-green-100 border-green-600 text-green-600 text-sm px-4 py-1.5 rounded-xl"
            onClick={() => handleModifyTask(taskIdPopOver, titleTaskPopOver, descriptionTaskPopOver, statusPopOver, typePopOver, gymNamePopOver, responsiblePopOver["name"], responsiblePopOver["email"], colorBackgroundName, props.tasks)}
          >
            Modifier
          </button>
        )}
        <div />
        {isNewTask && (
          <button
            className="bg-customOrange-light border-customOrange-dark text-customOrange-dark text-sm px-4 py-1.5 rounded-xl"
            onClick={() => handleAddTask(titleTaskPopOver, descriptionTaskPopOver, statusPopOver, typePopOver, gymNamePopOver, responsiblePopOver, colorBackgroundName, props.tasks)}
          >
            ✔ Ajouter
          </button>
        )}
      </div>

      {/* Error Message */}
      {showErrorMessage && (
        <div className="mt-4">
          <span className="text-red-500 text-xs">Vous devez compléter tous les champs</span>
        </div>
      )}
    </div>
  </Modal>
</div>



  );
};

const mapStateToProps = (state) => ({
  allMachinesData: state.machineState.allMachinesData,
  allMachinesDataFetched: state.machineState.allMachinesDataFetched,
  tasks: state.taskState.tasks, 
  maintenanceTasksFetched: state.taskState.maintenanceTasksFetched
});

const mapDispatchProps = (dispatch) => {
  return {
    fetchAllMachinesData: () => { dispatch(fetchAllMachinesData()) },
    fetchAllMaintenanceTasks: () => { dispatch(fetchAllMaintenanceTasks()) }, 
    updateMaintenanceTask: (taskId, title, description, status, type, gym, nameResponsible, emailResponsible, colorBackgroundName, tasks) => { dispatch(updateMaintenanceTask(taskId, title, description, status, type, gym, nameResponsible, emailResponsible, colorBackgroundName, tasks)) }, 
    addMaintenanceTask: (title, description, status, type, gym, nameResponsible, emailResponsible, colorBackgroundName, tasks) => { dispatch(addMaintenanceTask(title, description, status, type, gym, nameResponsible, emailResponsible, colorBackgroundName, tasks)) }
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Maintenance);
