import React from "react";
import { FaDollarSign, FaUsers, FaUser, FaStar } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import { MdLocalDrink } from "react-icons/md";

const BigDataBlock = ({ data = [] }) => {  
  const isPhone = window.innerWidth <= 768;

  // Function to format numbers with spaces for readability
  const formatValue = (value, type) => {
    let formattedValue = parseFloat(value || 0); 
    if (type === "money" || type === "subscription") {
      formattedValue = formattedValue.toFixed(2);
    }

    let valueStr = formattedValue.toString();
    if (formattedValue > 999) {
      valueStr = valueStr.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    if (type === "money" || type === "subscription") {
      valueStr += "€";
    }

    return valueStr;
  };

  // Define icons and colors for each data type
  const iconMap = {
    money: <FaDollarSign color="black" size={22} />, // Increased icon size
    subscription: <FaPeopleGroup color="black" size={22} />,
    drink: <MdLocalDrink color="black" size={22} />,
    user: <FaUser color="black" size={22} />,
    star: <FaStar color="black" size={22} />,
  };

  const bgColorMap = {
    money: "bg-green-100",
    subscription: "bg-yellow-100",
    drink: "bg-blue-100",
    user: "bg-gray-100",
    star: "bg-yellow-100",
  };

  return (
    <div className="p-8 bg-white border-transparent rounded-xl flex flex-col items-center justify-center w-full">
      <div className="flex gap-8 w-full items-center justify-center px-6"> 
        {data.length > 0 ? (
          data.map((item, index) => (
            <div key={index} className="flex flex-col items-center text-center w-1/4">
              {/* Icon in a circle on top */}
              <div className={`w-11 h-11 ${bgColorMap[item.type]} flex items-center justify-center rounded-full mb-2`}>
                {iconMap[item.type]}
              </div>

              {/* Value below the icon */}
              <div className={`text-black ${isPhone ? "text-1xl" : "text-4xl"} font-bold`}>
                {formatValue(item.value, item.type)}
              </div>

              {/* Title at the bottom */}
              <div className="text-gray-600 text-sm mt-1 font-medium">{item.title}</div>
            </div>
          ))
        ) : (
          <div className="text-gray-500 text-lg">No data available</div>
        )}
      </div>
    </div>
  );
};

export default BigDataBlock;
