const initState = {
    drinks: null,
    drinksFetched: false
}

const drinksReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_DRINKS_INFO':
            console.log(action)
            return {
                ...state,
                drinks: action.drinks, 
                drinksFetched: true
            };
        case 'ERROR_FETCHING_DRINKS_INFO': 
            return {
                ...state, 
                drinksFetched: false
            }
        default:
            return state
    }
}

export default drinksReducer