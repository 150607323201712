import React, { useEffect, useState, useRef } from "react";
import "../styles/navbar.css";
import Logo from "../assets/images/Fiilin_logo.png";
import ProfileImage from "../assets/images/userProfileImage.png"; // User profile image
import { FaHome } from "react-icons/fa";
import { MdSpaceDashboard, MdAutoGraph } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import { FaTools } from "react-icons/fa";
import { IoLogOut } from "react-icons/io5";
import { onSignOut } from "src/pages/auth/signOut";
import { connect, useDispatch } from 'react-redux';
import { fetchAllMachinesData } from "src/store/actions/machine";


const NavBar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isPhone = window.innerWidth <= 768;
  const dispatch = useDispatch()

  const dropdownRef = useRef(null);  // Reference to the dropdown
  const iconRef = useRef(null);      // Reference to the profile icon

  const [selectedPage, setSelectedPage] = useState("/");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  
  useEffect(() => {
    props.fetchAllMachinesData(props.gymMachineIds, props.privilege); //fetch here to make sure its updated
  //  console.log(props.allMachinesData)
   //console.log(props.allMachinesData.some(machine => machine["machineConfig"] && machine["machineConfig"].next != null))
  },[])

  useEffect(() => {
    setSelectedPage(location.pathname);
  }, [location.pathname]);

  const handleSignOut = () => { 
    dispatch(onSignOut())
  }

  // Handle click outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setIsDropdownVisible(false);
      }
    };

    // Add event listener when the dropdown is visible
    if (isDropdownVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener when the dropdown is hidden
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownVisible]);
    
    // Toggle the dropdown visibility
    const toggleDropdown = () => {
      setIsDropdownVisible(!isDropdownVisible);
    };


  if (isPhone) {
    return (
      <nav className="navbar-fixed-phone bg-customOrange-light min-h-[130px]">
        <div className="navbar-container-phone flex w-full overflow-x-auto">
          {/* Logo and Mascott */}
          <div className="flex items-center">
            <button onClick={() => navigate("/")} className="brand-loggo-phone mt-3">
              {/* <img src={Mascott} alt="Mascott" className="mascott-image ml-2" /> */}
              <img src={Logo} alt="logo" className="logo-image-phone" />
            </button>
          </div>
    
          {/* Menu Items and Profile Container */}
          <div className="flex items-center flex-grow justify-between ml-2">
            {/* Menu Items */}
            <div className="navbar-text-container overflow-x-auto overflow-y-hidden flex-nowrap items-center gap-4 w-auto bg-white px-3 py-2 rounded-lg shadow flex-wrap min-w-0">
              <button
                onClick={() => navigate("/")}
                className={`navbar-text-content whitespace-nowrap ${
                  selectedPage.startsWith("/management") ? "navbar-text-content-selected" : ""
                }`}
              >
                <FaHome className="mr-2" />
                Pilotage
              </button>
    
              <button
                onClick={() => navigate("/finance/dashboard")}
                className={`navbar-text-content whitespace-nowrap ${
                  selectedPage.startsWith("/finance")
                    ? "navbar-text-content-selected"
                    : ""
                }`}
              >
                <MdSpaceDashboard className="mr-2" />
                Finance
              </button>
    
              {props.privilege === "fiilin" && (
                <button
                  onClick={() => navigate("/maintenance")}
                  className={`navbar-text-content whitespace-nowrap ${
                    selectedPage.startsWith("/maintenance")
                      ? "navbar-text-content-selected"
                      : ""
                  }`}
                >
                  <FaTools className="mr-2" />
                  Maintenance
                </button>
              )}
            </div>
            
            {/* Profile Icon */}
            <div className="icon-profile ml-auto relative">
              <div
                ref={iconRef}
                className="profile-initials flex items-center justify-center rounded-full shadow object-cover p-1 bg-customOrange-dark text-white font-bold cursor-pointer"
                onClick={toggleDropdown}
                style={{ width: '40px', height: '40px' }}
              >
                {props.firstName[0]}{props.lastName[0]}
              </div>
    
              {/* Dropdown Menu */}
              {isDropdownVisible && (
                <div 
                  ref={dropdownRef}
                  className="absolute right-0 mt-2 min-w-[80px] bg-white shadow-xl rounded-lg overflow-hidden transition-all duration-200 ease-in-out text-sm"
                >
                  <ul className="py-1">
                    <li>
                      <button
                        onClick={handleSignOut}
                        className="flex items-center gap-1.5 px-3 pr-2 py-2 text-gray-800 w-auto text-left hover:bg-gray-100 hover:text-gray-900 transition-colors duration-200 ease-in-out cursor-pointer"
                      >
                        <IoLogOut className="text-sm" />
                        <span className="text-xs sm:text-sm">Logout</span>
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    );
    
  } else {
    return (
      <nav className="navbar-fixed w-full max-w-none bg-customOrange-light px-10">
        <div className="navbar-container w-full flex items-center justify-between">
          {/* Logo and Mascot */}
          <div className="flex items-center mr-4">
            <button onClick={() => navigate("/")} className="ml-2 brand-loggo">
              <img src={Logo} alt="logo" className="logo-image" />
            </button>
          </div>

          {/* Menu Items and Profile Container */}
          <div className="flex items-center flex-grow justify-between">
            {/* Menu Items */}
            <div className="navbar-text-container flex items-center gap-4 w-auto bg-white px-4 py-2 rounded-lg shadow">
              <button
                onClick={() => navigate("/")}
                className={`navbar-text-content ${
                  selectedPage.startsWith("/management") ? "navbar-text-content-selected" : ""
                }`}
              >
                <FaHome className="mr-2" />
                Pilotage
              </button>

              <button
                onClick={() => navigate("/finance/dashboard")}
                className={`navbar-text-content ${
                  selectedPage.startsWith("/finance")
                    ? "navbar-text-content-selected"
                    : ""
                }`}
              >
                <MdSpaceDashboard className="mr-2" />
                Finance
              </button>

              {props.privilege === "fiilin" && (
                <button
                  onClick={() => navigate("/maintenance")}
                  className={`navbar-text-content ${
                    selectedPage.startsWith("/maintenance")
                      ? "navbar-text-content-selected"
                      : ""
                  }`}
                >
                  <FaTools className="mr-2" />
                  Maintenance
                </button>
              )}
            </div>
          {/* Profile Icon & Update Button */}
          <div className="flex items-center gap-4 ml-auto">
            {/* Mettre à jour Button */}
            {props.allMachinesDataFetched && props.privilege === "gym" & props.allMachinesData.some(machine => machine["machineConfig"] && machine["machineConfig"].next != null) ? (<button 
              onClick={() => navigate("/management/configuration")}
              className="bg-blue-600 text-white text-sm font-semibold px-4 h-[40px] min-w-[120px] rounded-full shadow flex items-center justify-center hover:bg-blue-700 transition duration-200"
            >
              Mettre à jour
            </button>) : (null)}

            {/* Profile Icon */}
            <div className="icon-profile relative">
              <div
                ref={iconRef}
                className="profile-initials flex items-center justify-center rounded-full shadow object-cover p-1 bg-customOrange-dark text-white font-bold cursor-pointer"
                onClick={toggleDropdown}
                style={{ width: '40px', height: '40px' }}
              >
                {props.firstName[0]}{props.lastName[0]}
              </div>

              {/* Dropdown Menu */}
              {isDropdownVisible && (
                <div className="absolute right-1 mt-2 w-30 bg-white shadow-xl rounded-xl overflow-hidden transition-all duration-300 ease-in-out"
                  ref={dropdownRef}>
                  <ul className="py-2">
                    <li>
                      <button
                        onClick={handleSignOut}
                        className="flex items-center text-gray-800 px-4 py-3 text-sm w-full text-left hover:bg-gray-100 hover:text-gray-900 transition-colors duration-200 ease-in-out cursor-pointer"
                      >
                        <IoLogOut className="mr-3 text-lg" />
                        <span>Logout</span>
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          </div>
        </div>
      </nav>
    );

  }
  
};


const mapStateToProps = (state) => ({
  privilege: state.userState.privilege,
  firstName: state.userState.firstName,
  lastName: state.userState.lastName,
  email: state.userState,
  allMachinesData: state.machineState.allMachinesData,
  allMachinesDataFetched: state.machineState.allMachinesDataFetched,
  gymMachineIds: state.userState.gymMachineIds,
});

const mapDispatchProps = (dispatch) => ({
  fetchAllMachinesData: (machineIds, privilege) => dispatch(fetchAllMachinesData(machineIds, privilege)),
});

export default connect(mapStateToProps, mapDispatchProps)(NavBar);

