import { CACHE_SIZE_UNLIMITED } from "@firebase/firestore";

const initState = {
    gyms: null,
    gymsFetched: false, 
    gymsData: {}, 
    gymsDataAll: {},
    gymDataAllFetched: false, 
    gymDataFetched: false, 
    setMachineIdGymSuccess: false, 
    machineIdGym: null, 
    gymName: null,
    selectedGymsAnalysis: [], 
    fetchedGymsFinanceNumber: 0,
    fetchedGymsMembershipNumber: 0 
}

const gymReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_GYMS_INFO':
            return {
                ...state,
                gymsData: {
                    ...state.gymsData, // Preserve existing gyms
                    ...Object.fromEntries(action.gyms.map(gym => [gym.id, gym])) // Store gyms as { gymId: gymData }
                },
                gymsFetched: true
            };        
        case 'ERROR_FETCHING_GYMS_INFO': 
            return {
                ...state, 
                gymsFetched: false
            }
        case 'START_FETCHING_GYM_DATA': 
            return {
                ...state, 
                gymDataFetched: false
            }
        case 'START_FETCHING_ALL_GYM_DATA': 
        return {
            ...state, 
            gymDataAllFetched: false
        }
        case 'FETCH_GYM_DATA_SUCCESS':
            return {
                ...state,
                gymsData: {
                    ...state.gymsData,
                    [action.machineId]: {
                        ...action.data,
                        machineId: action.machineId,
                        name: action.nameGym
                    }
                },
                gymDataFetched: true,
                fetchedGymsFinanceNumber: state.fetchedGymsFinanceNumber + 1
            };
        case 'FETCH_GYM_DATA_ALL_SUCCESS':
            return {
                ...state,
                gymsDataAll: {
                ...action.gymsData 
                },
                gymDataAllFetched: true,
            };
              
        case 'SET_MACHINE_ID_GYM_SUCCESS': 
            return {
                ...state, 
                setMachineIdGymSuccess: true, 
                machineIdGym: action.machineId
            }
        case 'RESET_MACHINE_ID_GYM_SUCCESS': 
            return {
                ...state, 
                setMachineIdGymSuccess: false
            }
        case 'SET_GYM_NAME_SUCCESS': 
            return {
                ...state, 
                gymName: action.gymName
            }
        case 'RESET_GYM_NAME_SUCCESS': 
            return {
                ...state, 
                gymName: null
            }
        case 'SET_SELECTED_GYMS_ANALYSIS': 
            return {
                ...state, 
                selectedGymsAnalysis: action.selectedGymsAnalysis
            }
        case 'SET_GYM_DATA_READY': 
            return {
                ...state, 
                gymDataFetched: true
            }
        case 'CLEAR_ALL_GYMS_DATA':
            return initState
        default:
            return state
    }
}

export default gymReducer