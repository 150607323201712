import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useDispatch } from "react-redux";

// This function returns another function that receives `dispatch`.
export function fetchDrinksInfo() {
    console.log("Fetching drink families and flavours...");

    return async (dispatch) => {
        try {
            const familiesRef = firebase.firestore().collection("machineProducts");

            // Step 1: Fetch all family documents from `machineProducts`
            const familiesSnapshot = await familiesRef.get();
            let drinksData = {};

            if (!familiesSnapshot.empty) {
                // Step 2: Loop through each `familyId` document
                const familyPromises = familiesSnapshot.docs.map(async (familyDoc) => {
                    const familyId = familyDoc.id;
                    
                    // Initialize flavours object inside each family
                    drinksData[familyId] = { familyId, flavours: {} };

                    // 🔥 Fetch `flavours` subcollection inside each family
                    
                    const flavoursSnapshot = await familiesRef
                        .doc(familyId)
                        .collection("flavors")
                        .get();

                    if (!flavoursSnapshot.empty) {
                        flavoursSnapshot.forEach((flavourDoc) => {
                            const flavourId = flavourDoc.id;
                            const flavourData = flavourDoc.data();
                            //console.log(flavourData.name)
                            
                            // Store flavourId along with its data
                            drinksData[familyId].flavours[flavourId] = {
                                ...flavourData
                            };
                        });
                    } else {
                        console.warn(`No flavours found for family: ${familyId}`);
                    }
                });

                // Wait for all `flavours` collections to be fetched
                await Promise.all(familyPromises);
            } else {
                console.warn("No drink families found in Firestore.");
            }

            // Step 3: Dispatch all collected data
            console.log("Final drinks data:", drinksData);

            dispatch({
                type: "FETCH_DRINKS_INFO",
                drinks: drinksData
            });

        } catch (error) {
            console.error("Error retrieving drink families and flavours:", error);
        }
    };
}




