import React from "react";

export const Table = ({ children, ...props }) => (
  <div className="w-full rounded-xl">
    <table className="w-full caption-bottom text-sm" {...props}>
      {children}
    </table>
  </div>
);

export const TableHeader = ({ children, ...props }) => (
  <thead className="items-center font-semibold" {...props}>
    {children}
  </thead>
);

export const TableBody = ({ children, ...props }) => (
  <tbody className="" {...props}>
    {children}
  </tbody>
);

export const TableRow = ({ children, ...props }) => (
  <tr {...props} className="border-[0px] border-tranparent">
    <td colSpan="100%" className="p-0 border-transparent">
      <div className={`flex items-center ${props.header ? "" : "hover:bg-gray-200"} hover:cursor-pointer rounded-[20px] border-[1px] border-transparent px-4 py-2 transition-all duration-200`}>
        {children}
      </div>
    </td>
  </tr>
);

export const TableCell = ({ children, ...props }) => (
  <div className={`flex-1 px-4 text-sm text-gray-600 ${props.machineDasboard ? "py-2" : "py-1.5"}`} {...props}>
    {children}
  </div>
);

export const TableHeadCell = ({ children, ...props }) => (
  <th
    scope="col"
    className="flex-1 px-4 py-4 text-left text-sm font-medium text-gray-500"
    {...props}
  >
    {children}
  </th>
);
