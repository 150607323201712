import firebase from 'firebase/compat/app';
import 'react-toastify/dist/ReactToastify.css';
import { logoutUser } from '../../store/actions/user';
import { clearMachinesData } from '../../store/actions/machine';
import { clearAllGymsData } from '../../store/actions/gyms';
import { clearAllMaintenanceTasks } from '../../store/actions/tasks';



export const onSignOut = () => {
    return async (dispatch) => {
        try {
          await firebase.auth().signOut();
          dispatch(logoutUser());
          dispatch(clearMachinesData());
          dispatch(clearAllMaintenanceTasks());
          dispatch(clearAllGymsData());
          console.log("User signed out successfully.");
        } catch (error) {
          console.error("Error signing out:", error);
        }
      };
}



