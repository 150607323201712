import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DataBlock from "../dataBlock";
import BigDataBlock from "../bigDataBlock";
import { PlaceholderBlock, PlaceholderGraph } from "src/utils/placeholder";
import DatePicker from "react-datepicker";
import { fr, se } from "date-fns/locale"; // Import French locale
import { subMonths, startOfYear, endOfYear, differenceInMonths, max} from "date-fns";
import "src/styles/dashboard/customDatePicker.css"; // Import custom CSS file for DatePicker customization
import {
  fetchGymData,
  resetMachineIdGym,
  resetMGymName,
  setGymDataReady,
  setMachineIdGym,
  setGymName,
  fetchAllGymsData,
} from "src/store/actions/gyms";
import { fetchAllMachinesData } from "src/store/actions/machine";
import { fetchInfoUser, setInfoUser } from "src/store/actions/user";
import ProductTable from "src/utils/dashboard/productTable";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import MenuBar from "src/utils/dashboard/menuBar";
import { RotatingLines } from "react-loader-spinner";
import { StackedBarChart } from "../stackedBarChart";
import transformDataGraph from "../transformDataGraph";
import AreaGraph from "../areaGraph";
import HistogramBar from "../histogramBar";
import { data } from "src/utils/performance/lineChart";


const Overview = (props) => {
  //miscellaneous constants
  const isPhone = window.innerWidth <= 768;
  const [isLoadingGymData, setIsLoadingGymData] = useState(true);

  //constants for calendar range selection
  const currentDate = new Date();
  const currentStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  const [dateRange, setDateRange] = useState([
    currentStartDate, // First day of the current month
    null, // End date will be null for single-month selection
  ]);
  const [startDate, endDate] = dateRange;
  const [selectedMonthId, setSelectedMonthId] = useState(`${currentStartDate.getFullYear()}-${String(currentStartDate.getMonth() + 1).padStart(2, '0')}`)
  const location = useLocation();
  const currentMonthId = `${currentStartDate.getFullYear()}-${String(currentStartDate.getMonth() + 1).padStart(2, '0')}`
  const [selectedDateOption, setSelectedDateOption] = useState(null)
  const [selectedMonths, setSelectedMonths] = useState();
  const [dateChanged, setDateChanged] = useState(false);

  //calendar available range constants
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");

  //calendar quick button constants
  const [lastMonthValid, setLastMonthValid] = useState(false);
  const [last3MonthValid, setLast3MonthValid] = useState(false);
  const [lastYearValid, setLastYearValid] = useState(false);

  //constants for dashboards main numbers
  const [totalRevenue, setTotalRevenue] = useState(""); //Revenu total
  const [totalRevenueUnit, setTotalRevenueUnit] = useState(""); //Nombre boissons
  const [totalDrinks, setTotalDrinks] = useState(""); //Revenus unite
  const [totalRevenueApp, setTotalRevenueApp] = useState(0);
  const [totalRevenueMembership, setTotalRevenueMembership] = useState(0);

  //constants for dashboards second line of numbers
  const [fiilinRevenue, setFiilinRevenue] = useState(0); //Abonnements aux appli
  const [averageRating, setAverageRating] = useState("");  //Etoiles
  const [averageDrinksPerUser, setAverageDrinksPerUser] = useState(""); //Moyenne boissons par client
  const [uniqueUsers, setUniqueUsers] = useState(""); //Nombres de clients actifs

  //constants for dashboards third line of numbers
  const [numberOfGyms, setNumberOfGyms] = useState(0);
  const [rentRevenue, setRentRevenue] = useState(0);
  const [salesRevenue, setSalesRevenue] = useState(0);
  const [fiilinMembershipRevenue, setFiilinMembershipRevenue] = useState(0);

  //constants for repartition boissons histogram
  const [gratuiteDrinks, setGratuitDrinks] = useState(0);
  const [abonnmentDrinks, setAbonnementDrinks] = useState(0);
  const [packDrinks, setPackDrinks] = useState(0);
  const [payanteDrinks, setPayanteDrinks] = useState(0);

  const [dataLoaded, setDataLoaded] = useState(false);


  {/*useEffect Hooks that control how data is loaded according to gym selection */}

  // we fetch the data of all gyms but not the financial of the membership data, just the primary data of gyms
  // if the data is already fetched, then, we set a machine id as a default one along with the name, which is the 
  // the first gym by alphabetic order
  useEffect(() => {
    if (!props.allMachinesDataFetched && props.privilege) {
      //console.log("fetching")
      props.fetchAllMachinesData(null, props.privilege);
    } 
    if (props.allMachinesDataFetched) {
     // console.log("for gods sake")
      // for (let machineData of props.allMachinesData) {
      //   props.fetchGymData(machineData.id, machineData.name)
      // }
      props.fetchAllGymsData()

      setDataLoaded(true);
    }
  }, [props.allMachinesDataFetched]);



  {/*useEffect Hooks that control how data is displayed according to calendar selection */}

  useEffect(() => {
    // once gymdata is loaded, get the first and last month of data and set the available calendar range
    if (props.gymDataFetched&& props.gymsDataAll && props.gymsDataAll[props.machineIdGym]){
        let earliestDate = Object.keys(props.gymsDataAll[props.machineIdGym]["financials"])[0];
        let latestDate = Object.keys(props.gymsDataAll[props.machineIdGym]["financials"])[0];
        Object.values(props.gymsDataAll).map(gym => {
            const lastMonthIndex = Object.keys(gym["financials"]).length-1
            if (Object.keys(gym["financials"])[0] < earliestDate){
                earliestDate = Object.keys(gym["financials"])[0]
            }
            if (Object.keys(gym["financials"])[lastMonthIndex] > latestDate) {
                latestDate = Object.keys(gym["financials"])[lastMonthIndex]
            }
        })

      const firstDate = earliestDate;
      const lastDate = latestDate;
      setMinDate(firstDate);
      setMaxDate(lastDate);

      //decide whether or not to show last month, last 3 months or last year buttons
      const isValidRange = firstDate && lastDate;
      // Calculate the difference in months between the start and end date
      const rangeInMonths = isValidRange ? differenceInMonths(lastDate, firstDate) : 0;
      setLastMonthValid(rangeInMonths >= 1); // For "Last Month"
      setLast3MonthValid(rangeInMonths >= 3); // For "Last Month"
      setLastYearValid(rangeInMonths >= 12); // For "Last Month"
    } else {
      props.fetchGymData(props.machineIdGym, props.gymName);
    }
  }, [props.gymName, props.gymDataFetched, props.machineIdGym, props.gymsDataAll]);


  useEffect(() => { //hook to calculate correct number of selected months and update datechanged flag
    const diffInMonths = dateRange[1] ? differenceInMonths(dateRange[1], dateRange[0]) + 1 : 1;
    setSelectedMonths(diffInMonths); //update selectedMonths after dateRange is updated
    setDateChanged(true)
  }, [dateRange]);  // runs only when dateRange changes


  //if more than one month selected, aggregate data for selected time period
  useEffect (() => {
    //we calculate how many months are there in total in the selected date range
    if (selectedMonths > 1 && dateChanged && props.gymDataFetched && props.gymsDataAll[props.machineIdGym]) {//check whether number of selectedmonths is more than 1 and has been updated
        //if more than 1 we sum up every month in range to get total revenue

        const metrics = {
          revenue: 0,
          unitRevenue: 0,
          fiilinMembership: 0,
          membershipRevenue: 0,
        
          subscriptions: 0,
        
          drinks: 0,
          totalStars: 0,
          totalDrinks: 0,
          totalUniqueUsers: 0,
        
          totalGyms: 0,
        
          numberProtein: 0,
          numberSport: 0,
        
          rent: 0,
        };

        //split months dictionary in array of the keys
        const monthsId = Object.keys(props.gymsDataAll[props.machineIdGym]["financials"])
        //get the start month in date form
        const startMonth = `${dateRange[0].getFullYear()}-${String(dateRange[0].getMonth() + 1).padStart(2, '0')}`
        //find the index of the selected start month in the array of keys
        const startMonthIndex = monthsId.indexOf(startMonth)
        //loop from start month all the way to last month in array
        let numberOfIterationsGymsWithStars = 0;

        Object.values(props.gymsDataAll).map(gym => {
          if (gym["financials"][monthsId[startMonthIndex + selectedMonths - 1]]) {
            metrics.totalGyms += 1;
          }

          for (let i=startMonthIndex;i<startMonthIndex + selectedMonths;i+=1) {
            if (gym["financials"][monthsId[i]]) {
      
              metrics.revenue += gym["financials"][monthsId[i]]["finance"].revenue + gym["financials"][monthsId[i]]["memberships"].moneyMemberships;
              // we also need to add the revenue of the app memberships 
              metrics.revenue += gym["financials"][monthsId[i]]["memberships"].moneyAppMemberships ? gym["financials"][monthsId[i]]["memberships"].moneyAppMemberships : 0

              metrics.drinks += gym["financials"][monthsId[i]]["finance"].totalDrinks
              
              metrics. membershipRevenue += gym["financials"][monthsId[i]]["memberships"].moneyMemberships
              metrics.unitRevenue += gym["financials"][monthsId[i]]["finance"].revenue
              metrics.subscriptions += gym["financials"][monthsId[i]]["memberships"].moneyAppMemberships ? gym["financials"][monthsId[i]]["memberships"].moneyAppMemberships * (gym.chargeMembership/100) : 0
              metrics.totalStars += gym["financials"][monthsId[i]]["finance"].averageRating
              numberOfIterationsGymsWithStars = gym["financials"][monthsId[i]]["finance"].averageRating ? numberOfIterationsGymsWithStars + 1 : numberOfIterationsGymsWithStars
    
              metrics.totalDrinks += gym["financials"][monthsId[i]]["finance"].averageDrinksPerUser
              metrics.totalUniqueUsers += gym["financials"][monthsId[i]]["finance"].uniqueUsers
              metrics.fiilinMembership += 
              gym["financials"][monthsId[i]]["memberships"].moneyAppMemberships 
              ? 
              gym["financials"][monthsId[i]]["memberships"].moneyAppMemberships * (gym.chargeMembership/100) + gym["financials"][monthsId[i]]["memberships"].moneyMemberships * (gym.chargeMembership/100) +  gym["financials"][monthsId[i]]["finance"].revenue 
              :gym["financials"][monthsId[i]]["memberships"].moneyMemberships * (gym.chargeMembership/100) +  gym["financials"][monthsId[i]]["finance"].revenue;
              
              metrics.rent += gym.rentPrice ? gym.rentPrice : 0;

              Object.values(gym["financials"][monthsId[i]]["finance"].sportAndProtein).map(day => {
                metrics.numberProtein += day.protein;
                metrics.numberSport += day.sport;
               })
            }
          }
        })

        //first row data
        setTotalRevenue( metrics.revenue.toFixed(2));
        setTotalRevenueUnit( metrics.unitRevenue.toFixed(2));
        setTotalRevenueMembership(( metrics.membershipRevenue).toFixed(2));
        setNumberOfGyms( metrics.totalGyms);

        //second row data
        setFiilinRevenue(( metrics.fiilinMembership + ( metrics.numberProtein * 1.1 +  metrics.numberSport * 0.6)+ metrics.rent).toFixed(2));
        setFiilinMembershipRevenue(( metrics.fiilinMembership).toFixed(2));
        setSalesRevenue(( metrics.numberProtein * 1.1 +  metrics.numberSport * 0.6).toFixed(2));
        setRentRevenue( metrics.rent);

        //third row data
        setTotalDrinks( metrics.drinks);
        setAverageDrinksPerUser(( metrics.drinks /  metrics.totalUniqueUsers).toFixed(1));
        setUniqueUsers(Math.round( metrics.totalUniqueUsers /  selectedMonths));
        setAverageRating(( metrics.totalStars /numberOfIterationsGymsWithStars).toFixed(2));

    } else if (props.gymsDataAll) {
      //if only 1 month simply display that months values

      const metrics = {
        revenue: 0,
        drinks: 0,
        membershipRevenue: 0,
        unitRevenue: 0,
      
        subscriptions: 0,
        totalStars: 0,
        totalDrinks: 0,
        totalUniqueUsers: 0,
      
        fiilinMembership: 0,
        totalGyms: 0,
        numberOfGymsWithStars: 0,
      
        numberProtein: 0,
        numberSport: 0,
      
        rent: 0,
      
        freeDrinks: 0,
        subscriptionDrinks: 0,
        packDrinks: 0,
        paidDrinks: 0,
      };

      Object.values(props.gymsDataAll).map(gym => {
        if (gym["financials"][selectedMonthId]) {
          metrics.totalGyms += 1;
          // we add the revenue of the app memberships
          metrics.revenue += gym["financials"][selectedMonthId]["finance"].revenue + gym["financials"][selectedMonthId]["memberships"].moneyMemberships
          metrics.drinks += gym["financials"][selectedMonthId]["finance"].totalDrinks;
          metrics.membershipRevenue += gym["financials"][selectedMonthId]["memberships"].moneyMemberships;
          metrics.unitRevenue += gym["financials"][selectedMonthId]["finance"].revenue;

          metrics.subscriptions += gym["financials"][selectedMonthId]["memberships"].moneyAppMemberships ? gym["financials"][selectedMonthId]["memberships"].moneyAppMemberships * (gym.chargeMembership/100) : 0
          metrics.totalStars += gym["financials"][selectedMonthId]["finance"].averageRating
          metrics.numberOfGymsWithStars = gym["financials"][selectedMonthId]["finance"].averageRating ? metrics.numberOfGymsWithStars + 1 : metrics.numberOfGymsWithStars
          metrics.totalDrinks += gym["financials"][selectedMonthId]["finance"].averageDrinksPerUser
          metrics.totalUniqueUsers += gym["financials"][selectedMonthId]["finance"].uniqueUsers

          metrics.rent += gym.rentPrice ? gym.rentPrice : 0;

          metrics.freeDrinks += gym["financials"][selectedMonthId]["finance"].freeDrinks;
          metrics.subscriptionDrinks += gym["financials"][selectedMonthId]["finance"].membershipDrinks;
          metrics.packDrinks += gym["financials"][selectedMonthId]["finance"].packDrinks;
          metrics.paidDrinks += gym["financials"][selectedMonthId]["finance"].paidDrinks;

          metrics.fiilinMembership += 
          gym["financials"][selectedMonthId]["memberships"].moneyAppMemberships 
          ? 
          gym["financials"][selectedMonthId]["memberships"].moneyAppMemberships * (gym.chargeMembership/100) + gym["financials"][selectedMonthId]["memberships"].moneyMemberships * (gym.chargeMembership/100) +  gym["financials"][selectedMonthId]["finance"].revenue 
          :gym["financials"][selectedMonthId]["memberships"].moneyMemberships * (gym.chargeMembership/100) +  gym["financials"][selectedMonthId]["finance"].revenue;
          
          Object.values(gym["financials"][selectedMonthId]["finance"].sportAndProtein).map(day => {
            metrics.numberProtein += day.protein;
            metrics.numberSport += day.sport;
          })
        }
      })  

      //first row data
      setTotalRevenue(metrics.revenue.toFixed(2));
      setTotalRevenueUnit(metrics.unitRevenue.toFixed(2));
      setTotalRevenueMembership((metrics.membershipRevenue).toFixed(2));
      setNumberOfGyms(metrics.totalGyms);
      setTotalRevenueApp((metrics.subscriptions).toFixed(2));

      //second row data
      setFiilinRevenue((metrics.fiilinMembership + (metrics.numberProtein * 1.1 + metrics.numberSport * 0.6) + metrics.rent).toFixed(2));
      setFiilinMembershipRevenue((metrics.fiilinMembership).toFixed(2));
      setSalesRevenue((metrics.numberProtein * 1.1 + metrics.numberSport * 0.6).toFixed(2));
      setRentRevenue(metrics.rent);

      //third row data
      setTotalDrinks(metrics.drinks);
      setAverageDrinksPerUser((metrics.drinks / metrics.totalUniqueUsers).toFixed(1));
      setUniqueUsers(Math.round(metrics.totalUniqueUsers / selectedMonths));
      setAverageRating((metrics.totalStars / metrics.numberOfGymsWithStars).toFixed(2));

      //boissons histogram
      setGratuitDrinks(metrics.freeDrinks);
      setAbonnementDrinks(metrics.subscriptionDrinks);
      setPackDrinks(metrics.packDrinks);
      setPayanteDrinks(metrics.paidDrinks);
      
    }
  }, [dataLoaded, props.gymsDataAll, props.gymDataFetched, dateChanged, selectedMonthId]);

  
  const handleChangeDateCalendar = (update) => { 
    // First, reset the dateChanged flag
    setDateChanged(false);
    
    //clear all selections from calendar
    handleQuickSelect("clear");
  
    // Then update dateRange
    setDateRange(update);
  
    // Set selectedMonthId based on the updated dateRange
    const selectedMonth = `${update[0].getFullYear()}-${String(update[0].getMonth() + 1).padStart(2, '0')}`;
    setSelectedMonthId(selectedMonth);

  };


  {/*Secondary functions that performs utility actions like selecting months based on a button or transforming data to be send to components */}

  // this function is to highlight the month selected on the calendar date picker
  const highlightMonth = (date) => {
    if (!startDate || !endDate) return "";

    const isStartOrEnd =
      (startDate?.getFullYear() === date.getFullYear() && startDate?.getMonth() === date.getMonth()) ||
      (endDate?.getFullYear() === date.getFullYear() && endDate?.getMonth() === date.getMonth());

    const isBetween =
      startDate &&
      endDate &&
      date > startDate &&
      date < endDate;

    if (isStartOrEnd) return "selected-month";
    if (isBetween) return "between-month";

    return "";
  };


  const handleQuickSelect = (option) => {
    const today = new Date();
    setDateChanged(false)

    switch (option) {
      case "lastMonth":
        setDateRange([today, today]);
        setSelectedMonthId(`${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}`)
        setSelectedDateOption("lastMonth")
        break;
      case "last3Months":
        setDateRange([subMonths(today, 2), today]);
        setSelectedDateOption("last3Months")
        break;
      case "currentYear":
        setDateRange([startOfYear(today), endOfYear(today)]);
        setSelectedDateOption("currentYear")
        break;

      case "allMonths":
        const minDateFormatted = new Date(...minDate.split("-").map((v, i) => i === 1 ? v - 1 : v), 1);
        const maxDateFormatted = new Date(...maxDate.split("-").map((v, i) => i === 1 ? v - 1 : v), 1);
        setDateRange([minDateFormatted, maxDateFormatted])
        setSelectedDateOption("selectionAll")
        break;
      case "selectionYear":
        setDateRange([null, null]);
        setSelectedDateOption("selectionYear")
        break;
      case "selectionMonth":
        setDateRange([null, null]);
        setSelectedDateOption("selectionMonth")
        break;
      case "clear":
        setSelectedDateOption(null)
        setDateRange([null, null]);
        break;
      default:
        setSelectedDateOption(null)
        setDateRange([null, null]);
        break;
    }
  };




  {/*Various HTML components */}
  const customCalendarContainer = ({ children }) => (
    <div
      className="border border-transparent rounded-xl"
      style={{
        display: "flex",
        flexDirection: isPhone ? "column" : "row", // Stack vertically for phone
        alignItems: "stretch",
        marginRight: isPhone
          ? "0" // No margin on phone view
          : `calc(4vw + (8 / 100) * (92vw))`, // Dynamic margin for desktop
      }}
    >
      {/* Sidebar */}
      <div
        className={`p-4 bg-white border-r flex flex-col justify-between border border-transparent ${
          isPhone ? "mb-4" : "" // Add margin-bottom for phone view
        }`}
        style={{
          borderTopLeftRadius: isPhone ? "0" : "10px", // Remove border radius for phone
          borderBottomLeftRadius: isPhone ? "0" : "10px", // Remove border radius for phone
        }}
      >
        <div>
          {lastMonthValid && (
            <button
              className={`block w-full mb-2 py-4 px-2 border border-transparent rounded-xl ${
                selectedDateOption === "lastMonth"
                  ? "bg-customOrange-dark text-white"
                  : "bg-white text-black hover:bg-customOrange-light"
              }`}
              onClick={() => handleQuickSelect("lastMonth")}
            >
              Dernier mois
            </button>
          )}
          {last3MonthValid && (
            <button
              className={`block w-full mb-2 py-4 px-2 border border-transparent rounded-xl ${
                selectedDateOption === "last3Months"
                  ? "bg-customOrange-dark text-white"
                  : "bg-white text-black hover:bg-customOrange-light"
              }`}
              onClick={() => handleQuickSelect("last3Months")}
            >
              3 derniers mois
            </button>
          )}
          {lastYearValid && (
            <button
              className={`block w-full mb-2 py-4 px-2 border border-transparent rounded-xl ${
                selectedDateOption === "currentYear"
                  ? "bg-customOrange-dark text-white"
                  : "bg-white text-black hover:bg-customOrange-light"
              }`}
              onClick={() => handleQuickSelect("currentYear")}
            >
              Année en cours
            </button>
          )}
          {/* Select full date range option */}
          {minDate && maxDate &&(<button
            className={`block w-full mb-2 py-4 px-2 border border-transparent rounded-xl ${
              selectedDateOption === "currentYear"
                ? "bg-customOrange-dark text-white"
                : "bg-white text-black hover:bg-customOrange-light"
            }`}
            onClick={() => handleQuickSelect("allMonths")}
          >
            Total
          </button>)}
          <button
            className="block w-full mb-2 py-4 px-2 bg-white border border-transparent rounded-xl hover:bg-customOrange-light"
            onClick={() => handleQuickSelect()}
          >
            Réinitialiser
          </button>

        </div>
      </div>
  
      {/* Calendar */}
      <div
        className="bg-white"
        style={{
          flexGrow: 1,
          padding: "10px",
          borderTopRightRadius: isPhone ? "10px" : "0", // Adjust border radius for phone
          borderBottomRightRadius: isPhone ? "10px" : "0", // Adjust border radius for phone
        }}
      >
        {children}
      </div>
    </div>
  )
  
  {/*Menu Bar routing dictionary */}
  const menuItems = [
    { name: "Dashboard", route: "/finance/dashboard" },
    { name: "Analysis", route: "/finance/analysis" },
    { name: "Overview", route: "/finance/overview"}
  ]


  return (
    <div
      className={`min-h-screen flex flex-col w-full p-6 bg-customOrange-light ${
        isPhone ? "w-full p-1" : "" // Adjust padding and width for phone view
      }`}
      style={{
        marginTop: "20px",
        paddingLeft: isPhone ? "2%" : "4%", // Smaller padding on phone view
        paddingRight: isPhone ? "2%" : "4%", // Smaller padding on phone view
        height: "auto", // Make sure the div fills the phone screen height
        justifyContent: "flex-start", // Align content to the top for both mobile and desktop
        alignItems: "flex-start", // Align items to the top of the flex container
      }}
    >

    {/* Gym Selector and Date Range Picker */}
    {props.allMachinesDataFetched && numberOfGyms > 0 && (
      <div className="w-full flex-start">
        {/* MenuBar */}
        {!props.gymMachineIds || props.gymMachineIds[1]  ? (<div className= {isPhone ? "flex flex-start item-start" : "flex justify-start pt-8"}>
          <MenuBar menuItems={menuItems} />
        </div>) : <div style={{ height: 35, visibility: 'hidden' }}></div>}
        <div className={isPhone ? "w-full flex flex-col mb-1 ": "flex items-center justify-between mb-[10px]"}>
        {/* Date Picker */}
        <div className={isPhone ? "flex flex-row items-center justify-between bg-white border border-transparent rounded-xl px-4 w-full mb-1" : "flex flex-row mb-1 items-center justify-between bg-white border border-transparent rounded-xl px-4"}>
          <FaRegCalendarAlt />
          <DatePicker
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => handleChangeDateCalendar(update)}
            dateFormat="MMM yyyy"
            showMonthYearPicker
            placeholderText={startDate}
            className="custom-datepicker-input"
            calendarClassName="custom-datepicker-calendar"
            dayClassName={(date) => highlightMonth(date)}
            locale={fr}
            calendarContainer={customCalendarContainer}
            minDate={minDate}
            maxDate={maxDate}
            popperPlacement="bottom-end" 
        />
        </div>
      </div>

        {/* Content */}
        {props.gymsDataAll.hasOwnProperty(props.machineIdGym) ? (
          <div className="mt-3 flex flex-col flex-grow space-y-4 justify-start">
            {/* First set of data blocks */}
            <BigDataBlock  data={[
                { title: "Revenu total des salles", value: totalRevenue, type: "money" },
                { title: "Revenu unité", value: totalRevenueUnit, type: "money" },
                { title: "Revenu abonnement", value: totalRevenueMembership, type: "money" },
                { title: "Nombre de Salles", value: numberOfGyms, type: "user" },
              ]}
            />

            {/* Second set of data blocks */}
            <div className={`grid gap-4 w-full ${isPhone ? "grid-cols-1" : "grid-cols-2"}`}>
              {/* Left Side: 1 Large Block + 3 Small Blocks */}
              <div className="flex flex-col gap-4 col-span-1 h-full">
                {/* Large "Revenu total Fiilin" block */}
                <div className="grid h-full flex">
                  <DataBlock title="Revenu total Fiilin" value={fiilinRevenue} type="money" className="w-full" />
                </div>

                {/* Row of 3 smaller DataBlocks */}
                <div className="grid grid-cols-3 gap-4 h-full">
                  <DataBlock title="Revenu abo Fiilin" value={fiilinMembershipRevenue} type="money" size="smaller" />
                  <DataBlock title="Revenu de location" value={rentRevenue} type="money" size="smaller" />
                  <DataBlock title="Revenu des ventes" value={salesRevenue} type="money" size="smaller" />
                </div>
              </div>

              {/* Right Side: Graph taking full height */}
              <div className="grid h-full col-span-1 flex">
                {selectedMonths > 1 ? (<StackedBarChart
                  data={transformDataGraph(
                    props.gymsDataAll,
                    "overviewRevenueGraph",
                    dateChanged,
                    selectedMonths,
                    dateRange,
                    selectedMonthId
                  )}
                  type="overviewRevenue"
                  className="w-full"
                />) : (
                  <HistogramBar
                  data={transformDataGraph(
                    [ {value: totalRevenueUnit,
                      name: "Revenue Unite"},
                      {value: totalRevenueMembership,
                        name: "Revenue Abonnement"},
                      {value: totalRevenueApp,
                        name: "Revenue App Abonnement"}
                    ],
                    "overviewRevenueHistogram",
                    dateChanged,
                    selectedMonths,
                    dateRange,
                    selectedMonthId
                  )}
                  title="Répartition des revenus salles"
                  dataKey="types"
                  nameTooltip="Revenu"
                  colorLine="#F08300"
                  colorArea="#FFD699"
                  isPhone={isPhone}
                />
                )}
              </div>
            </div>

            {/* Third set of data blocks */}
            <div className={`grid gap-4 w-full ${isPhone ? "grid-cols-1" : "grid-cols-4"}`}>
              {/* Repartition des boissons */}
              <div className={`grid col-span-${isPhone ? "1" : "2"} h-full `}>
                {selectedMonths > 1 ? (<StackedBarChart data={transformDataGraph(props.gymsDataAll, "overviewDrinksGraph", dateChanged, selectedMonths, dateRange, selectedMonthId)} type="overviewDrinks" />) 
                : (
                  <HistogramBar
                  data={transformDataGraph(
                    [ {value: packDrinks,
                      name: "Packs"},
                      {value: payanteDrinks,
                        name: "Payantes"},
                      {value: gratuiteDrinks,
                        name: "Gratuites"},
                      {value: abonnmentDrinks,
                        name:"Abonnements"
                      }
                    ],
                    "overviewDrinksHistogram",
                    dateChanged,
                    selectedMonths,
                    dateRange,
                    selectedMonthId
                  )}
                  title="Répartition des boissons"
                  dataKey="types"
                  nameTooltip="Revenu"
                  colorLine="#F08300"
                  colorArea="#FFD699"
                  isPhone={isPhone}
                />
                )}
              </div>
              <div className="grid grid-cols-2 gap-4 col-span-2">
                <DataBlock title="Nombre de boissons" value={totalDrinks} type="drink" />
                <DataBlock title="Moyenne boissons par client" value={averageDrinksPerUser} type="drink" />
                <DataBlock 
                  title={selectedMonths < 2 ? "Nombre de clients actifs" : "Moyenne nombre de clients actifs"}
                  value={uniqueUsers}
                  type="user"
                />
                <DataBlock title="Note Utilisateur" value={averageRating} type="star" />
              </div>
            </div>

            {/* Product Table -  Repartition des ventes */}
            <div className="flex-grow overflow-x-auto">
              <ProductTable products={transformDataGraph(props.gymsDataAll, "productTable", dateChanged, selectedMonths, dateRange, selectedMonthId)} gyms={props.allMachinesData} />
            </div>

          </div>
          ) : (

            <div className="flex flex-col flex-grow space-y-6">
              <RotatingLines strokeColor="#f08300" height={30} width={30} visible={true} animationDuration="1.25" />
              <div className="grid grid-cols-4 gap-4 w-full">
                <PlaceholderBlock title="Chiffre d'affaire" />
                <PlaceholderBlock title="Nombre boissons" />
                <PlaceholderBlock title="Revenu abonnement" />
                <PlaceholderBlock title="Utilisateurs" />
              </div>
              <div className="grid grid-cols-2 gap-3 w-full flex-grow">
                <PlaceholderGraph />
                <PlaceholderGraph />
              </div>
            </div>
          )}
        </div>
      )}
    </div>


  );
  
  

  };
  
  

  
  const mapStateToProps = (state) => ({
    userId: state.userState.userId,
    gymsData: state.gymState.gymsData,
    gymsDataAll: state.gymState.gymsDataAll,
    gymDataFetched: state.gymState.gymDataFetched,
    allMachinesData: state.machineState.allMachinesData,
    allMachinesDataFetched: state.machineState.allMachinesDataFetched,
    setMachineIdGymSuccess: state.gymState.setMachineIdGymSuccess,
    machineIdGym: state.gymState.machineIdGym,
    gymName: state.gymState.gymName, 
    fetchedGymsFinanceNumber: state.gymState.fetchedGymsFinanceNumber, 
    fetchedGymsMembershipNumber: state.gymState.fetchedGymsMembershipNumber,
    gymMachineIds: state.userState.gymMachineIds,
    privilege: state.userState.privilege,
    gymDataFetched: state.gymState.gymDataFetched,
    gymDataAllFetched: state.gymState.gymDataFetched,
  });
  
  const mapDispatchProps = (dispatch) => ({
    fetchGymData: (machineId, nameGym) => dispatch(fetchGymData(machineId, nameGym)),
    fetchAllGymsData: () => dispatch(fetchAllGymsData()),
    fetchAllMachinesData: (machineIds, privilege) => dispatch(fetchAllMachinesData(machineIds, privilege)),
    setMachineIdGym: (machineId) => dispatch(setMachineIdGym(machineId)),
    setGymDataReady: () => dispatch(setGymDataReady()),
    resetMachineIdGym: () => dispatch(resetMachineIdGym()),
    setGymName: (gymName) => dispatch(setGymName(gymName)), 
    resetMGymName: () => dispatch(resetMGymName()),
    setInfoUser: (userId) => dispatch(setInfoUser(userId)),
    fetchAllMachinesData: (gymMachineIds, privilege) => {dispatch(fetchAllMachinesData(gymMachineIds, privilege))},
    fetchInfoUser: (userId) => { dispatch(fetchInfoUser(userId))},
  });

  export default connect(mapStateToProps, mapDispatchProps)(Overview)